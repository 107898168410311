// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { withActiveOrganization } from "containers/Auth/decorators";
import { restrictedArea, ROLES } from "containers/Permissions";
import { withTargetsReceive } from "pages/_shared/TargetsField/decorators/withTargetsReceive";
import { withRouter } from "utils/decorators";
import { getGqlErrorMessage } from "utils/errors";
import { withQuery } from "utils/graphql";

import AdminStructureQuery from "./gql/adminStructure";
import StructureQuery from "./gql/structure";
import StructureForm from "./StructureForm";
import { adaptStructure } from "./utils/adapters";

@restrictedArea((props) => {
  const allowed = [];

  if (props.forAdmin) {
    allowed.push(ROLES.administrator.structuresAdmin);
  } else {
    allowed.push(ROLES.structure.structureManager);
  }

  return {
    allowed,
  };
})
@withTargetsReceive({
  distributorFieldNameInRequest: "organizations",
})
@withRouter
@withActiveOrganization
@withQuery({ name: "structure", query: StructureQuery })
@withQuery({ name: "adminStructure", query: AdminStructureQuery })
export class EditStructureData extends Component {
  static propTypes = {
    allCountries: PropTypes.array.isRequired,
    forAdmin: PropTypes.bool.isRequired,

    // from @withTargetsReceive
    getTargetsData: PropTypes.func.isRequired,

    // @withRouter
    params: PropTypes.object.isRequired,

    // from @withActiveOrganization
    activeOrganization: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),

    // from @withQuery
    structureQuery: PropTypes.func,
    adminStructureQuery: PropTypes.func,
  };

  state = {
    loading: true,
    error: null,
    initialValues: undefined,
  };

  componentDidMount() {
    this.updateInitialValues();
  }

  componentDidUpdate(prevProps) {
    const organizationHasChanged =
      this.props.activeOrganization.id !== prevProps.activeOrganization.id;

    if (organizationHasChanged) {
      this.updateInitialValues();
    }
  }

  getStructureId() {
    const { params } = this.props;
    return params.id;
  }

  async updateInitialValues() {
    const { getTargetsData, allCountries } = this.props;

    try {
      this.setState({ loading: true, error: undefined });

      const structure = await this.fetchStructure();

      const initialValues = await adaptStructure(
        structure,
        getTargetsData,
        allCountries
      );

      this.setState({ initialValues });
    } catch (err) {
      this.setState({ error: getGqlErrorMessage(err) });
    } finally {
      this.setState({ loading: false });
    }
  }

  async fetchStructure() {
    const { forAdmin, structureQuery, adminStructureQuery } = this.props;

    const structureId = this.getStructureId();
    const request = forAdmin ? adminStructureQuery : structureQuery;
    const structureResponse = await request({
      variables: { structureId },
    });

    // TODO CS-170: in REST api error objects used special flags e.g. isForbidden. Implement in GraphQL
    // TODO CS-170: add error handling
    return get(
      structureResponse,
      `${forAdmin ? "adminStructure" : "structure"}.structure`
    );
  }

  render() {
    const { allCountries, forAdmin } = this.props;
    const { initialValues, loading, error } = this.state;

    return (
      <StructureForm
        initialValues={initialValues}
        loading={loading}
        requestError={error}
        isEdit={true}
        allCountriesCount={allCountries.length}
        forAdmin={forAdmin}
      />
    );
  }
}
