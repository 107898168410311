// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { chunk } from "lodash-es";

import { NO_VALUE_PLACEHOLDER } from "src/constants";
import { trans } from "src/translations";

import {
  AUTOFILL_ORDER_MAPPING,
  MAX_CHUNK_SIZE,
  RESULTS_PER_PAGE,
} from "./constants";

export const fetchAutofillApps = async (
  loadAppsData,
  page,
  autofill,
  categories
) => {
  const response = await loadAppsData({
    page: page,
    limit: RESULTS_PER_PAGE,
    order: AUTOFILL_ORDER_MAPPING[autofill.orderBy],
    ...autofill.filters,
  });
  return response.results.map((app) => ({
    ...app,
    category: categories[app.category] || NO_VALUE_PLACEHOLDER,
  }));
};

export const fetchCategories = async (loadCategories) => {
  const { results } = await loadCategories();
  const categories = {};
  if (results) {
    results.forEach((category) => {
      categories[category.slug] = category.human_name;
    });
  }
  return categories;
};

const createSearchBarFilter = (filter) => ({
  id: filter.id,
  displayName: filter.display_name,
  param: filter.param,
  description: filter.description,
  values: [],
  hasSuggestions: filter.has_suggestions,
});

export const fetchFilters = async (loadFilters) => {
  const { results } = await loadFilters();
  return {
    sections: [
      {
        title: trans.DEFAULT_SECTION(),
        options: (results || []).map(createSearchBarFilter),
      },
    ],
  };
};

export const fetchSelectedApps = async (
  loadAppsData,
  selectedApps,
  categories
) => {
  const appsIDsPaginated = chunk(
    selectedApps.map((item) => item.id),
    MAX_CHUNK_SIZE
  );
  const responses = await Promise.all(
    appsIDsPaginated.map((apps) =>
      loadAppsData({
        limit: MAX_CHUNK_SIZE,
        id: apps,
      })
    )
  );
  const orderedIds = selectedApps.map((app) => app.id);
  const apps = responses
    .reduce((acc, item) => acc.concat(item.results), [])
    .sort((a, b) => orderedIds.indexOf(a.id) - orderedIds.indexOf(b.id));

  return apps.map((app) => ({
    ...app,
    category: categories[app.category] || NO_VALUE_PLACEHOLDER,
  }));
};
