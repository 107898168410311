// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useState } from "react";

import PropTypes from "prop-types";

import { SearchBar } from "components/elements";
import { Modal } from "components/layout";
import { trans } from "src/translations";

import { PackageItemsData } from "./PackageItemsData";
import styles from "./PackageItemsModals.scss";

export const PackageItemsModal = ({
  forAdmin,
  isOpen,
  packageData,
  onClose,
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const breadcrumbs = packageData?.parent?.parent
    ? [
        { id: "1", name: packageData.parent.parent.name ?? null },
        { id: "2", name: packageData.parent.name ?? null },
        { id: "3", name: packageData.name ?? null },
      ]
    : [];

  const handleClose = () => {
    setSearchQuery("");
    onClose();
  };

  const renderSearchField = (onSearch) => {
    const handleSearch = (value) => {
      setSearchQuery(value);
      onSearch(value);
    };

    return (
      <SearchBar
        placeholder={trans.STRUCTURES_PACKAGE__LIST_SEARCH_PLACEHOLDER()}
        value={searchQuery}
        onSearch={handleSearch}
        dataTestId="package-search-bar"
      />
    );
  };

  const renderStatus = () => {
    const status = [];
    if (packageData.itemsCount > 0) {
      status.push(packageData.itemsCount);
    }
    if (packageData.autofill) {
      status.push(trans.STRUCTURES_LIST__AUTOFILL());
    }
    return (
      <p className={styles.listStatus}>
        {trans.STRUCTURES_MODAL__PACKAGE_ITEMS_COUNT({
          count: status.join(" + "),
        })}
      </p>
    );
  };

  return (
    <Modal
      breadcrumbs={breadcrumbs}
      isOpen={isOpen}
      size="big"
      title={trans.STRUCTURES_PACKAGE__LIST_HEADER({
        name: packageData?.name ?? null,
      })}
      onClose={handleClose}
      dataTestId="package-items-modal"
    >
      <PackageItemsData
        forAdmin={forAdmin}
        packageData={packageData}
        searchComponent={renderSearchField}
        statusComponent={renderStatus}
      />
    </Modal>
  );
};

PackageItemsModal.propTypes = {
  forAdmin: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  packageData: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};
