// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { ScrollTable } from "components/elements";
import { tableColPropType, tableLayoutPropType } from "components/layout";
import { InfiniteData } from "containers/InfiniteData";
import { classes } from "utils/classes";

import { RESULTS_PER_PAGE } from "./constants";
import { PackageApplicationRow } from "./PackageApplicationRow";
import styles from "./PackageInfiniteTable.scss";

export const PackageInfiniteTable = ({
  className,
  columns,
  fetchAllData = false,
  initialSearch,
  layout = "auto",
  rowsPerPage = RESULTS_PER_PAGE,
  fetchData,
  isChecked,
  onChange,
  onNewDataLoaded,
  searchComponent,
  selectAllComponent,
  statusComponent,
}) => {
  const visibleColumns = columns.reduce((obj, c) => {
    obj[c.id] = true;
    return obj;
  }, {});

  return (
    <InfiniteData
      fetchAllData={fetchAllData}
      initialSearch={initialSearch}
      itemsPerPage={rowsPerPage}
      fetchData={fetchData}
      newDataLoaded={onNewDataLoaded}
    >
      {({ count, data, error, handleScroll, handleSearchChange, pending }) => (
        <div className={classes(styles.wrapper, className)}>
          {searchComponent && searchComponent(handleSearchChange)}
          <ScrollTable
            className={styles.table}
            columns={columns}
            data={{
              results: data,
              loading: pending,
              error: error,
            }}
            layout={layout}
            onScroll={handleScroll}
            selectAllComponent={
              selectAllComponent && selectAllComponent(data, count)
            }
            dataTestId="package-table"
          >
            {(item) => (
              // I guess this way it should be easy to switch renderer for video item
              <PackageApplicationRow
                app={item}
                key={item.id}
                visibleColumns={visibleColumns}
                isChecked={isChecked && isChecked(item)}
                onChange={onChange}
              />
            )}
          </ScrollTable>
          {statusComponent &&
            statusComponent({ results: data, meta: { count: count } })}
        </div>
      )}
    </InfiniteData>
  );
};

PackageInfiniteTable.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.arrayOf(tableColPropType).isRequired,
  fetchAllData: PropTypes.bool,
  initialSearch: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  layout: tableLayoutPropType,
  rowsPerPage: PropTypes.number,
  fetchData: PropTypes.func.isRequired,
  isChecked: PropTypes.func,
  onChange: PropTypes.func,
  onNewDataLoaded: PropTypes.func,
  searchComponent: PropTypes.func,
  selectAllComponent: PropTypes.func,
  statusComponent: PropTypes.func,
};
