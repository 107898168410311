// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import { Formik } from "formik";
import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { Info } from "components/feedback";
import { FormPagination } from "components/form";
import { PageContentError } from "components/layout";
import {
  Tabs,
  Tab,
  TabContent,
  getActiveTabIndex,
} from "components/navigation";
import { withActiveOrganization } from "containers/Auth/decorators";
import { TARGETS_FIELD_NAME } from "pages/_shared/TargetsField/constants";
import { trans } from "src/translations";
import { withRouter } from "utils/decorators";
import { withMutation } from "utils/graphql";
import { pathToUrl } from "utils/url";

import CreateAdminStructureMutation from "./gql/createAdminStructure";
import CreateStructureMutation from "./gql/createStructure";
import EditAdminStructureMutation from "./gql/editAdminStructure";
import EditStructureMutation from "./gql/editStructure";
import {
  TabBasicDetails,
  NAME_FIELD_NAME,
} from "./TabBasicDetails/TabBasicDetails";
import { FIELDS } from "./TabStructureCreator/constants";
import { TabStructureCreatorData } from "./TabStructureCreator/TabStructureCreatorData";
import { ForAdminContext } from "./utils/context";
import { submitForm } from "./utils/submitForm";
import { validateStructureForm, parseSubmitError } from "./utils/validate";

// TODO CS-520: There is another breadcrumbs level
// TODO CS-520: Designs used incomplete <Pagination> component. Since this
//      is also used in app submit, I did not change component
//      for compatibility reasons.

const tabs = [
  {
    url: "basic-details",
    text: trans.STRUCTURES_EDIT__TAB_NAME(),
    dataTestId: "create-structure-name-tab",
    requiredFields: [NAME_FIELD_NAME, TARGETS_FIELD_NAME],
  },
  {
    url: "creator",
    text: trans.STRUCTURES_EDIT__TAB_CREATOR(),
    dataTestId: "create-structure-creator-tab",
    requiredFields: [FIELDS.SECTION_FIELD_NAME],
  },
];

const editRoutePath = (forAdmin) =>
  forAdmin
    ? "/structures/admin/edit/:id/:tabId"
    : "/structures/edit/:id/:tabId";

const createRoutePath = (forAdmin) =>
  forAdmin ? "/structures/admin/create/:tabId" : "/structures/create/:tabId";

export const FORM_NAME = "structureForm";
@withRouter
@withActiveOrganization
@withMutation({
  name: "createStructure",
  mutation: CreateStructureMutation,
})
@withMutation({
  name: "editStructure",
  mutation: EditStructureMutation,
})
@withMutation({
  name: "createAdminStructure",
  mutation: CreateAdminStructureMutation,
})
@withMutation({
  name: "editAdminStructure",
  mutation: EditAdminStructureMutation,
})
export default class StructureForm extends Component {
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    loading: PropTypes.bool.isRequired,
    requestError: PropTypes.string,
    isEdit: PropTypes.bool,
    allCountriesCount: PropTypes.number.isRequired,
    forAdmin: PropTypes.bool.isRequired,
    initialValues: PropTypes.object.isRequired,

    // from @withRouter
    location: PropTypes.object,
    navigate: PropTypes.func,
    params: PropTypes.object,

    // from @withActiveOrganization
    activeOrganization: PropTypes.object.isRequired,

    // from @withMutation
    createStructureMutation: PropTypes.func,
    editStructureMutation: PropTypes.func,
    createAdminStructureMutation: PropTypes.func,
    editAdminStructureMutation: PropTypes.func,
  };

  // This serves to be set once the component is rendered the first time
  // Using `location` is out of the question, as the `location` changes
  // multiple time
  state = {
    pageOnTheList: 1,
    submitError: null,
  };

  componentDidMount() {
    const pageOnTheList = this.props.location?.state?.page;
    if (pageOnTheList) {
      this.setState({ pageOnTheList });
    }
  }

  static defaultProps = {
    isEdit: false,
  };

  getSubmitMutation = () => {
    const {
      createStructureMutation,
      editStructureMutation,
      createAdminStructureMutation,
      editAdminStructureMutation,
      isEdit,
      forAdmin,
    } = this.props;

    if (forAdmin) {
      return isEdit ? editAdminStructureMutation : createAdminStructureMutation;
    }
    return isEdit ? editStructureMutation : createStructureMutation;
  };

  areFieldsValid = (fields, errors) => {
    return fields.every((fieldName) => !errors[fieldName]);
  };

  isTabValid = (tab, errors) => {
    return this.areFieldsValid(tab.requiredFields, errors);
  };

  getTabList = (errors) => {
    return tabs.map((tab) => ({
      ...tab,
      isValid: this.isTabValid(tab, errors),
    }));
  };

  redirectToInvalidTab = (errors) => {
    const { forAdmin, navigate, params } = this.props;
    const tabs = this.getTabList(errors);
    const firstTabWithError = tabs.find((tab) => !tab.isValid);
    if (!firstTabWithError) {
      return;
    }
    const routePath = params.id
      ? editRoutePath(forAdmin)
      : createRoutePath(forAdmin);
    navigate(
      pathToUrl(routePath, {
        ...params,
        tabId: firstTabWithError.url,
      })
    );
  };

  touchAllFields = (formikProps) => {
    const touchedFields = {};
    Object.keys(formikProps.values).forEach((field) => {
      touchedFields[field] = true;
    });
    formikProps.setTouched(touchedFields);
  };

  handleSubmit = async (formikProps) => {
    const { isEdit, allCountriesCount, forAdmin, navigate } = this.props;
    this.setState({ submitError: null });
    this.touchAllFields(formikProps);
    if (!formikProps.isValid) {
      this.setState({ submitError: trans.STRUCTURES__DEFAULT_ERROR() });
      this.redirectToInvalidTab(formikProps.errors);
      return;
    }
    try {
      await submitForm({
        values: formikProps.values,
        isEdit,
        allCountriesCount,
        page: this.state.pageOnTheList,
        navigate,
        forAdmin,
        submitMutation: this.getSubmitMutation(),
      });
    } catch (error) {
      this.setState({ submitError: parseSubmitError(error) });
      this.redirectToInvalidTab(formikProps.errors);
    }
  };

  render() {
    const {
      allCountriesCount,
      requestError,
      activeOrganization,
      loading,
      forAdmin,
      location,
      initialValues,
      params,
    } = this.props;

    if (loading) {
      return <Loader />;
    }

    if (requestError) {
      return <PageContentError defaultMessage={requestError} />;
    }

    const locationMessage = location.state?.message;

    return (
      <Formik
        initialValues={initialValues}
        validate={validateStructureForm}
        onSubmit={(values, formikProps) => this.handleSubmit(formikProps)}
        enableReinitialize
      >
        {(formikProps) => {
          const tabList = this.getTabList(formikProps.errors);
          const activeTabIndex = getActiveTabIndex(params, "tabId", tabList);

          const createTabLink = (tabIndex) => {
            const tabUrl = tabList[tabIndex]?.url || tabList[0].url;

            const routePath = params.id
              ? editRoutePath(forAdmin)
              : createRoutePath(forAdmin);

            return pathToUrl(routePath, {
              ...params,
              tabId: tabUrl,
            });
          };

          return (
            <>
              <Tabs>
                {tabList.map((tab, idx) => (
                  <Tab
                    key={tab.url}
                    to={createTabLink(idx)}
                    text={tab.text}
                    icon={tab.isValid ? "ok" : "warning"}
                    iconColor={tab.isValid ? "success" : "error"}
                    dataTestIconId={tab.isValid ? "icon-success" : "icon-error"}
                    dataTestId={tab.dataTestId}
                    active={idx === activeTabIndex}
                  />
                ))}
              </Tabs>
              <TabContent>
                {locationMessage && <Info>{locationMessage}</Info>}
                {activeTabIndex === 0 && (
                  <TabBasicDetails
                    activeOrganizationId={activeOrganization.id}
                    allCountriesCount={allCountriesCount}
                    forAdmin={forAdmin}
                  />
                )}
                {activeTabIndex === 1 && (
                  <ForAdminContext.Provider value={forAdmin}>
                    <TabStructureCreatorData />
                  </ForAdminContext.Provider>
                )}

                {this.state.submitError && (
                  <Info type="error">{this.state.submitError}</Info>
                )}
                <FormPagination
                  activeTab={activeTabIndex}
                  dirty={!formikProps.dirty || !formikProps.isValid}
                  submitting={formikProps.isSubmitting}
                  onNext={() => createTabLink(activeTabIndex + 1)}
                  onPrev={() => createTabLink(activeTabIndex - 1)}
                  onSubmit={() => this.handleSubmit(formikProps)}
                  tabCount={tabList.length}
                  tabTitle={tabList.map((t) => t.text)[activeTabIndex]}
                />
              </TabContent>
            </>
          );
        }}
      </Formik>
    );
  }
}
