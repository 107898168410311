// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { GrabsonIcon } from "components/icons";
import { TableRow, TableRowColumn } from "components/layout";
import { Link } from "components/typography";
import { trans } from "src/translations";

import { STRUCTURE_TYPES } from "../constants";
import { ProviderInfoBox } from "./ProviderInfoBox";
import styles from "./ProviderRow.scss";
import { ProviderTooltip } from "./ProviderTooltip";

export const ProviderRow = ({
  selectedProvider,
  onEdit,
  sectionsFieldName,
  section,
}) => {
  const editAction = () =>
    onEdit(
      STRUCTURE_TYPES.SECTION,
      section,
      sectionsFieldName,
      selectedProvider
    );

  return (
    <TableRow className={styles.row} highlighting={false}>
      <TableRowColumn
        colspan={5}
        className={styles.column}
        columnClass={styles.withoutPadding}
      >
        <ProviderInfoBox selectedProvider={selectedProvider} />
        <ProviderTooltip
          selectedProvider={selectedProvider}
          className={styles.tooltip}
          alignment="bottom-start"
          action={editAction}
        >
          <GrabsonIcon className={styles.icon} name="help-filled" size="xl" />
        </ProviderTooltip>
        <Link className={styles.link} onClick={editAction}>
          {trans.STRUCTURES_EDIT__TYPE()}
        </Link>
      </TableRowColumn>
    </TableRow>
  );
};

ProviderRow.propTypes = {
  selectedProvider: PropTypes.string,
  onEdit: PropTypes.func.isRequired,
  sectionsFieldName: PropTypes.string.isRequired,
  section: PropTypes.object.isRequired,
};
