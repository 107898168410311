// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useEffect, useState } from "react";

import PropTypes from "prop-types";

import { DismissableModal, Modal } from "components/layout";
import { trans } from "src/translations";
import { parseGqlError } from "utils/errors";
import { pipeValidators } from "utils/validation";

import {
  LabelPropTypes,
  LabelType,
  TransCreatePropTypes,
  TransEditPropTypes,
} from "../../Labels/propTypes";
import {
  CREATE_COPY_MODE,
  CREATE_NEW_MODE,
  EDIT_MODES,
  SECTION,
} from "./constants";
import { CreateLabelModalData } from "./CreateLabelModalData";
import { EditLabelModalData } from "./EditLabelModalData";
import styles from "./LabelModal.scss";
import {
  getAlreadyUsedPackageLabels,
  getAlreadyUsedSectionLabels,
  getDuplicateLabelValidator,
  notSelectedLabelToCopy,
} from "./validation";

export const LabelModal = ({
  fieldName,
  isOpen,
  item,
  labelType,
  onClose,
  texts,
  availableProviders,
  onSubmit,
  structureFormValues,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mode, setMode] = useState(CREATE_NEW_MODE);

  useEffect(() => {
    if (item) {
      setMode(EDIT_MODES.EDIT);
    }
  }, [item]);

  const isEditReplaceNewMode = () => mode === EDIT_MODES.REPLACE_NEW;

  const isCopyCreateMode = () => mode === CREATE_COPY_MODE;

  const isReplaceExistingEditMode = () => mode === EDIT_MODES.REPLACE_EXISTING;

  const isCopyOrReplaceExistingMode = () =>
    isCopyCreateMode() || isReplaceExistingEditMode();

  const isEdit = () => item !== undefined;

  const isSectionLabel = labelType === SECTION;

  const handleSubmit = async (values, { setErrors }) => {
    const provider = values?.providerField;
    try {
      if (isCopyOrReplaceExistingMode()) {
        await onSubmit(values.labelToCopy, provider);
      } else if (isEditReplaceNewMode()) {
        await onSubmit(
          {
            mode: EDIT_MODES.REPLACE_NEW,
            translations: values.translationsNew,
          },
          provider
        );
      } else {
        await onSubmit(values.translations, provider);
      }
    } catch (error) {
      const gqlError = parseGqlError(error);
      setErrors({
        requestError:
          gqlError?.message ?? trans.DEFAULT_REQUEST_ERROR_MESSAGE(),
        ...gqlError.formMessages,
      });
    }
    setIsSubmitting(false);
    setMode(CREATE_NEW_MODE);
  };

  const handleClose = ({ isSubmitting }) => {
    if (!isSubmitting) {
      setMode(CREATE_NEW_MODE);
      onClose();
    }
  };

  const renderModal = () => {
    const modalTitle = isEdit()
      ? trans.STRUCTURES_LABEL_MODAL__EDIT_TITLE({
          labelName: item.name,
        })
      : texts.modalTitle();
    const modalSize = isSectionLabel ? "huge" : "medium";

    const validateForm = (values) => {
      if (isCopyCreateMode() || isReplaceExistingEditMode()) {
        const alreadyUsedLabelIds = isSectionLabel
          ? getAlreadyUsedSectionLabels(structureFormValues)
          : getAlreadyUsedPackageLabels(structureFormValues, fieldName);

        return pipeValidators(
          notSelectedLabelToCopy("labelToCopy"),
          getDuplicateLabelValidator(
            isSectionLabel,
            "labelToCopy",
            alreadyUsedLabelIds
          )
        )(values);
      }

      const translationsToHandle = isEditReplaceNewMode()
        ? values.translationsNew
        : values.translations;
      const hasAtLeastOneDefault = translationsToHandle.some(
        (translation) => translation.isDefault
      );
      if (!hasAtLeastOneDefault) {
        return {
          _error: trans.STRUCTURES_LABEL_MODAL__NO_DEFAULT_ERROR(),
        };
      }
      return {};
    };

    return (
      <Modal
        size={modalSize}
        className={styles.modal}
        isOpen={isOpen}
        onClose={() => handleClose({ isSubmitting })}
        title={modalTitle}
        dataTestId={`${isEdit() ? "edit" : "create"}-label-modal`}
      >
        {isEdit() ? (
          <EditLabelModalData
            labelType={labelType}
            texts={texts}
            item={item}
            mode={mode}
            onChangeMode={(e) => setMode(e.target.value)}
            onClose={handleClose}
            onSubmit={(values, helpers) => {
              setIsSubmitting(true);
              handleSubmit(values, helpers);
            }}
            validate={validateForm}
            fieldName={fieldName}
            availableProviders={availableProviders}
          />
        ) : (
          <CreateLabelModalData
            labelType={labelType}
            fieldName={fieldName}
            texts={texts}
            mode={mode}
            onChangeMode={(e) => setMode(e.target.value)}
            onSubmit={(values, helpers) => {
              setIsSubmitting(true);
              handleSubmit(values, helpers);
            }}
            onClose={handleClose}
            validate={validateForm}
            availableProviders={availableProviders}
          />
        )}
      </Modal>
    );
  };

  return (
    <>
      {isEdit() ? (
        <DismissableModal
          isOpen={isOpen}
          title={trans.STRUCTURES_LABELS__CHANGES_HEADER()}
          size="medium"
          type="warning"
          onClose={onClose}
          renderSubmitted={renderModal}
          dataTestId="edit-label-warning-modal"
        >
          <p>{trans.STRUCTURES_LABELS__CHANGES_WARNING()}</p>
        </DismissableModal>
      ) : (
        renderModal()
      )}
    </>
  );
};

LabelModal.propTypes = {
  fieldName: PropTypes.string,
  isOpen: PropTypes.bool,
  labelType: LabelType,
  item: LabelPropTypes,
  texts: PropTypes.oneOfType([TransEditPropTypes, TransCreatePropTypes])
    .isRequired,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  availableProviders: PropTypes.array,
  structureFormValues: PropTypes.object,
};
