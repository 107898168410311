// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import get from "lodash-es/get";

import { createSearchParams } from "components/elements/SearchBarWithFilters/_utils";

import { PACKAGE_ITEMS_SORT } from "../TabStructureCreator/constants";

export const getNameFromTranslation = (label) =>
  label?.translations?.find((translation) => translation.isDefault)
    ?.translation ?? "";

export const adaptFilters = (filters) => {
  const filtersAdapted = filters.map(({ key, ...rest }) => ({
    id: key.toLowerCase(),
    ...rest,
  }));
  return createSearchParams(null, {
    value: null,
    filters: filtersAdapted,
    exclusionFilters: [],
  });
};

const adaptPackageItemsAutofill = (autofill) => {
  if (!autofill) {
    return null;
  }

  return {
    displayCount: autofill.displayCount?.toString(),
    orderBy: autofill.orderBy,
    filters: adaptFilters(autofill.filters),
  };
};

// This removes unwanted `__typename` from gQL response
const adaptPackageItems = ({ id }) => ({ id });

const adaptTranslations = ({
  isDefault,
  translation,
  language: { code: languageCode },
}) => ({
  isDefault,
  translation,
  languageCode,
});

export const adaptPackages = (packageDetails) => {
  const packageItems = get(packageDetails, "packageItems.items", []);
  return {
    id: packageDetails.id,
    key: packageDetails.key || packageDetails.id,
    packageLabelId: packageDetails.packageLabel.id,
    name: getNameFromTranslation(packageDetails.packageLabel),
    translations: (packageDetails.packageLabel?.translations ?? []).map(
      adaptTranslations
    ),
    packageItems: packageItems.map(adaptPackageItems),
    autofillPackageItems: adaptPackageItemsAutofill(
      packageDetails.autofillPackageItems
    ),
    packageItemsOrderBy: PACKAGE_ITEMS_SORT.CUSTOM,
  };
};

export const adaptSection = (sectionDetails) => {
  const packages = get(sectionDetails, "packages.items", []);
  return {
    id: sectionDetails.id,
    key: sectionDetails.key || sectionDetails.id,
    sectionLabelId: sectionDetails.sectionLabel.id,
    name: getNameFromTranslation(sectionDetails.sectionLabel),
    translations: (sectionDetails.sectionLabel?.translations ?? []).map(
      adaptTranslations
    ),
    packages: packages.map(adaptPackages),
    selectedProvider: sectionDetails.selectedProvider.id,
  };
};

export const adaptTargets = async (targets, getTargetsData, allCountries) => {
  const targetsRESTShape = targets.map((t) => {
    const countries = t.worldwide ? allCountries : t.countries;

    return {
      device_models: t.deviceModels,
      device_families: t.deviceFamilies,
      organizations: t.organizations,
      collections: t.collectionIds,
      countries: countries.map(({ code }) => ({
        id: code,
      })),
    };
  });

  return getTargetsData(targetsRESTShape);
};

export const adaptStructure = async (
  structureDetails,
  getTargetsData,
  allCountries
) => {
  const sections = get(structureDetails, "sections.items", []);

  return {
    id: structureDetails.id,
    name: structureDetails.name,
    sections: sections.map(adaptSection),
    targets: await adaptTargets(
      structureDetails.targets,
      getTargetsData,
      allCountries
    ),
    score: structureDetails.score.value,
    isScoreAutogenerated: structureDetails.score.isAutogenerated,
  };
};
