// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { get, has, transform, pickBy } from "lodash-es";

export type ValidatorPath = string | RegExp;

export const find = <T = unknown>(
  path: ValidatorPath,
  values: Record<string, T>
): Record<string, T> => {
  if (typeof path === "string") {
    return { [path]: get(values, path) };
  }

  if (path instanceof RegExp) {
    return pickBy(values, (_, key) => path.test(key));
  }

  return {};
};

export type Validator<T = unknown, K = unknown> = (
  values: Record<string, T>,
  rawValue: Record<string, K>,
  errors: Record<string, string> | undefined
) => Record<string, string> | undefined;

export const createValidator =
  <T = unknown, K = unknown>(
    fn: (field: T, rawValues: Record<string, K>) => boolean,
    errorStr: string,
    path: ValidatorPath
  ): Validator<T, K> =>
  (
    values: Record<string, T>,
    rawValues: Record<string, K>,
    errors: Record<string, string> | undefined
  ): Record<string, string> => {
    const fieldValues = find(path, values);

    const accumulateErrors = (
      acc: Record<string, string>,
      value: T,
      key: string
    ) => {
      const isError = fn(value, rawValues);
      const isFirstErrorForField = !has(acc, key);
      if (isError && isFirstErrorForField) {
        acc[key] = errorStr;
      }
    };

    return transform(fieldValues, accumulateErrors, { ...errors });
  };
