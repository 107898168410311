// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { PageContent, PageHeader } from "components/layout";
import { trans } from "src/translations";

import { StructureData } from "./StructureData";
import { EditStructureData } from "./StructureForm";

export default class StructureEdit extends Component {
  static propTypes = {
    forAdmin: PropTypes.bool,
  };

  static defaultProps = {
    forAdmin: false,
  };

  render() {
    const { forAdmin } = this.props;

    return (
      <div>
        <PageHeader>{trans.STRUCTURES_EDIT__HEADER()}</PageHeader>
        <PageContent>
          <StructureData>
            {({ allCountries }) => {
              return (
                <EditStructureData
                  allCountries={allCountries}
                  forAdmin={forAdmin}
                />
              );
            }}
          </StructureData>
        </PageContent>
      </div>
    );
  }
}
