// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { WarningModal } from "components/layout";
import { trans } from "src/translations";
import { mutationResultType } from "utils/graphql";

import { TransRemovePropTypes } from "../Labels/propTypes";
import styles from "./modals.scss";

export default function RemoveLabelConfirmModal(props) {
  const { isOpen, texts, onClose, onRemove, removeStatus } = props;

  return (
    <WarningModal
      isOpen={isOpen}
      onClose={onClose}
      title={trans.STRUCTURES_MODAL__REMOVE_HEADER()}
      dataTestId="remove-label-modal"
      actions={
        <ButtonsWrapper position="modal">
          <Button
            type="plain"
            onClick={onRemove}
            dataTestId="remove-label-ok-button"
            processing={removeStatus.loading}
            disabled={removeStatus.loading}
          >
            {trans.STRUCTURES_MODAL__DELETE_BUTTON()}
          </Button>
          <Button
            type="normal"
            onClick={onClose}
            dataTestId="remove-label-cancel-button"
          >
            {trans.CANCEL()}
          </Button>
        </ButtonsWrapper>
      }
    >
      <p>{texts.removeConfirmMessage()}</p>
      {removeStatus.error && (
        <Info type="error" className={styles.error}>
          {removeStatus.error.message || removeStatus.error}
        </Info>
      )}
    </WarningModal>
  );
}

RemoveLabelConfirmModal.propTypes = {
  isOpen: PropTypes.bool,
  texts: TransRemovePropTypes.isRequired,
  onClose: PropTypes.func,
  onRemove: PropTypes.func,
  removeStatus: mutationResultType,
};
