// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Tooltip } from "components/popups";
import { Link } from "components/typography";
import { trans } from "src/translations";

import { PROVIDERS } from "../constants";
import styles from "./ProviderTooltip.scss";

export const ProviderTooltip = ({
  selectedProvider,
  alignment,
  className,
  children,
  action,
  fullInfo = true,
}) => {
  const providerInfo = selectedProvider && PROVIDERS[selectedProvider].info;
  const providerExtendedInfo =
    selectedProvider && PROVIDERS[selectedProvider].extendedInfo;
  const tooltipContent = (
    <div className={styles.tooltipContent}>
      {fullInfo && <p>{providerInfo}</p>}
      <p>{providerExtendedInfo}</p>
      <Link className={styles.link} onClick={action}>
        {trans.STRUCTURES_EDIT__TYPE()}
      </Link>
    </div>
  );

  return (
    <Tooltip
      alignment={alignment}
      className={className}
      content={tooltipContent}
    >
      {children}
    </Tooltip>
  );
};

ProviderTooltip.propTypes = {
  selectedProvider: PropTypes.string,
  action: PropTypes.func.isRequired,
  alignment: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  fullInfo: PropTypes.bool,
};
