// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useContext } from "react";

import PropTypes from "prop-types";

import {
  ColorField,
  ResolutionField,
  SelectField,
  TextField,
  EditorField,
  NumberField,
} from "components/form/fields";
import { Uploader } from "components/form/Uploader";
import { Column, Row } from "components/layout";
import { EditorThemeContext } from "pages/AppManifests/_shared/ManifestData";
import { HtmlTranslation, trans } from "src/translations";
import { validateImage } from "utils/image";

import { FIELD_DESCRIPTION } from "../../constants";
import styles from "./ManifestEdit.scss";
import { ManifestEditCustomField } from "./ManifestEditCustomField";
import { ManifestEditFieldReset } from "./ManifestEditFieldReset";
import { ManifestEditKeysField } from "./ManifestEditKeysField";

const getInputId = (name) => `${name}-input-id`;

const renderField = ({ name, type, values, meta: imageMeta }) => {
  let fieldRenderer;
  const props = {
    id: getInputId(name),
    look: "simple",
    name: name,
    required: false,
  };
  switch (type) {
    case "select":
      fieldRenderer = <SelectField {...props} values={values} />;
      break;
    case "color":
      fieldRenderer = (
        <ColorField
          {...props}
          popupPosition="left"
          presets={[
            { value: "#000000", label: "black" },
            { value: "#FFFFFF", label: "white" },
          ]}
        />
      );
      break;
    case "resolution":
      fieldRenderer = <ResolutionField {...props} />;
      break;
    case "number":
      fieldRenderer = <NumberField {...props} />;
      break;
    case "image":
      return (
        <Uploader
          {...props}
          validate={async (blobUrl) => await validateImage(blobUrl, imageMeta)}
          formats={["image/jpeg", "image/jpg", "image/png"]}
        />
      );
    default:
      fieldRenderer = <TextField {...props} />;
  }
  return (
    <>
      {fieldRenderer}
      <ManifestEditFieldReset name={name} />
    </>
  );
};

export const ManifestEditField = ({ name, ...rest }) => {
  const { editorTheme, toggleTheme } = useContext(EditorThemeContext);

  switch (name) {
    case "keyRemapping":
      return <ManifestEditKeysField name={name} />;
    case "custom":
      return <ManifestEditCustomField name={name} />;
    case "userScript":
      return (
        <EditorField
          name={name}
          language="javascript"
          look="simple"
          formats={["text/javascript"]}
          required={false}
          uploadActionText={trans.APP_DETAILS_MANIFEST__SCRIPT_UPLOAD()}
          editorTheme={editorTheme}
          toggleTheme={toggleTheme}
        />
      );
    default:
      return (
        <Row className={styles.formRow}>
          <Column className={styles.labelCol}>
            <label className={styles.label} htmlFor={getInputId(name)}>
              {name}
            </label>
            {name !== "keyRemapping" && (
              <HtmlTranslation
                transFunc={FIELD_DESCRIPTION[name]}
                component="p"
              />
            )}
          </Column>
          <Column className={styles.inputCol}>
            {renderField({ name, ...rest })}
          </Column>
        </Row>
      );
  }
};

ManifestEditField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    "array",
    "color",
    "select",
    "resolution",
    "javascript",
    "text",
  ]),
  values: PropTypes.array,
};
