// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent, Fragment } from "react";

import get from "lodash-es/get";
import isEmpty from "lodash-es/isEmpty";
import PropTypes from "prop-types";
import queryString from "query-string";

import { GrabsonIcon } from "components/icons";
import { TableRow, TableRowColumn } from "components/layout";
import { ActionMenu, MenuItem } from "components/popups";
import { NO_VALUE_PLACEHOLDER } from "src/constants";
import { trans } from "src/translations";
import { formatDateTime } from "utils/date";
import { withRouter } from "utils/decorators";

import { StructurePropType } from "./propTypes";
import styles from "./Structures.scss";
import StructureSectionRow from "./StructureSectionRow";

@withRouter
export default class StructureRow extends PureComponent {
  static propTypes = {
    structure: StructurePropType,
    openPackageModal: PropTypes.func,
    openRemoveModal: PropTypes.func,
    forAdmin: PropTypes.bool,

    // from @withRouter
    navigate: PropTypes.func.isRequired,
    location: PropTypes.object,
  };

  state = {
    isExpanded: false,
  };

  onToggle = () => {
    this.setState((prevState) => ({
      isExpanded: !prevState.isExpanded,
    }));
  };

  render() {
    const {
      structure,
      openPackageModal,
      openRemoveModal,
      forAdmin,
      navigate,
      location: { search } = {},
    } = this.props;
    const { isExpanded } = this.state;

    const page = Number(queryString.parse(search).page) || 1;

    const editStructurePath = forAdmin
      ? `/structures/admin/edit/${structure.id}/basic-details`
      : `/structures/edit/${structure.id}/basic-details`;

    return (
      <Fragment>
        <TableRow>
          <TableRowColumn className={styles.structure} onClick={this.onToggle}>
            <GrabsonIcon
              name={isExpanded ? "arrow-up" : "arrow-down"}
              size="sm"
              font="micro"
              className={styles.toggleIcon}
            />
            <div>
              <h4 className={styles.name}>{structure.name}</h4>
            </div>
          </TableRowColumn>
          <TableRowColumn>
            {get(structure, "score.value", NO_VALUE_PLACEHOLDER)}
          </TableRowColumn>
          <TableRowColumn>
            {formatDateTime(structure.lastModifiedDate)}
          </TableRowColumn>
          <TableRowColumn className={styles.structureEdit}>
            <ActionMenu>
              <MenuItem
                onClick={() =>
                  navigate({ pathname: editStructurePath }, { state: { page } })
                }
              >
                {trans.STRUCTURES_LABELS__EDIT_OPTION()}
              </MenuItem>
              <MenuItem onClick={() => openRemoveModal(structure)}>
                {trans.DELETE()}
              </MenuItem>
            </ActionMenu>
          </TableRowColumn>
        </TableRow>
        {isExpanded &&
          !isEmpty(structure.sections) &&
          structure.sections.map((section) => (
            <StructureSectionRow
              key={section.id}
              section={section}
              openPackageModal={openPackageModal}
            />
          ))}
      </Fragment>
    );
  }
}
