// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { TableBody } from "components/layout";
import { Tooltip } from "components/popups";
import { TabbedTable } from "containers/TabbedTable";
import { trans } from "src/translations";

import { DefaultSortContext } from "../../_shared/Context/defaultSortContext";
import { StructurePropType } from "./propTypes";
import StructureRow from "./StructureRow";
import { ITEMS_PER_PAGE } from "./StructuresData";
import styles from "./StructuresTable.scss";

export default function StructuresTable(props) {
  const {
    structures,
    count,
    loading,
    error,
    openPackageModal,
    openRemoveModal,
    forAdmin,
  } = props;

  return (
    <DefaultSortContext.Consumer>
      {(defaultOrder) => (
        <TabbedTable
          renderTableBody={() => (
            <TableBody>
              {structures.map((structure) => (
                <StructureRow
                  key={structure.id}
                  structure={structure}
                  openPackageModal={openPackageModal}
                  openRemoveModal={openRemoveModal}
                  forAdmin={forAdmin}
                />
              ))}
            </TableBody>
          )}
          tabsConfig={StructuresTable.tabsConfig}
          data={{
            results: structures,
            count,
            loading,
            error,
            defaultOrder,
          }}
          noResultsMessage={<p>{trans.STRUCTURES_LIST__EMPTY_LIST()}</p>}
          rowsPerPage={ITEMS_PER_PAGE}
        />
      )}
    </DefaultSortContext.Consumer>
  );
}

StructuresTable.propTypes = {
  structures: PropTypes.arrayOf(StructurePropType),
  count: PropTypes.number,
  loading: PropTypes.bool,
  error: PropTypes.string,
  openPackageModal: PropTypes.func,
  openRemoveModal: PropTypes.func,
  forAdmin: PropTypes.bool,
};

StructuresTable.defaultProps = {
  loading: false,
};

StructuresTable.tabsConfig = [
  {
    dataTestId: "structures-table",
    columns: [
      {
        label: trans.STRUCTURES_LIST__COLUMN_NAME(),
        className: styles.nameColumnLabel,
        sortBy: "name",
      },
      {
        label: (
          <span>
            {trans.STRUCTURES_LIST__COLUMN_SCORE()}
            <Tooltip
              content={trans.STRUCTURES_LIST__COLUMN_SCORE_TOOLTIP()}
              alignment="bottom"
              className={styles.tooltip}
              classNamePopup={styles.tooltipPopup}
            >
              (?)
            </Tooltip>
          </span>
        ),
        sortBy: "score",
      },
      {
        label: trans.STRUCTURES_LIST__COLUMN_LAST_EDITED(),
        sortBy: "last_edited",
      },
      { label: "" },
    ],
  },
];
