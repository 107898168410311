// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { StaticPage, PageContentError } from "components/layout";
import { Link } from "components/typography";
import { apiThatThrows } from "containers/Request";
import { trans } from "src/translations";

import { DISTRIBUTION_TYPES } from "../constants";
import { getDistributionType } from "../EditApp/utils/appInventoryApiAdapter";
import styles from "./Status.scss";

@connect(null, (dispatch) => ({
  getApplication: (appId) =>
    dispatch(
      apiThatThrows.getApplication.action({
        params: { id: appId },
      })
    ),
  getLastVersion: (appId) =>
    dispatch(
      apiThatThrows.getApplicationFullVersionsList.action({
        params: { app_id: appId },
        queryParams: { ordering: "-created_date", limit: 1 },
      })
    ),
}))
export class Status extends Component {
  static propTypes = {
    // form @ExtendedRoute
    params: PropTypes.object.isRequired,

    // from @withActiveOrganization
    activeOrganization: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,

    // from @connect
    getApplication: PropTypes.func.isRequired,
    getLastVersion: PropTypes.func.isRequired,
  };

  state = {
    loading: true,
    error: null,
    application: null,
    lastVersion: null,
  };

  async componentDidMount() {
    this.updateData();
  }

  componentDidUpdate(prevProps) {
    // Update data when either the application or the active organization has
    // changed.
    const appChanged = this.props.params.id !== prevProps.params.id;
    const activeOrgChanged =
      this.props.activeOrganization !== prevProps.activeOrganization;
    if (appChanged || activeOrgChanged) {
      this.updateData();
    }
  }

  async updateData() {
    const { getApplication, getLastVersion } = this.props;

    this.setState({
      loading: true,
      error: null,
    });

    try {
      const appId = this.getAppId();
      const requests = [getApplication(appId), getLastVersion(appId)];
      const results = await Promise.all(requests);

      this.setState({
        application: results[0].results,
        lastVersion: get(results[1], "results[0]", {}),
      });
    } catch (error) {
      this.setState({
        error,
      });
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  getHeader() {
    if (this.isTestApplication()) {
      return trans.APP_EDIT_STATUS__TEST_SUCCESS();
    }

    const distributionType = getDistributionType(this.state.lastVersion);

    switch (distributionType) {
      case DISTRIBUTION_TYPES.EXAMINE:
        return trans.APP_EDIT_STATUS__EXAMINE_HEADER();
      case DISTRIBUTION_TYPES.VEWD_CERTIFICATION_PROGRAM:
        return trans.APP_EDIT_STATUS__CERTIFICATION_HEADER();
      case DISTRIBUTION_TYPES.VEWD_CATALOGUE:
        return trans.APP_EDIT_STATUS__DISTRIBUTABLE_HEADER();
      default:
        return "";
    }
  }

  getContent() {
    const { support_email = "" } = this.state.lastVersion;

    if (this.isTestApplication()) {
      return null;
    }

    const distributionType = getDistributionType(this.state.lastVersion);

    let contentText = "";

    switch (distributionType) {
      case DISTRIBUTION_TYPES.EXAMINE:
        contentText = trans.APP_EDIT_STATUS__EXAMINE_CONTENT();
        break;
      case DISTRIBUTION_TYPES.VEWD_CERTIFICATION_PROGRAM:
        contentText = trans.APP_EDIT_STATUS__CERTIFICATION_CONTENT();
        break;
      case DISTRIBUTION_TYPES.VEWD_CATALOGUE:
        contentText = trans.APP_EDIT_STATUS__DISTRIBUTABLE_CONTENT();
        break;
    }

    return (
      <>
        <p className={styles.acpContent}>{contentText}</p>
        <h4>{trans.APP_EDIT_STATUS__CERTIFICATION_EMAIL_CONTENT()}</h4>
        {trans.APP_EDIT_STATUS__VERIFY_EMAIL({ support_email })}
      </>
    );
  }

  isTestApplication() {
    return get(this.state.application, "is_test", false);
  }

  isDistributable() {
    return get(this.state.lastVersion, "is_distributable", true);
  }

  getAppId() {
    return this.props.params.id;
  }

  renderContent() {
    const { error, loading } = this.state;

    if (loading) {
      return <Loader />;
    }

    if (error) {
      return <PageContentError error={error} />;
    }

    return (
      <>
        <h1>{this.getHeader()}</h1>
        <div>{this.getContent()}</div>
        <Link to="/applications" className={styles.appListLink}>
          {trans.APP_EDIT_STATUS__BACK_BUTTON()}
        </Link>
      </>
    );
  }

  render() {
    return (
      <StaticPage title={trans.APP_EDIT_STATUS__PAGE_HEADER()}>
        {this.renderContent()}
      </StaticPage>
    );
  }
}
