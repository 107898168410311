// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useEffect } from "react";

import { useField } from "formik";
import PropTypes from "prop-types";

import { ValidationBadge } from "components/form";
import { trans } from "src/translations";

import { SwitchField } from ".";
import { RangeInput } from "../elements";
import { FieldProps } from "./propTypes";

export const ScoreField = ({
  name,
  autogenFieldName,
  disabled,
  getScore,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const [{ value: isScoreAutogenerated }] = useField(autogenFieldName);

  useEffect(() => {
    if (isScoreAutogenerated) {
      helpers.setValue(getScore());
      helpers.setTouched();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScoreAutogenerated, getScore]);

  return (
    <>
      <RangeInput
        {...field}
        {...meta}
        required={true}
        disabled={disabled || isScoreAutogenerated}
        {...props}
      />
      <SwitchField
        name={autogenFieldName}
        label={trans.STRUCTURES_EDIT__SCORE_AUTO_FIELD()}
        disabled={disabled}
      />
      {Boolean(meta.error) && (
        <ValidationBadge error={meta.error} touched={meta.touched} />
      )}
    </>
  );
};

ScoreField.propTypes = {
  ...FieldProps,
  autogenFieldName: PropTypes.string.isRequired,
  getScore: PropTypes.func.isRequired,
};
