// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { withActiveOrganization } from "containers/Auth/decorators";
import { restrictedArea, ROLES } from "containers/Permissions";
import { withRouter } from "utils/decorators";
import { getGqlErrorMessage } from "utils/errors";
import { withQuery, queryResultType } from "utils/graphql";

import AdminStructureQuery from "./gql/adminStructure";
import StructureQuery from "./gql/structure";
import StructureForm from "./StructureForm";
import { calculateScore } from "./utils/calculateScore";
import { getCopiedStructureData } from "./utils/getCopiedStructureData";

@withRouter
@restrictedArea((props) => {
  const allowed = [];

  if (props.forAdmin) {
    allowed.push(ROLES.administrator.structuresAdmin);
  } else {
    allowed.push(ROLES.structure.structureManager);
  }

  return {
    allowed,
  };
})
@withActiveOrganization
@withQuery({ name: "structure", query: StructureQuery })
@withQuery({ name: "adminStructure", query: AdminStructureQuery })
export class CreateStructureData extends Component {
  static propTypes = {
    allCountries: PropTypes.array.isRequired,
    forAdmin: PropTypes.bool.isRequired,

    // from @withRouter
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,

    // from @withActiveOrganization
    activeOrganization: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),

    // from @withQuery
    structureQuery: PropTypes.func.isRequired,
    structureQueryStatus: queryResultType,
    adminStructureQuery: PropTypes.func.isRequired,
    adminStructureQueryStatus: queryResultType,
  };

  state = {
    initialNameValue: "",
  };

  async componentDidMount() {
    const { location } = this.props;
    const name = get(location, "state.name");
    const structureToCopyId = get(location, "state.structureToCopyId");

    this.setState({
      initialNameValue: name,
    });

    if (structureToCopyId) {
      this.fetchStructure(structureToCopyId);
    }
  }

  componentDidUpdate(prevProps) {
    const organizationHasChanged =
      this.props.activeOrganization.id !== prevProps.activeOrganization.id;

    if (organizationHasChanged) {
      const { forAdmin, navigate } = this.props;

      const structuresListPath = forAdmin
        ? "/structures/admin/list"
        : "/structures/list";

      // Because of reloding the route in withActiveOrganization we have to wait for then next tick to redirect.
      setTimeout(() => {
        navigate({ pathname: structuresListPath });
      }, 0);
    }
  }

  async fetchStructure(structureToCopyId) {
    const { forAdmin, structureQuery, adminStructureQuery } = this.props;

    const request = forAdmin ? adminStructureQuery : structureQuery;

    await request({
      variables: {
        structureId: structureToCopyId,
      },
    });
  }

  render() {
    const {
      structureQueryStatus,
      adminStructureQueryStatus,
      allCountries,
      forAdmin,
    } = this.props;
    const { initialNameValue } = this.state;
    const { data, loading, error } = forAdmin
      ? adminStructureQueryStatus
      : structureQueryStatus;

    const allCountriesCount = allCountries.length;
    const structure = get(
      data,
      `${forAdmin ? "adminStructure" : "structure"}.structure`
    );
    const initialValues = {
      ...getCopiedStructureData(initialNameValue, structure),
      score: calculateScore({
        targets: [],
        allCountriesCount,
        forAdmin: forAdmin,
      }),
      isScoreAutogenerated: true,
    };

    return (
      <StructureForm
        initialValues={initialValues}
        loading={loading}
        requestError={error && getGqlErrorMessage(error)}
        allCountriesCount={allCountriesCount}
        forAdmin={forAdmin}
      />
    );
  }
}
