import { Component, ComponentType } from "react";
import { connect, ConnectedProps } from "react-redux";

import PropTypes from "prop-types";

import { api } from "../../containers/Request";
import { Country, getContinentName, isRealContinent } from "./utils";

export const CountriesPropType = PropTypes.shape({
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  allCountries: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      continents: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.string,
      type: PropTypes.string,
    })
  ),
});

export interface WithCountriesProps {
  countriesLoading: boolean;
  countriesError: boolean;
  allCountries: Country[];
}

interface CountryResult {
  id: string;
  code: string;
  human_name: string;
  continents: { id: string }[];
}

interface WithCountriesState {
  loading: boolean;
  error: Error | null;
  countries: Country[];
}

const connector = connect(null, (dispatch) => ({
  getCountries: () =>
    dispatch(
      api.getCountries.action({
        cache: true,
        queryParams: { limit: "nolimit" },
      })
    ),
}));

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & WithCountriesProps;

export const withCountries = <TProps extends Props = Props>(
  ComposedComponent: ComponentType<TProps>
) => {
  class WithCountriesDecorator extends Component<Props, WithCountriesState> {
    private isStillMounted = false;

    state = {
      loading: true,
      error: null,
      countries: [],
    };

    componentDidMount() {
      this.isStillMounted = true;
      this.refreshData();
    }

    componentWillUnmount() {
      this.isStillMounted = false;
    }

    asFrontendCountry = (country: CountryResult): Country => ({
      type: "country",
      code: country.code,
      name: country.human_name,
      continents: country.continents
        .map((c) => getContinentName(c.id) || "")
        .filter(isRealContinent),
    });

    async refreshData() {
      const { getCountries } = this.props;
      const { error, result } = await getCountries();

      if (this.isStillMounted && error) {
        this.setState({
          error,
          loading: false,
        });
      } else if (this.isStillMounted) {
        this.setState({
          countries: result.results.map(this.asFrontendCountry),
          loading: false,
        });
      }
    }

    render() {
      const { error, countries, loading } = this.state;

      return (
        <ComposedComponent
          {...(this.props as TProps)}
          countriesLoading={loading}
          countriesError={error}
          allCountries={countries}
        />
      );
    }
  }

  return connector(WithCountriesDecorator);
};
