// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Routes, Navigate, Route } from "react-router-dom";

import { ExtendedRoute } from "containers/ExtendedRoute";
import { RedirectDependingOnRoles } from "containers/Layout/SideMenu/redirect/RedirectDependingOnRoles";
import { trans } from "src/translations";

import PackageLabels from "./PackageLabels";
import SectionLabels from "./SectionLabels";
import StructureCreator from "./StructureCreator";
import StructureEdit from "./StructureEdit";
import StructuresList from "./StructuresList";

export function StructuresRoute() {
  return (
    <Routes>
      <Route index element={<RedirectDependingOnRoles />} />
      <Route
        exact
        path="/admin/list"
        element={
          <ExtendedRoute
            name={trans.ADMIN_STRUCTURES_LIST__ROUTE_NAME()}
            component={StructuresList}
            componentProps={{ forAdmin: true }}
          />
        }
      />
      <Route
        exact
        path="/admin/create/:tabId"
        element={
          <ExtendedRoute
            name={trans.STRUCTURES_CREATE__ROUTE_NAME()}
            component={StructureCreator}
            componentProps={{ forAdmin: true }}
          />
        }
      />
      <Route
        exact
        path={"/admin/edit/:id/:tabId"}
        element={
          <ExtendedRoute
            name={trans.STRUCTURES_EDIT__ROUTE_NAME()}
            component={StructureEdit}
            componentProps={{ forAdmin: true }}
          />
        }
      />
      <Route
        exact
        path="/list"
        element={
          <ExtendedRoute
            name={trans.STRUCTURES_LIST__ROUTE_NAME()}
            component={StructuresList}
          />
        }
      />
      <Route
        exact
        path="/create/:tabId"
        element={
          <ExtendedRoute
            name={trans.STRUCTURES_CREATE__ROUTE_NAME()}
            component={StructureCreator}
          />
        }
      />
      <Route
        exact
        path={"/edit/:id/:tabId"}
        element={
          <ExtendedRoute
            name={trans.STRUCTURES_EDIT__ROUTE_NAME()}
            component={StructureEdit}
          />
        }
      />
      <Route
        exact
        path="/sections"
        element={
          <ExtendedRoute
            name={trans.STRUCTURES_SECTION__ROUTE_NAME()}
            component={SectionLabels}
          />
        }
      />
      <Route
        exact
        path="/packages"
        element={
          <ExtendedRoute
            name={trans.STRUCTURES_PACKAGE__ROUTE_NAME()}
            component={PackageLabels}
          />
        }
      />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
}
