// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useField, FieldArray } from "formik";
import PropTypes from "prop-types";

import { isPackageValid } from "../../utils/validate";
import { StructureItem } from "../_shared/StructureItem";
import { NO_ITEMS_MESSAGES, STRUCTURE_TYPES, FIELDS } from "../constants";
import { PackageItemsData, PackageEmpty } from "../PackageItems";
import styles from "./Packages.scss";

export const Packages = ({ sectionFieldName, onEdit, onMove, onRemove }) => {
  const packagesFieldName = `${sectionFieldName}.${FIELDS.PACKAGE_FIELD_NAME}`;
  const [packages] = useField(packagesFieldName);

  if (!packages.value?.length) {
    return (
      <PackageEmpty message={NO_ITEMS_MESSAGES[STRUCTURE_TYPES.SECTION]} />
    );
  }

  return (
    <>
      {packages.value.map((packageEntity, idx) => {
        const packageFieldName = `${packagesFieldName}[${idx}]`;
        const packageItemsFieldName = `${packageFieldName}.${FIELDS.PACKAGE_ITEM_FIELD_NAME}`;
        return (
          <StructureItem
            key={packageEntity.id || packageEntity.key}
            index={idx}
            fieldName={packageFieldName}
            type={STRUCTURE_TYPES.PACKAGE}
            validate={isPackageValid}
            onEdit={onEdit}
            onMove={onMove}
            onRemove={onRemove}
            isNested
            rowClassName={styles.row}
            dataTestId={`package-row-${idx}`}
          >
            <FieldArray
              name={packageItemsFieldName}
              render={(arrayHelpers) => (
                <PackageItemsData
                  {...arrayHelpers}
                  packageFieldName={packageFieldName}
                />
              )}
            />
          </StructureItem>
        );
      })}
    </>
  );
};

Packages.propTypes = {
  sectionFieldName: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};
