import { useFormikContext } from "formik";
import PropTypes from "prop-types";

import { TableBody } from "components/layout";
import { TabbedTable } from "containers/TabbedTable";
import { trans } from "src/translations";

import { Sections } from "./Sections/Sections";
import styles from "./StructureCreatorTable.scss";

const tabConfig = [
  {
    id: "structures-creator-table",
    dataTestId: "structures-creator-table",
    columns: [
      {
        id: "name",
        label: trans.STRUCTURES_PACKAGE__LIST_NAME_COL(),
        className: styles.nameHeaderColumn,
      },
      {
        id: "type",
        label: trans.TYPE(),
        className: styles.typeHeaderColumn,
      },
      { id: "customize", label: "", className: styles.customizeHeaderColumn },
      { id: "arrows", label: "", className: styles.arrowsHeaderColumn },
      { id: "edit", label: "", className: styles.editHeaderColumn },
    ],
  },
];

export const StructureCreatorTable = (props) => {
  const { values } = useFormikContext();
  return (
    <TabbedTable
      withPagination={false}
      dataTestId="structure-creator-table"
      renderTableBody={() => (
        <TableBody>
          <Sections {...props} />
        </TableBody>
      )}
      tabsConfig={tabConfig}
      data={{
        results: values.sections,
        loading: false,
      }}
      noResultsMessage={<p>{trans.STRUCTURES_LIST__EMPTY_LIST()}</p>}
    />
  );
};

Sections.propTypes = {
  onMove: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
};
