// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";

import { FIELD_TYPE_AUTO, FIELD_TYPE_SELECT } from "../../constants";
import styles from "./PackageItemsModals.scss";

export const RESULTS_PER_PAGE = 20;
// should be at least as big as RESULTS_PER_PAGE
export const MAX_CHUNK_SIZE = 50;

export const AUTOFILL_ORDER_MAPPING = {
  NAME: "name",
  // "-" is to reverse order (https://search-engine.api-services-int.ci.k8s.tvint.qa/docs#tag/application)
  NEWEST: "-first_approved_date",
  POPULAR: "name",
};

export const CREATE_RADIO_OPTIONS = [
  {
    value: FIELD_TYPE_SELECT,
    label: trans.STRUCTURES_MODAL__PACKAGE_ALL_APPS(),
    dataTestId: "app-list-type-select",
  },
  {
    value: FIELD_TYPE_AUTO,
    label: trans.STRUCTURES_MODAL__PACKAGE_AUTOFILL_APPS(),
    dataTestId: "app-list-type-autofill",
  },
];

export const EDIT_COLUMNS = [
  {
    id: "select",
    dataTestId: "select-column-header",
    className: styles.selectHeader,
  },
  {
    id: "icon",
    label: trans.STRUCTURES_PACKAGE__LIST_ICON_COL(),
    dataTestId: "icon-column-header",
    className: styles.iconHeader,
  },
  {
    id: "name",
    label: trans.STRUCTURES_PACKAGE__LIST_NAME_COL(),
    dataTestId: "name-column-header",
  },
];

export const FIELDS = {
  appListType: {
    name: "appListType",
    displayAsTabs: true,
    inline: true,
    label: null,
    values: CREATE_RADIO_OPTIONS,
    dataTestId: "app-list-type",
  },
  selectedApps: {
    name: "selectedApps",
  },
  filters: {
    name: "filters",
  },
  volatileFilters: {
    name: "volatileFilters",
  },
};

export const INITIAL_FORM_STATE = {
  appListType: FIELD_TYPE_SELECT,
  selectedApps: [],
  filters: {},
  volatileFilters: {},
};

export const PREVIEW_COLUMNS = [
  {
    id: "icon",
    label: trans.STRUCTURES_PACKAGE__LIST_ICON_COL(),
    dataTestId: "icon-column-header",
    className: styles.iconHeader,
  },
  {
    id: "name",
    label: trans.STRUCTURES_PACKAGE__LIST_NAME_COL(),
    dataTestId: "name-column-header",
  },
  {
    id: "category",
    label: trans.STRUCTURES_PACKAGE__LIST_CATEGORY_COL(),
    dataTestId: "category-column-header",
  },
  /*{ id: "dateAdded", label: trans.STRUCTURES_PACKAGE__LIST_DATE_COL() },*/
];
