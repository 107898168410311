// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

const VALID_ATTR_REGEX = /^[\w]+$/;

const validateAttributeName = (name) => {
  return name.length > 0 && VALID_ATTR_REGEX.test(name);
};

export const validateAttributeValue = (value) => {
  return value.length > 0 && VALID_ATTR_REGEX.test(value);
};

export const validateAttribute = (name, value) => {
  return validateAttributeName(name) && validateAttributeValue(value);
};
