// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useCallback, useEffect, useState, useContext } from "react";
import { useDispatch } from "react-redux";

import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { Info } from "components/feedback";
import { apiThatThrows } from "containers/Request";
import { adaptSortingQueryParams, retrieveFiltersFromInclude } from "utils/url";

import { PackageItemsContext } from "../../StructureForm";
import { EDIT_COLUMNS, RESULTS_PER_PAGE } from "./constants";
import { fetchFilters } from "./dataHelpers";
import { EditPackageItemsForm } from "./EditPackageItemsForm";

export const EditPackageItemsData = ({ forAdmin, onClose }) => {
  const dispatch = useDispatch();
  const packageItems = useContext(PackageItemsContext);

  const [isLoading, setIsLoading] = useState(true);
  const [availableFilters, setAvailableFilters] = useState(null);
  const [error, setError] = useState(null);

  const isPackageItemUsed = (id) =>
    packageItems.findIndex((item) => item.id === id) !== -1;

  const getAvailableFilters = useCallback(() => {
    if (forAdmin) {
      return dispatch(
        apiThatThrows.getDistributionApplicationsFilters.action({
          cache: true,
        })
      );
    }
    return dispatch(
      apiThatThrows.getMyDistributionApplicationsFilters.action({
        cache: true,
      })
    );
  }, [forAdmin, dispatch]);

  const getApplicationsPaginated = (params) => {
    const queryParams = retrieveFiltersFromInclude(
      adaptSortingQueryParams(params)
    );
    if (forAdmin) {
      return dispatch(
        apiThatThrows.getDistributionApplicationsPaginated.action({
          queryParams,
        })
      );
    }
    return dispatch(
      apiThatThrows.getMyDistributionApplicationsPaginated.action({
        queryParams,
      })
    );
  };

  const getSearchSuggestions = (queryParams, abortSignal) => {
    if (forAdmin) {
      return dispatch(
        apiThatThrows.getDistributionApplicationsSuggestions.action({
          queryParams,
          abortSignal,
        })
      );
    }
    return dispatch(
      apiThatThrows.getMyDistributionApplicationsSuggestions.action({
        queryParams,
        abortSignal,
      })
    );
  };

  const fetchData = async ({ page, search }) => {
    const { results, meta } = await getApplicationsPaginated({
      limit: RESULTS_PER_PAGE,
      page: page || 0,
      ...search,
    });
    return {
      results: results.filter((item) => !isPackageItemUsed(item.id)),
      meta,
    };
  };

  useEffect(() => {
    async function setupAvailableFilters() {
      try {
        setAvailableFilters(await fetchFilters(getAvailableFilters));
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    }
    setupAvailableFilters();
  }, [getAvailableFilters]);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <Info type="error">{error.message}</Info>;
  }

  return (
    <EditPackageItemsForm
      availableFilters={availableFilters}
      columns={EDIT_COLUMNS}
      fetchData={fetchData}
      getSuggestions={getSearchSuggestions}
      onClose={onClose}
    />
  );
};

EditPackageItemsData.propTypes = {
  forAdmin: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
