// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, Component } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { SearchBarWithFilters } from "components/elements";
import { filterSectionPropTypes } from "components/elements/SearchBarWithFilters/propTypes";
import { Info } from "components/feedback";
import { Section } from "components/layout";
import { withActiveOrganization } from "containers/Auth/decorators";
import { trans } from "src/translations";
import { withRouter } from "utils/decorators/withRouter";
import { createLocationWithSearch } from "utils/url";

import {
  CreateStructureModal,
  PackageItemsModal,
  RemoveStructureConfirmModal,
} from "../Modals";
import { StructurePropType } from "./propTypes";
import styles from "./Structures.scss";
import StructuresTable from "./StructuresTable";

@withRouter
@withActiveOrganization
export default class Structures extends Component {
  static propTypes = {
    refetchList: PropTypes.func,
    data: PropTypes.arrayOf(StructurePropType),
    count: PropTypes.number,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    forAdmin: PropTypes.bool.isRequired,
    availableFilters: PropTypes.shape({
      sections: PropTypes.arrayOf(filterSectionPropTypes).isRequired,
    }),
    excludableAvailableFilters: PropTypes.shape({
      sections: PropTypes.arrayOf(filterSectionPropTypes).isRequired,
    }),
    queryParams: PropTypes.object,

    // from @withRouter
    location: PropTypes.object,
    navigate: PropTypes.func.isRequired,
    // from @withActiveOrganization
    activeOrganization: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  };

  state = {
    currentItem: {},
    isPackageOpen: false,
    isRemoveOpen: false,
    isCreateOpen: false,
  };

  closeModal = () => {
    this.setState({
      isPackageOpen: false,
      isRemoveOpen: false,
      isCreateOpen: false,
    });
  };

  openPackageModal = (item) => {
    this.setState({
      currentItem: item,
      isPackageOpen: true,
    });
  };

  openRemoveModal = (item) => {
    this.setState({
      currentItem: item,
      isRemoveOpen: true,
    });
  };

  openCreateModal = () => {
    this.setState({ isCreateOpen: true });
  };

  onChangeSearchParams = (newSearchParams) => {
    const { location, navigate } = this.props;
    const newLocation = createLocationWithSearch(location, newSearchParams);
    navigate(newLocation);
  };

  render() {
    const {
      data,
      count,
      loading,
      error,
      refetchList,
      location,
      forAdmin,
      activeOrganization: { name },
      availableFilters,
      excludableAvailableFilters,
      queryParams,
    } = this.props;
    const { currentItem, isPackageOpen, isRemoveOpen, isCreateOpen } =
      this.state;

    const locationMessage = get(location, "state.message");

    const renderCreateButton = (
      <ButtonsWrapper>
        <Button
          className={styles.button}
          type="green"
          onClick={this.openCreateModal}
          dataTestId="create-structure-button"
        >
          {trans.STRUCTURES__CREATE_BUTTON()}
        </Button>
      </ButtonsWrapper>
    );

    return (
      <Fragment>
        <Section
          header={
            forAdmin
              ? trans.STRUCTURES_LIST__SECTION_NAME_ADMIN()
              : trans.STRUCTURES_LIST__SECTION_NAME({ organization: name })
          }
        >
          <Section>
            <SearchBarWithFilters
              placeholder={trans.STRUCTURES_LIST__SEARCH_PLACEHOLDER()}
              look="for-white-bg"
              onSearch={this.onChangeSearchParams}
              filtersDataChoices={availableFilters}
              excludableFiltersDataChoices={excludableAvailableFilters}
              hasExcludeOption={true}
              searchParams={queryParams}
            />
          </Section>
          <Section>{locationMessage && <Info>{locationMessage}</Info>}</Section>
          {renderCreateButton}
          <StructuresTable
            loading={loading}
            structures={data}
            count={count}
            error={error}
            openPackageModal={this.openPackageModal}
            openRemoveModal={this.openRemoveModal}
            forAdmin={forAdmin}
          />
          {renderCreateButton}
        </Section>
        <PackageItemsModal
          forAdmin={forAdmin}
          packageData={currentItem}
          isOpen={isPackageOpen}
          onClose={this.closeModal}
        />
        <RemoveStructureConfirmModal
          isOpen={isRemoveOpen}
          onClose={this.closeModal}
          structureId={get(currentItem, "id")}
          refetchList={refetchList}
          forAdmin={forAdmin}
        />
        <CreateStructureModal
          isOpen={isCreateOpen}
          onClose={this.closeModal}
          forAdmin={forAdmin}
        />
      </Fragment>
    );
  }
}
