// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useState } from "react";

import { FieldArray, useFormikContext } from "formik";
import { get } from "lodash-es";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";

import { Button } from "components/buttons";
import { Section } from "components/layout";
import { Header } from "components/typography";
import { trans } from "src/translations";

import { LabelModal } from "../../Modals";
import { EDIT_MODES } from "../../Modals/LabelModal/constants";
import {
  LABEL_MODAL_TEXTS,
  STRUCTURE_TYPES,
  PACKAGE_ITEMS_SORT,
  FIELDS,
} from "./constants";
import { StructureCreatorTable } from "./StructureCreatorTable";
import styles from "./TabStructureCreator.scss";

export const TabStructureCreator = ({
  handleEditLabel,
  handleCreateLabel,
  updateLabel,
  availableProviders,
  setFieldState,
  isSectionLabel,
  fieldName,
  currentLabelItem,
  labelType = "section",
}) => {
  const { values, setFieldValue } = useFormikContext();
  const [{ isEditOpen, isCreateOpen }, setModalState] = useState({
    isEditOpen: false,
    isCreateOpen: false,
  });

  const addNewSection = (section) => {
    setFieldValue(FIELDS.SECTION_FIELD_NAME, [
      ...values[FIELDS.SECTION_FIELD_NAME],
      section,
    ]);
  };

  const addNewPackage = (pack, sectionWithIdxFieldName) => {
    const packageFieldName = `${sectionWithIdxFieldName}.${FIELDS.PACKAGE_FIELD_NAME}`;
    const sectionPackages = get(values, packageFieldName);
    setFieldValue(packageFieldName, [...sectionPackages, pack]);
  };

  const handleCreatePackage = (name, packageLabelId) => {
    const newPackage = {
      key: uuid(),
      name,
      packageLabelId,
      packageItems: [],
      autofillPackageItems: null,
      packageItemsOrderBy: PACKAGE_ITEMS_SORT.CUSTOM,
      isExpanded: true,
    };
    addNewPackage(newPackage, fieldName);
  };

  const changeProvider = (selectedProvider) => {
    setFieldValue(
      `${fieldName}.${FIELDS.PROVIDER_FIELD_NAME}`,
      selectedProvider
    );
  };

  const handleCreateSection = (name, sectionLabelId, selectedProvider) => {
    const newSection = {
      key: uuid(),
      name,
      sectionLabelId,
      packages: [],
      selectedProvider,
      availableProviders: [],
      isExpanded: true,
    };

    addNewSection(newSection);
  };

  const chooseLabelModalTexts = () => {
    if (isEditOpen) {
      return LABEL_MODAL_TEXTS.EDIT[labelType];
    }
    return LABEL_MODAL_TEXTS.CREATE[labelType];
  };

  const getLabelId = (structureItem, type) => {
    if (type === STRUCTURE_TYPES.SECTION) {
      return structureItem.sectionLabelId;
    }
    return structureItem.packageLabelId;
  };

  const openEditModal = (type, structureItem, fieldName, selectedProvider) => {
    const labelItem = {
      id: getLabelId(structureItem, type),
      name: structureItem.name,
      selectedProvider,
    };
    setFieldState({
      fieldName: fieldName,
      currentLabelItem: labelItem,
      labelType: type,
    });
    setModalState({
      isEditOpen: true,
    });
  };

  const openCreateModal = (type, fieldName) => {
    setFieldState({
      fieldName: fieldName,
      currentLabelItem: undefined,
      labelType: type,
    });
    setModalState({ isCreateOpen: true });
  };

  const closeModal = () => {
    setModalState({
      isEditOpen: false,
      isCreateOpen: false,
    });
  };

  const handleEdit = async (values, selectedProvider) => {
    let { name, id } = values;
    if (Array.isArray(values)) {
      name = await handleEditLabel(values);
    } else if (values.mode === EDIT_MODES.REPLACE_NEW) {
      ({ name, id } = await handleCreateLabel(values.translations));
    }
    if (selectedProvider) {
      changeProvider(selectedProvider);
    }
    updateLabel({ name, id, translations: values });
    closeModal();
  };

  const handleCreate = async (values, selectedProvider) => {
    // Create Copy mode
    let { name, id } = values;
    if (!name && !id) {
      // Create Normal mode
      ({ name, id } = await handleCreateLabel(values));
    }
    if (isSectionLabel()) {
      handleCreateSection(name, id, selectedProvider);
    } else {
      handleCreatePackage(name, id);
    }
    closeModal();
  };

  const texts = chooseLabelModalTexts();

  return (
    <div className={styles.content}>
      <Header type="h3" bold={true} className={styles.header}>
        {trans.STRUCTURES_CREATOR__TITLE()}
      </Header>
      <p className={styles.description}>
        {trans.STRUCTURES_CREATOR__DESCRIPTION()}
      </p>
      <Header type="h5" bold={true} className={styles.tableHeader}>
        {trans.STRUCTURES_CREATOR__STRUCTURE_TREE()}
      </Header>
      <FieldArray
        name={FIELDS.SECTION_FIELD_NAME}
        render={({ move, remove, name }) => (
          <>
            <Section>
              <StructureCreatorTable
                onMove={move}
                onRemove={remove}
                onCreate={openCreateModal}
                onEdit={openEditModal}
              />
              <div className={styles.addNewSectionContainer}>
                <Button
                  type="normal"
                  className={styles.addNewSectionButton}
                  onClick={() => openCreateModal(STRUCTURE_TYPES.SECTION, name)}
                >
                  {trans.STRUCTURES_CREATOR__ADD_NEW_SECTION()}
                </Button>
              </div>
            </Section>
            <LabelModal
              fieldName={fieldName}
              item={currentLabelItem}
              isOpen={isEditOpen || isCreateOpen}
              labelType={labelType}
              texts={texts}
              onClose={closeModal}
              onSubmit={isEditOpen ? handleEdit : handleCreate}
              availableProviders={availableProviders}
              structureFormValues={values}
            />
          </>
        )}
      />
    </div>
  );
};

TabStructureCreator.propTypes = {
  handleCreateLabel: PropTypes.func.isRequired,
  handleEditLabel: PropTypes.func.isRequired,
  updateLabel: PropTypes.func.isRequired,
  setFieldState: PropTypes.func.isRequired,
  isSectionLabel: PropTypes.func.isRequired,
  availableProviders: PropTypes.array,
  fieldName: PropTypes.string,
  currentLabelItem: PropTypes.string,
  labelType: PropTypes.string,
};
