// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import get from "lodash-es/get";

import { adaptSection } from "./adapters";

const getCopiedPackagesData = (sectionDetails) => {
  const packages = get(sectionDetails, "packages.items", []);
  return packages.map(({ id, ...packageDetails }) => ({
    ...packageDetails,
    key: id,
  }));
};

const getCopiedSectionsData = (baseStructure) => {
  const sections = get(baseStructure, "sections.items", []);
  return sections.map(({ id, ...sectionDetails }) => ({
    ...sectionDetails,
    key: id,
    packages: { items: getCopiedPackagesData(sectionDetails) },
  }));
};

export const getCopiedStructureData = (name, baseStructure) => {
  const copiedSections = getCopiedSectionsData(baseStructure);
  return {
    name: name || get(baseStructure, "name", ""),
    sections: copiedSections.map(adaptSection),
    targets: [],
  };
};
