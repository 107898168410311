// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { apiThatThrows } from "containers/Request";
import { withQuery } from "utils/graphql";

import { packageLabelGql } from "../../Labels/gql/packageLabel";
import { sectionLabelGql } from "../../Labels/gql/sectionLabel";
import {
  LabelPropTypes,
  TransEditPropTypes,
  LabelType,
} from "../../Labels/propTypes";
import { CREATE_INITIAL_VALUES } from "./constants";
import { LabelModalForm } from "./LabelModalForm";

@connect(null, (dispatch) => ({
  getLanguages: () =>
    dispatch(
      apiThatThrows.getLanguages.action({
        cache: true,
      })
    ),
}))
@withQuery({ name: "packageLabel", query: packageLabelGql })
@withQuery({ name: "sectionLabel", query: sectionLabelGql })
export class EditLabelModalData extends Component {
  static propTypes = {
    labelType: LabelType,
    item: LabelPropTypes,
    texts: TransEditPropTypes.isRequired,
    onSubmit: PropTypes.func,
    mode: PropTypes.string,
    onChangeMode: PropTypes.func,
    fieldName: PropTypes.string,
    availableProviders: PropTypes.array,
    validate: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    // from @connect
    getLanguages: PropTypes.func.isRequired,

    // from @withQuery
    packageLabelQuery: PropTypes.func,
    packageLabelQueryStatus: PropTypes.object,
    sectionLabelQuery: PropTypes.func,
    sectionLabelQueryStatus: PropTypes.object,
  };

  state = {
    languages: [],
    loading: false,
    initialValues: {},
  };

  componentDidMount() {
    if (this.props.item) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.item !== this.props.item) {
      this.fetchData();
    }
  }

  async fetchData() {
    const { getLanguages, packageLabelQuery, sectionLabelQuery, item } =
      this.props;

    try {
      this.setState({
        loading: true,
      });
      const languagesResponse = await getLanguages();

      let labelDetails;
      if (this.isSectionLabelType()) {
        const labelResponse = await sectionLabelQuery({
          variables: { sectionLabelId: item.id },
        });
        labelDetails = {
          ...get(labelResponse, "sectionLabel.sectionLabel"),
          providerField: item.selectedProvider,
        };
      } else {
        const labelResponse = await packageLabelQuery({
          variables: { packageLabelId: item.id },
        });
        labelDetails = get(labelResponse, "packageLabel.packageLabel");
      }

      this.setState({
        languages: languagesResponse.results,
        initialValues: this.adaptLabelToInitialValues(labelDetails),
      });
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  adaptLabelToInitialValues(labelDetails) {
    const initialValues = {
      translationsNew: CREATE_INITIAL_VALUES.translations,
      labelToCopy: {},
    };

    initialValues.translations = labelDetails.translations.map(
      (translation) => {
        return {
          languageCode: translation.language.code,
          translation: translation.translation,
          isDefault: translation.isDefault,
        };
      }
    );

    if (this.isSectionLabelType()) {
      initialValues.thumbnailUrl = labelDetails.thumbnail.url;
      initialValues.providerField = labelDetails.providerField;
    }
    return initialValues;
  }

  isSectionLabelType() {
    const { labelType } = this.props;
    return labelType === "section";
  }

  getLabelQueryStatus() {
    const { labelType, packageLabelQueryStatus, sectionLabelQueryStatus } =
      this.props;
    return labelType ? sectionLabelQueryStatus : packageLabelQueryStatus;
  }

  render() {
    const {
      texts,
      mode,
      onChangeMode,
      labelType,
      fieldName,
      availableProviders,
      item,
      validate,
      onClose,
      onSubmit,
    } = this.props;

    const { loading, languages, initialValues } = this.state;

    return (
      <LabelModalForm
        mode={mode}
        texts={texts}
        languages={languages}
        loading={loading || this.getLabelQueryStatus().loading}
        onChangeMode={onChangeMode}
        labelType={labelType}
        fieldName={fieldName}
        availableProviders={availableProviders}
        selectedProvider={item.selectedProvider}
        initialValues={initialValues}
        validate={validate}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    );
  }
}
