// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { TableRow, TableRowColumn } from "components/layout";

import styles from "./PackageEmpty.scss";

export function PackageEmpty({ message }) {
  return (
    <TableRow className={styles.row} highlighting={false}>
      <TableRowColumn colspan={5}>
        <p className={styles.message}>{message}</p>
      </TableRowColumn>
    </TableRow>
  );
}

PackageEmpty.propTypes = {
  message: PropTypes.string.isRequired,
};
