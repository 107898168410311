// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import uniq from "lodash-es/uniq";

const MANAGER_BASE_SCORE = 50,
  ADMIN_BASE_SCORE = 0,
  SCORE_MULTIPLIER = 25,
  DEVICE_SCORE_TOP = 1,
  DEVICE_SCORE_MID = 0.5,
  DEVICE_SCORE_LOW = 0;

const getDeviceScore = (target) => {
  if (target.distributors?.length > 0) {
    return DEVICE_SCORE_LOW;
  }
  if (target.deviceFamilies?.length > 0) {
    return DEVICE_SCORE_MID;
  }
  if (target.deviceModels?.length > 0 || target.collections?.length > 0) {
    return DEVICE_SCORE_TOP;
  }
  return 0;
};

// This will always return 1 for granular targets only; this is intentional
const getCountryScore = (countries, allCountriesCount) => {
  return (allCountriesCount - countries.length) / allCountriesCount;
};

export const calculateScore = ({
  forAdmin = false,
  targets = [],
  allCountriesCount,
}) => {
  const deviceScores = [];
  const countries = [];

  targets.forEach((target) => {
    deviceScores.push(getDeviceScore(target));
    countries.push(...target.countries);
  });
  const countryScore = getCountryScore(uniq(countries), allCountriesCount);
  const deviceScore = deviceScores.length ? Math.min(...deviceScores) : 0;
  return Math.round(
    (forAdmin ? ADMIN_BASE_SCORE : MANAGER_BASE_SCORE) +
      SCORE_MULTIPLIER * (deviceScore + countryScore)
  );
};
