// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, PureComponent } from "react";

import { connect } from "formik";
import PropTypes from "prop-types";

import { Label } from "components/form";
import { SwitchField, TextField } from "components/form/fields";
import { trans } from "src/translations";

import styles from "./CustomDownloadUrlField.scss";

@connect
export class DownloadUrlField extends PureComponent {
  render() {
    const hasCustomUrl = this.props.formik.values?.hasCustomUrl;

    return (
      <Fragment>
        <Label text={trans.UPDATES__FORM_FIELD_CUSTOM_DOWNLOAD_SWITCH()}>
          <SwitchField name="hasCustomUrl" />
        </Label>
        {hasCustomUrl && (
          <Label text={trans.UPDATES__FORM_FIELD_CUSTOM_DOWNLOAD_INPUT_LABEL()}>
            <TextField
              name="customDownloadUrl"
              placeholder={trans.UPDATES__FORM_FIELD_CUSTOM_DOWNLOAD_INPUT_PLACEHOLDER()}
              className={styles.customDownloadInput}
              disabled={!hasCustomUrl}
            />
          </Label>
        )}
      </Fragment>
    );
  }
}

DownloadUrlField.propTypes = {
  formik: PropTypes.string.isRequired,
};
