// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { SearchableList } from "components/elements";
import { Modal } from "components/layout";
import { InfiniteData } from "containers/InfiniteData";
import { trans } from "src/translations";
import { getGqlErrorMessage } from "utils/errors";

import { DownloadUrlField } from "../../fields/CustomDownloadUrlField";
import { ITEMS_PER_PAGE } from "./constants";
import { currentVersionPropTypes } from "./propTypes";
import styles from "./SelectSoftwarePackageModal.scss";
import { SoftwarePackageRow } from "./SoftwarePackageRow";

export class SelectSoftwarePackageModalRend extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    setCurrentVersion: PropTypes.func.isRequired,
    currentVersion: currentVersionPropTypes,
    fetchSoftwarePackages: PropTypes.func.isRequired,
  };

  renderHeaders = () => {
    return (
      <div className={styles.headerRow}>
        <span className={styles.headerPackageName}>
          {trans.UPDATES__FORM_SELECT_SOFTWARE_PACKAGE_MODAL_PACKAGE_NAME()}
        </span>
        <span className={styles.headerVersionQuantity}>
          {trans.UPDATES__FORM_SELECT_SOFTWARE_PACKAGE_MODAL_VERSION_QUANTITY()}
        </span>
      </div>
    );
  };

  renderRow = (softwarePackage, idx) => {
    const { currentVersion, setCurrentVersion } = this.props;

    return (
      <SoftwarePackageRow
        key={softwarePackage.id}
        idx={idx}
        softwarePackage={softwarePackage}
        currentVersion={currentVersion}
        setCurrentVersion={setCurrentVersion}
      />
    );
  };

  render() {
    const { isOpen, onClose, onSubmit, currentVersion, fetchSoftwarePackages } =
      this.props;
    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={trans.UPDATES__FORM_SELECT_SOFTWARE_PACKAGE_MODAL_TITLE()}
        dataTestId="select-version-modal"
        size="big"
        actions={
          <ButtonsWrapper position="modal">
            <Button
              type="normal"
              onClick={onClose}
              dataTestId="select-version-modal-close-button"
            >
              {trans.CANCEL()}
            </Button>
            <Button
              type="green"
              onClick={onSubmit}
              dataTestId="select-version-modal-select-button"
              disabled={currentVersion == null}
            >
              {trans.SELECT()}
            </Button>
          </ButtonsWrapper>
        }
      >
        <div className={styles.grid}>
          <InfiniteData
            fetchData={fetchSoftwarePackages}
            itemsPerPage={ITEMS_PER_PAGE}
          >
            {({
              data,
              pending,
              error,
              allDataLoaded,
              handleScroll,
              handleSearchChange,
            }) => (
              <SearchableList
                className={styles.packagesList}
                dataTestId="select-version-modal-list"
                list={data}
                onScroll={handleScroll}
                onSearchChange={handleSearchChange}
                withLoader={true}
                loading={pending}
                allDataLoaded={allDataLoaded}
                error={error && getGqlErrorMessage(error)}
                placeholder={trans.UPDATES__FORM_SELECT_SOFTWARE_PACKAGE_MODAL_SEARCH_PLACEHOLDER()}
                optionsListElement={this.renderHeaders}
              >
                {this.renderRow}
              </SearchableList>
            )}
          </InfiniteData>
          <div className={styles.customDownload}>
            <DownloadUrlField />
          </div>
        </div>
      </Modal>
    );
  }
}
