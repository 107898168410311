// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, Component } from "react";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { FormHasErrors } from "components/form";
import { TextField, TextareaField } from "components/form/fields";
import { Section } from "components/layout";
import { withActiveOrganization } from "containers/Auth/decorators";
import { TargetsField, TARGET_FIELD_MODE } from "pages/_shared";
import { HtmlTranslation, trans } from "src/translations";

import { ChangeOngoingCampaignModal } from "../_shared/CampaignForm/ChangeOngoingCampaignModal";
import {
  ScoreField,
  ScopeField,
  EnableField,
  EnableAutoScoreField,
  PromotionsField,
} from "../_shared/CampaignForm/fields";
import { STATUSES, getCampaignStatus } from "../_utils";
import styles from "./AdminCampaignForm.scss";
import { FIELDS, SUBMIT_ATTEMPTED } from "./constants";

@withActiveOrganization
export class AdminCampaignFormRendFields extends Component {
  static propTypes = {
    // from @withActiveOrganization
    activeOrganization: PropTypes.object.isRequired,

    values: PropTypes.object,
    setFieldValue: PropTypes.func,
    isSubmitting: PropTypes.bool,
    dirty: PropTypes.bool,
    handleSubmit: PropTypes.func,
    isReadonly: PropTypes.bool,
    initialValues: PropTypes.object,
    isEdit: PropTypes.bool,
    forAdmin: PropTypes.bool,
    submitFailed: PropTypes.bool,
    setStatus: PropTypes.func,
    errorsPresent: PropTypes.bool,
  };

  state = {
    isChangeOngoingModalOpen: false,
  };

  getCampaignStatus() {
    const {
      values: { scope },
    } = this.props;
    return getCampaignStatus(scope.start, scope.end);
  }

  getInitialCampaignStatus() {
    const { start_date, end_date } = this.props.initialValues;
    return getCampaignStatus(start_date, end_date);
  }

  isInitiallyOngoing() {
    const { is_enabled } = this.props.initialValues;
    return (
      this.props.isEdit &&
      is_enabled &&
      this.getInitialCampaignStatus() === STATUSES.ONGOING
    );
  }

  handleCloseChangeOngoingModal = () => {
    this.setState({ isChangeOngoingModalOpen: false });
  };

  handleSubmitBtnClick = () => {
    const { errorsPresent } = this.props;
    if (this.isInitiallyOngoing() && !errorsPresent) {
      this.setState({ isChangeOngoingModalOpen: true });
    }
  };

  isFinished() {
    return this.props.isEdit && this.getCampaignStatus() === STATUSES.FINISHED;
  }

  getTargetFieldMode = () =>
    this.props.forAdmin
      ? TARGET_FIELD_MODE.ALL_DEVICES
      : TARGET_FIELD_MODE.GRANULAR;

  render() {
    const {
      values,
      activeOrganization,
      setFieldValue,
      isSubmitting,
      dirty,
      handleSubmit,
      isReadonly,
      forAdmin,
      submitFailed,
      setStatus,
    } = this.props;

    const { isChangeOngoingModalOpen } = this.state;
    const { is_score_autogenerated: isScoreAutogenerated } = values;
    const isOngoing = this.isInitiallyOngoing();

    return (
      <Fragment>
        {isOngoing && (
          <Info type="warning">{trans.CAMPAIGN_EDIT__ONGOING_WARNING()}</Info>
        )}
        <Section header={trans.CAMPAIGN_EDIT__SECTION_BASIC_DETAILS()}>
          <TextField isReadonly={isReadonly} {...FIELDS.NAME} />
          <TextareaField isReadonly={isReadonly} {...FIELDS.DESCRIPTION} />
        </Section>
        <Section header={trans.CAMPAIGN_EDIT__SECTION_SCOPE()}>
          <ScopeField
            isReadonly={isReadonly}
            onSetFieldValue={setFieldValue}
            values={values}
          />
          <EnableField isFinished={this.isFinished()} />
        </Section>

        <Section header={trans.CAMPAIGN_EDIT__SECTION_SCORE()}>
          <HtmlTranslation
            className={styles.scoreInfo}
            component="p"
            transFunc={trans.CAMPAIGN_EDIT__FIELD_SCORE_DESCRIPTION}
          />
          <ScoreField disabled={isScoreAutogenerated || isReadonly} />
          <EnableAutoScoreField isReadonly={isReadonly} />
        </Section>

        <Section header={trans.CAMPAIGN_EDIT__SECTION_TARGETS()}>
          <TargetsField
            required={true}
            forAdmin={forAdmin}
            devicesDistributorId={activeOrganization.id}
            readonly={isReadonly}
            mode={this.getTargetFieldMode()}
          />
        </Section>

        <Section header={trans.CAMPAIGN_EDIT__SECTION_PROMOTIONS()}>
          <PromotionsField isReadonly={isReadonly} />
        </Section>
        <FormHasErrors submitFailed={submitFailed} />
        <ButtonsWrapper>
          <Button
            buttonType={isOngoing ? "button" : "submit"}
            type="green"
            disabled={isSubmitting || !dirty}
            processing={isSubmitting}
            onClick={() => {
              setStatus(SUBMIT_ATTEMPTED);
              this.handleSubmitBtnClick();
            }}
            dataTestId="campaign-add-edit-button"
          >
            {this.props.isEdit
              ? trans.SUBMIT_CHANGES()
              : trans.CAMPAIGN__CREATE_BUTTON()}
          </Button>
        </ButtonsWrapper>
        <ChangeOngoingCampaignModal
          isOpen={isChangeOngoingModalOpen}
          submitting={isSubmitting}
          onClose={this.handleCloseChangeOngoingModal}
          onSubmit={() => {
            handleSubmit(this.submit);
          }}
        />
      </Fragment>
    );
  }
}
