// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { WarningModal } from "components/layout";
import { trans } from "src/translations";

import { LabelPropTypes, TransRemovePropTypes } from "../Labels/propTypes";

export default function RemoveLabelImpossibleModal(props) {
  const { isOpen, item, texts, onClose } = props;

  return (
    <WarningModal
      isOpen={isOpen}
      onClose={onClose}
      title={trans.STRUCTURES_MODAL__REMOVE_FAIL_HEADER()}
      dataTestId="remove-label-fail-modal"
      actions={
        <ButtonsWrapper position="modal">
          <Button onClick={onClose} dataTestId="remove-label-close-button">
            {trans.STRUCTURES_MODAL__GO_BACK_BUTTON()}
          </Button>
        </ButtonsWrapper>
      }
    >
      <p>{texts.removeFailMessage({ count: item.useCount })}</p>
    </WarningModal>
  );
}

RemoveLabelImpossibleModal.propTypes = {
  isOpen: PropTypes.bool,
  item: LabelPropTypes.isRequired,
  texts: TransRemovePropTypes.isRequired,
  onClose: PropTypes.func,
};
