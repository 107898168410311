// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import React, { useContext, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { useField } from "formik";
import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { SelectField } from "components/form/fields";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableNoResults,
  TableRowColumn,
  TableRow,
} from "components/layout";
import { trans } from "src/translations";
import { classes } from "utils/classes";

import { FIELD_TYPE_AUTO, FIELD_TYPE_SELECT } from "../../../constants";
import { EditPackageItemsModal } from "../../../Modals";
import { INITIAL_FORM_STATE } from "../../../Modals/PackageItems/constants";
import { ForAdminContext } from "../../utils/context";
import {
  NO_ITEMS_MESSAGES,
  STRUCTURE_TYPES,
  PACKAGE_ITEMS_COLUMNS,
  SORT_VALUES,
  FIELDS,
} from "../constants";
import { PackageAutofillRow } from "./PackageAutofillRow";
import { PackageItemRow } from "./PackageItemRow";
import styles from "./PackageItems.scss";

export const PackageItemsContext = React.createContext([]);

export const PackageItems = ({
  packageFieldName,
  isValid,
  removePackageItem,
  removeAutofill,
  addAutofill,
  addItems,
  handleMove,
  applications,
  loading,
  error,
}) => {
  const forAdmin = useContext(ForAdminContext);
  const [{ value }] = useField(packageFieldName);
  const { packageItems, autofillPackageItems } = value;
  const [{ editModalType, isEditOpen }, setModalState] = useState({
    editModalType: undefined,
    isEditOpen: false,
  });

  const closeEditModal = () => {
    setModalState({
      editModalType: undefined,
      isEditOpen: false,
    });
  };

  const openEditModal = (type) => {
    setModalState({
      editModalType: type,
      isEditOpen: true,
    });
  };

  const getAppDetails = (packageItemId) =>
    applications.find((app) => app.id === packageItemId);

  const handleAddItems = async (values) => {
    closeEditModal();

    if (values.appListType === FIELD_TYPE_AUTO) {
      addAutofill(values.filters);
    } else {
      await addItems(values.selectedApps);
    }
  };

  const renderBody = (provided) => {
    if (error || (!packageItems.length && !autofillPackageItems)) {
      return (
        <TableNoResults
          message={
            error ? error.message : NO_ITEMS_MESSAGES[STRUCTURE_TYPES.PACKAGE]
          }
          isError={Boolean(error)}
          colspan={5}
          loading={loading}
        />
      );
    }

    if (loading) {
      return <TableNoResults colspan={5} loading={loading} />;
    }

    const packageItemsDetails = packageItems
      .map((packageItem) => getAppDetails(packageItem.id))
      .filter((app) => Boolean(app)); // this filters out any undefined values

    return (
      <TableBody>
        {packageItemsDetails.map((app, index) => {
          return (
            <Draggable key={app.id} draggableId={app.id} index={index}>
              {(provided, snapshot) => (
                <PackageItemRow
                  index={index}
                  isDragging={snapshot.isDragging}
                  key={app.id}
                  onRemove={removePackageItem}
                  packageItem={app}
                  innerRef={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                />
              )}
            </Draggable>
          );
        })}
        {provided.placeholder}
        {autofillPackageItems && (
          <PackageAutofillRow
            onEdit={() => openEditModal(FIELD_TYPE_AUTO)}
            onRemove={removeAutofill}
            packageFieldName={packageFieldName}
          />
        )}
      </TableBody>
    );
  };

  const editPackageData = autofillPackageItems
    ? {
        initialValues: Object.assign({}, INITIAL_FORM_STATE, {
          appListType: editModalType,
          filters: autofillPackageItems.filters,
        }),
      }
    : {};

  return (
    <TableRow className={styles.packageRow} highlighting={false}>
      <TableRowColumn
        colspan={5}
        className={classes(styles.container, { [styles.invalid]: !isValid() })}
      >
        <div>
          <SelectField
            name={`${packageFieldName}.${FIELDS.ORDERBY_FIELD_NAME}`}
            label={`${trans.STRUCTURES_PACKAGE_ITEM__SORT()}:`}
            size="compact"
            values={SORT_VALUES}
            labelPosition="left"
          />
          <Button
            type="green"
            className={styles.addPackageItemButton}
            onClick={() => openEditModal(FIELD_TYPE_SELECT)}
          >
            {trans.STRUCTURES_PACKAGE_ITEM__ADD_PACKAGE_ITEM()}
          </Button>
        </div>
        <div className={classes(styles.packageItemsContainer)}>
          <DragDropContext onDragEnd={handleMove}>
            <Droppable droppableId="package-items-droppable">
              {(provided) => (
                <Table
                  innerRef={provided.innerRef}
                  layout="fixed"
                  className={styles.table}
                >
                  <TableHeader position="sticky" loading={loading}>
                    {PACKAGE_ITEMS_COLUMNS.map(({ id, label, className }) => (
                      <TableHeaderColumn key={id} className={className}>
                        {label}
                      </TableHeaderColumn>
                    ))}
                  </TableHeader>
                  {renderBody(provided)}
                </Table>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <PackageItemsContext.Provider value={packageItems}>
          <EditPackageItemsModal
            {...editPackageData}
            forAdmin={forAdmin}
            isOpen={isEditOpen}
            onClose={closeEditModal}
            onSubmit={handleAddItems}
          />
        </PackageItemsContext.Provider>
      </TableRowColumn>
    </TableRow>
  );
};

PackageItems.propTypes = {
  packageFieldName: PropTypes.string.isRequired,
  autofillPackageItems: PropTypes.object,
  isValid: PropTypes.func.isRequired,
  removePackageItem: PropTypes.func.isRequired,
  removeAutofill: PropTypes.func.isRequired,
  addAutofill: PropTypes.func.isRequired,
  addItems: PropTypes.func.isRequired,
  handleMove: PropTypes.func.isRequired,
  applications: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.object,
};
