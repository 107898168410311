// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { gql } from "@apollo/client";

export const editSectionLabelGql = gql`
  mutation editSectionLabel($input: EditSectionLabelInput!) {
    editSectionLabel(input: $input) {
      sectionLabel {
        id
        translations(onlyDefault: false) {
          translation
          isDefault
        }
      }
    }
  }
`;
