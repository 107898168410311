// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { gql } from "@apollo/client";

export default gql`
  query adminStructures(
    $pagination: PaginationInput!
    $search: FilterSearchInput!
  ) {
    adminStructures(pagination: $pagination, search: $search) {
      structures {
        items {
          id
          name
        }
        pagination {
          count
        }
      }
    }
  }
`;
