// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Checkbox } from "components/form/elements";
import {
  TableRow,
  TableRowColumn,
  TableRowColumnExtended,
} from "components/layout";
import premiumIconImg from "images/premium.svg";
import { NO_VALUE_PLACEHOLDER } from "src/constants";
import { formatDate } from "utils/date";

import styles from "./PackageApplicationRow.scss";

export const PackageApplicationRow = ({
  app,
  isChecked,
  visibleColumns,
  onChange,
}) => {
  return (
    <TableRow key={app.id} highlighting={false}>
      {visibleColumns.select && (
        <TableRowColumn className={styles.selectColumn}>
          <Checkbox
            name={`application-checkbox-${app.id}`}
            checked={isChecked}
            value={app.id}
            onChange={() => onChange(app, !isChecked)}
          />
        </TableRowColumn>
      )}
      {visibleColumns.icon && (
        <TableRowColumnExtended icon={app.icon} defaultIcon />
      )}
      <TableRowColumnExtended
        className={styles.nameColumn}
        rightIcon={app && app.isPremium ? premiumIconImg : ""}
        text={app.name}
        to={`/applications/distribution/${app.id}/details`}
        urlTarget="blank"
      />
      {visibleColumns.category && (
        <TableRowColumn>{app.category}</TableRowColumn>
      )}
      {visibleColumns.dateAdded && (
        <TableRowColumn>
          {app.dateAdded
            ? formatDate(new Date(app.dateAdded))
            : NO_VALUE_PLACEHOLDER}
        </TableRowColumn>
      )}
    </TableRow>
  );
};

PackageApplicationRow.propTypes = {
  app: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    category: PropTypes.string,
    dateAdded: PropTypes.string,
    icon: PropTypes.string,
    isPremium: PropTypes.bool,
  }).isRequired,
  isChecked: PropTypes.bool,
  visibleColumns: PropTypes.object,
  onChange: PropTypes.func,
};
