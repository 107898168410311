// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { LabelPropTypes, TransRemovePropTypes } from "../Labels/propTypes";
import RemoveLabelConfirmModal from "./RemoveLabelConfirmModal";
import RemoveLabelImpossibleModal from "./RemoveLabelImpossibleModal";

export default function RemoveLabelModal(props) {
  const { item } = props;

  return item && item.useCount > 0 ? (
    <RemoveLabelImpossibleModal {...props} />
  ) : (
    <RemoveLabelConfirmModal {...props} />
  );
}

RemoveLabelModal.propTypes = {
  isOpen: PropTypes.bool,
  item: LabelPropTypes,
  texts: TransRemovePropTypes.isRequired,
  onClose: PropTypes.func,
  onRemove: PropTypes.func,
};
