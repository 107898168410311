// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, useState } from "react";

import { Formik, Form } from "formik";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { TextField, RadioGroupField } from "components/form/fields";
import { Modal } from "components/layout";
import { Tooltip } from "components/popups";
import { trans } from "src/translations";
import { withRouter } from "utils/decorators";
import { isRequired } from "utils/validation";

import { INITIAL_FORM_STATE, CREATE_MODE } from "./constants";
import styles from "./CreateStructureModal.scss";
import StructureToCopyField from "./fields/StructureToCopyField";

const CREATE_RADIO_OPTIONS = [
  {
    label: trans.STRUCTURES_MODAL__CREATE_STRUCTURE_RADIO_NEW(),
    value: CREATE_MODE.CREATE_NEW,
    dataTestId: "create-structure-new",
  },
  {
    children: (
      <Fragment>
        <Tooltip
          content={trans.STRUCTURES_MODAL__CREATE_STRUCTURE_RADIO_COPY_TOOLTIP()}
          alignment="bottom"
        >
          {trans.STRUCTURES_MODAL__CREATE_STRUCTURE_RADIO_COPY()}
          <span data-test-id="create-structure-clone-tooltip">&nbsp;(?)</span>
        </Tooltip>
      </Fragment>
    ),
    value: CREATE_MODE.COPY_EXISTING,
    dataTestId: "create-structure-clone",
  },
];

const STRUCTURE_TO_COPY_FIELD_NAME = "structureToCopy";

const CreateStructureModal = ({ isOpen, onClose, forAdmin, navigate }) => {
  const [createMode, setCreateMode] = useState(() => CREATE_MODE.CREATE_NEW);

  const isUsingCopy = (createMode) => createMode === CREATE_MODE.COPY_EXISTING;

  const prepareDataForStructureCreator = (values) => {
    const { structureToCopy, name, createMode } = values;
    const result = { name };
    if (isUsingCopy(createMode)) {
      result.structureToCopyId = structureToCopy;
    }
    return result;
  };

  const handleSubmit = async (values) => {
    navigate(
      {
        pathname: forAdmin
          ? "/structures/admin/create/basic-details"
          : "/structures/create/basic-details",
      },
      {
        state: prepareDataForStructureCreator(values),
      }
    );
  };
  const handleValidation = (values) => {
    let errors = {};
    if (isUsingCopy(createMode)) {
      errors = isRequired(STRUCTURE_TO_COPY_FIELD_NAME)(values);
    }
    return errors;
  };
  const handleChangeMode = (e, setFieldValue) => {
    setCreateMode(e.target.value);
    setFieldValue("createMode", e.target.value);
  };

  const handleClose = (isSubmitting) => {
    if (!isSubmitting) {
      onClose();
    }
  };

  return (
    <Formik
      initialValues={INITIAL_FORM_STATE}
      onSubmit={handleSubmit}
      validate={handleValidation}
    >
      {({
        isSubmitting,
        handleSubmit,
        errors,
        dirty,
        setFieldValue,
        setFieldTouched,
      }) => (
        <Modal
          title={trans.STRUCTURES_MODAL__CREATE_STRUCTURE_TITLE()}
          size="medium"
          isOpen={isOpen}
          onClose={() => handleClose(isSubmitting)}
          actions={
            <ButtonsWrapper>
              <Button
                type="normal"
                onClick={onClose}
                disabled={isSubmitting}
                dataTestId="cancel-button"
              >
                {trans.CANCEL()}
              </Button>
              <Button
                type="green"
                onClick={handleSubmit}
                disabled={isSubmitting || !dirty}
                processing={isSubmitting}
                dataTestId="create-button"
              >
                {trans.CREATE()}
              </Button>
            </ButtonsWrapper>
          }
        >
          <RadioGroupField
            key="create-structure-type"
            name="createMode"
            onChange={(e) =>
              handleChangeMode(e, setFieldValue, setFieldTouched)
            }
            inline={true}
            values={CREATE_RADIO_OPTIONS}
            value={createMode}
            dataTestId="create-structure-type"
            displayAsTabs={true}
          />
          <Form aria-label="form">
            <TextField
              name="name"
              label={trans.STRUCTURES_MODAL__CREATE_STRUCTURE_NAME()}
              required={true}
              dataTestId="create-structure-name"
              look="simple"
              className={styles.nameField}
            />
            {isUsingCopy(createMode) ? (
              <StructureToCopyField
                name={STRUCTURE_TO_COPY_FIELD_NAME}
                forAdmin={forAdmin}
              />
            ) : null}
            {errors?._error && <Info type="error">{errors._error}</Info>}
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

CreateStructureModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  forAdmin: PropTypes.bool,
  // from @withRouter
  navigate: PropTypes.func.isRequired,
};

export default withRouter(CreateStructureModal);
