// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Field } from "formik";
import PropTypes from "prop-types";

import { TransCreatePropTypes, LabelType } from "../../../../Labels/propTypes";
import LabelToCopy from "./LabelToCopy";

export const LabelToCopyField = ({ texts, labelType, languages }) => (
  <Field name="labelToCopy">
    {(props) => (
      <LabelToCopy
        {...props}
        texts={texts}
        labelType={labelType}
        languages={languages}
      />
    )}
  </Field>
);

LabelToCopyField.propTypes = {
  texts: TransCreatePropTypes,
  labelType: LabelType,
  languages: PropTypes.array,
};
