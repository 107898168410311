// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useField } from "formik";
import PropTypes from "prop-types";

import { GrabsonIcon } from "components/icons";
import { TableRow, TableRowColumn } from "components/layout";
import { trans } from "src/translations";

import { PROVIDERS, STRUCTURE_TYPES, FIELDS } from "../constants";
import { ProviderTooltip } from "../Sections/ProviderTooltip";
import styles from "./PackagesLockedScreen.scss";

export const PackagesLockedScreen = ({ onEdit, sectionFieldName }) => {
  const [field] = useField(sectionFieldName);
  const selectedProvider = field.value[FIELDS.PROVIDER_FIELD_NAME];
  const providerName = selectedProvider && PROVIDERS[selectedProvider].name;

  return (
    <TableRow highlighting={false}>
      <TableRowColumn
        colspan={5}
        className={styles.column}
        columnClass={styles.withoutPadding}
      >
        <div className={styles.info}>
          <GrabsonIcon className={styles.icon} name="lock" size="lg" />
          <p className={styles.description}>
            {trans.STRUCTURES_CREATOR__LOCKED_DESCRIPTION()} - {providerName}
          </p>
          <ProviderTooltip
            selectedProvider={selectedProvider}
            className={styles.tooltip}
            alignment="bottom-start"
            action={() =>
              onEdit(STRUCTURE_TYPES.SECTION, field.value, sectionFieldName)
            }
          >
            (?)
          </ProviderTooltip>
        </div>
      </TableRowColumn>
    </TableRow>
  );
};

PackagesLockedScreen.propTypes = {
  onEdit: PropTypes.func.isRequired,
  sectionFieldName: PropTypes.string.isRequired,
};
