// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import get from "lodash-es/get";

import { TARGETS_FIELD_NAME } from "pages/_shared/TargetsField/constants";
import { trans } from "src/translations";
import { parseGqlError } from "utils/errors";
import { GRPC_STATUS } from "utils/graphql/constants";
import {
  isRequired,
  hasOneElement,
  hasOneElementWhen,
  pipeValidators,
} from "utils/validation";

import { NAME_FIELD_NAME } from "../TabBasicDetails/TabBasicDetails";
import { PROVIDER_ID } from "../TabStructureCreator/constants";

const arePackageItemsValid = (pack) => !pack?.packageItems;

export const isPackageValid = (packagesFieldName, syncErrors) => {
  const pack = get(syncErrors, packagesFieldName);

  return arePackageItemsValid(pack);
};

export const isSectionValid = (sectionFieldName, syncErrors) => {
  const packagesErrors = get(syncErrors, `${sectionFieldName}.packages`);
  let hasAnyPackageError = false;
  if (Array.isArray(packagesErrors)) {
    hasAnyPackageError = packagesErrors.some(
      (pack) => !arePackageItemsValid(pack)
    );
  }

  return !packagesErrors && !hasAnyPackageError;
};

export const areSectionsValid = (syncErrors) => !syncErrors.sections;

export const parseSubmitError = (error) => {
  // Back-end error messages are not useful. Use generic ones.
  const gqlError = parseGqlError(error, { preferGenericMessages: true });
  if (gqlError?.grpcStatus === GRPC_STATUS.INVALID_ARGUMENT_3) {
    // Override the USER_INPUT_ERROR message.
    gqlError.message = trans.STRUCTURES__DEFAULT_ERROR();
  }
  return gqlError?.message ?? trans.DEFAULT_REQUEST_ERROR_MESSAGE();
};

const validateField = (allValues, fieldName, validators) => {
  const errors = pipeValidators(...validators)(allValues, allValues);

  if (errors) {
    return errors[fieldName];
  }

  return undefined;
};

const validatePackage = (values, idx) => {
  const packageErrors = values.sections[idx].packages.reduce(
    (acc, element, pkgIdx) => {
      const error = validateField(
        values,
        `sections[${idx}].packages[${pkgIdx}].packageItems`,
        [
          hasOneElementWhen(
            `sections[${idx}].packages[${pkgIdx}].packageItems`,
            () => !element.autofillPackageItems
          ),
        ]
      );
      if (error) {
        acc[pkgIdx] = { packageItems: error };
      }

      return acc;
    },
    []
  );
  return packageErrors.length ? packageErrors : undefined;
};

const validateStructureCreator = (values) => {
  const errors = {};
  const missingSections = validateField(values, "sections", [
    hasOneElement("sections"),
  ]);

  const sectionsErrors = values.sections.reduce((acc, element, idx) => {
    const selectedProvider = element.selectedProvider;
    const missingPackages = validateField(values, `sections[${idx}].packages`, [
      hasOneElementWhen(
        `sections[${idx}].packages`,
        () => selectedProvider === PROVIDER_ID.CLOUD
      ),
    ]);

    const packageErrors = validatePackage(values, idx);

    if (missingPackages || packageErrors) {
      acc[idx] = { packages: missingPackages || packageErrors };
    }

    return acc;
  }, []);

  if (missingSections || sectionsErrors.length) {
    errors.sections = missingSections || sectionsErrors;
  }

  return errors;
};

const validateBasicDetails = (values) => {
  const errors = {};

  const nameFieldError = validateField(values, NAME_FIELD_NAME, [
    isRequired(NAME_FIELD_NAME),
  ]);

  const targetsFieldError = validateField(values, TARGETS_FIELD_NAME, [
    hasOneElement(TARGETS_FIELD_NAME),
  ]);

  if (nameFieldError) {
    errors[NAME_FIELD_NAME] = nameFieldError;
  }

  if (targetsFieldError) {
    errors[TARGETS_FIELD_NAME] = targetsFieldError;
  }

  return errors;
};

export const validateStructureForm = (values) => ({
  ...validateBasicDetails(values),
  ...validateStructureCreator(values),
});
