// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { GrabsonIcon } from "components/icons";
import { Header } from "components/typography";

import styles from "./WarningMessage.scss";

export const WarningMessage = ({ title, text }) => (
  <div className={styles.message}>
    <Header bold={true}>
      <GrabsonIcon className={styles.icon} name="alert-circle" size="xl" />{" "}
      {title}
    </Header>
    <p className={styles.content}>{text}</p>
  </div>
);

WarningMessage.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};
