// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { LABEL_TYPES } from "./constants";

export const LabelPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  useCount: PropTypes.number,
});

export const TransCreatePropTypes = PropTypes.shape({
  modalTitle: PropTypes.func.isRequired,
  translationsHeader: PropTypes.func.isRequired,
  copySearchPlaceholder: PropTypes.func.isRequired,
  copyTooltip: PropTypes.func.isRequired,
});

export const TransEditPropTypes = PropTypes.shape({
  translationsHeader: PropTypes.func.isRequired,
});

export const TransListPropTypes = PropTypes.shape({
  contentHeader: PropTypes.func,
  infoHeader: PropTypes.func,
  searchPlaceholder: PropTypes.func,
});

export const TransRemovePropTypes = PropTypes.shape({
  removeConfirmMessage: PropTypes.func,
  removeFailMessage: PropTypes.func,
});

export const LabelType = PropTypes.oneOf(Object.values(LABEL_TYPES));
