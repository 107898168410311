// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PageContent, PageHeader } from "components/layout";
import { trans } from "src/translations";

import { SectionLabelsData } from "./Labels/SectionLabelsData";

export default function SectionLabels() {
  return (
    <div>
      <PageHeader>{trans.STRUCTURES_SECTION__HEADER()}</PageHeader>
      <PageContent>
        <SectionLabelsData />
      </PageContent>
    </div>
  );
}
