// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { retrieveFiltersFromInclude } from "utils/url";

import { PROVIDER_ID } from "../TabStructureCreator/constants";

const prepareFilters = (filtersData) => {
  const filters = retrieveFiltersFromInclude(filtersData);
  const result = [];
  Object.entries(filters).forEach(([key, value]) => {
    const filterKey = key.toUpperCase();
    if (Array.isArray(value)) {
      value.forEach((item) => {
        result.push({ key: filterKey, value: item });
      });
    } else {
      result.push({ key: filterKey, value: value });
    }
  });
  return result;
};

const preparePackage = (packageData) => {
  return {
    id: packageData.id,
    packageLabelId: packageData.packageLabelId,
    packageItemIds: packageData.packageItems.map(({ id }) => id),
    autofillPackageItems: packageData.autofillPackageItems && {
      filters: prepareFilters(packageData.autofillPackageItems.filters),
      displayCount: parseInt(packageData.autofillPackageItems.displayCount, 10),
      orderBy: packageData.autofillPackageItems.orderBy,
    },
  };
};

const prepareSection = (sectionData) => {
  const result = {
    id: sectionData.id,
    sectionLabelId: sectionData.sectionLabelId,
  };

  if (sectionData.selectedProvider === PROVIDER_ID.CLOUD) {
    result.packages = sectionData.packages.map(preparePackage);
  } else {
    result.providerId = sectionData.selectedProvider;
  }

  return result;
};

const prepareTargetDevices = (
  deviceModels,
  deviceFamilies,
  distributors,
  collections
) => {
  const result = {};

  if (deviceModels.length > 0) {
    result.deviceModelIds = deviceModels.map(({ id }) => id);
  } else if (deviceFamilies.length > 0) {
    result.deviceFamiliesIds = deviceFamilies.map(({ id }) => id);
  } else if (distributors.length > 0) {
    result.organizationIds = distributors.map(({ id }) => id);
  } else if (collections) {
    result.collectionIds = [collections.id];
  }

  return result;
};

const prepareTargetCountries = (countries, allCountriesCount) => {
  const result = {};

  if (countries.length === allCountriesCount) {
    result.worldwide = true;
  } else {
    result.worldwide = false;
    result.countryCodes = countries;
  }

  return result;
};

const prepareTarget = (targetData, allCountriesCount) => {
  const { deviceModels, deviceFamilies, distributors, countries, collections } =
    targetData;

  const preparedDevices = prepareTargetDevices(
    deviceModels,
    deviceFamilies,
    distributors,
    collections
  );
  const preparedCountries = prepareTargetCountries(
    countries,
    allCountriesCount
  );

  return {
    ...preparedDevices,
    ...preparedCountries,
  };
};

export const createStructureInput = (isEdit, structure, allCountriesCount) => {
  return {
    ...(isEdit ? { structureId: structure.id } : {}),
    name: structure.name,
    sections: structure.sections.map(prepareSection),
    targets: structure.targets.map((t) => prepareTarget(t, allCountriesCount)),
    score: {
      value: parseInt(structure.score, 10),
      isAutogenerated: structure.isScoreAutogenerated,
    },
  };
};
