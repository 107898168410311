// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { gql } from "@apollo/client";

export default gql`
  query structure($structureId: ID!) {
    structure(structureId: $structureId) {
      structure {
        id
        name
        lastModifiedDate
        score {
          value
          isAutogenerated
        }
        sections {
          items {
            id
            sectionLabel {
              id
              translations(onlyDefault: false) {
                translation
                isDefault
                language {
                  code
                }
              }
            }
            selectedProvider {
              id
            }
            packages {
              items {
                id
                packageLabel {
                  id
                  translations(onlyDefault: false) {
                    translation
                    isDefault
                    language {
                      code
                    }
                  }
                }
                autofillPackageItems {
                  displayCount
                  orderBy
                  filters {
                    key
                    value
                  }
                }
                packageItems(pagination: { noLimit: true }) {
                  items {
                    id
                  }
                }
              }
            }
          }
        }
        targets {
          deviceModels {
            id
          }
          deviceFamilies {
            id
          }
          organizations {
            id
          }
          countries {
            code
          }
          collectionIds {
            id
          }
          worldwide
        }
      }
    }
  }
`;
