// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { gql } from "@apollo/client";

export const sectionLabelsGql = gql`
  query sectionLabels($pagination: PaginationInput!, $search: SearchInput) {
    sectionLabels(pagination: $pagination, search: $search) {
      sectionLabels {
        items {
          id
          usedInStructuresCount
          translations(onlyDefault: false) {
            isDefault
            translation
          }
        }
        pagination {
          count
        }
      }
    }
  }
`;
