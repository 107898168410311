// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { TableRowColumn, TableSubRow } from "components/layout";
import { Link } from "components/typography";
import { trans } from "src/translations";

import { PackagePropTypes } from "./propTypes";
import styles from "./Structures.scss";

export default function StructurePackageRow(props) {
  const { pack, openPackageModal } = props;

  const status = [];
  if (pack.itemsCount > 0) {
    status.push(trans.STRUCTURES_LIST__APPS_AMOUNT({ count: pack.itemsCount }));
  }
  if (pack.autofill) {
    status.push(trans.STRUCTURES_LIST__AUTOFILL());
  }

  return (
    <TableSubRow className={styles.package} dataTestId="structure-package-row">
      <TableRowColumn className={styles.name} colspan={2}>
        <p>{pack.name}</p>
      </TableRowColumn>
      <TableRowColumn className={styles.count} colspan={2}>
        <Link
          className={styles.link}
          onClick={() => openPackageModal(pack)}
          data-test-id="structure-package-content-button"
        >
          {status.join(" + ")}
        </Link>
      </TableRowColumn>
    </TableSubRow>
  );
}

StructurePackageRow.propTypes = {
  pack: PackagePropTypes,
  openPackageModal: PropTypes.func,
};
