// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import cloneDeep from "lodash-es/cloneDeep";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Table, TableHeaderColumn, TableHeader } from "components/layout";
import {
  HOME_ID,
  SNAP_EDIT_MINIMUM_SECTION_NUMBER,
  DEFAULT_SECTION,
  DEFAULT_SECTION_OPTIONS,
  HOME_SECTION_OPTIONS,
  LIVE_FEED,
  NEW_LIVE_SECTION,
  BUTTONS,
  FIELDS,
  SECTIONS,
} from "pages/Snap/shared/constants";
import { InfoBox } from "pages/Snap/shared/InfoBox/InfoBox";
import styles from "pages/Snap/shared/Translations.scss";
import { SectionFeed } from "pages/Snap/SnapEdit/components/Translations/SectionFeed";
import { SectionHome } from "pages/Snap/SnapEdit/components/Translations/SectionHome";
import { SectionLive } from "pages/Snap/SnapEdit/components/Translations/SectionLive";
import TranslationsModal from "pages/Snap/SnapEdit/components/Translations/TranslationsModal";

export class SectionsTable extends Component {
  static propTypes = {
    getLiveIndex: PropTypes.func.isRequired,
    /** name used for formik managed field */
    name: PropTypes.string,
    /** formik context prop */
    form: PropTypes.object,
  };

  state = {
    sectionIndex: 0,
    liveStreamIndex: 0,
    isModalOpen: false,
    formType: "section",
    focusTitle: false,
    initialValues: undefined,
    mode: undefined,
  };

  shouldComponentUpdate(nextProp, nextState) {
    const { isModalOpen } = this.state;
    return !(isModalOpen && isModalOpen === nextState.isModalOpen);
  }

  addHomeSection = (value) => {
    const homeSection = cloneDeep(value[HOME_ID]);
    homeSection.show = true;
    Object.keys(HOME_SECTION_OPTIONS).forEach((key) => {
      const prop = FIELDS[key].name;
      if (!homeSection.hasOwnProperty(prop)) {
        homeSection[prop] = HOME_SECTION_OPTIONS[key];
      }
    });
    this.setState({
      isModalOpen: true,
      sectionIndex: HOME_ID,
      initialValues: homeSection,
    });
  };

  addNewSection = (value) => {
    const lastField = value.length;
    const defaultSection = cloneDeep(DEFAULT_SECTION);
    Object.keys(DEFAULT_SECTION_OPTIONS).forEach(
      (key) => (defaultSection[FIELDS[key].name] = DEFAULT_SECTION_OPTIONS[key])
    );
    defaultSection.order = lastField;
    this.setState({
      isModalOpen: true,
      sectionIndex: lastField,
      initialValues: defaultSection,
    });
  };

  addNewLiveStream = (value) => {
    const { getLiveIndex } = this.props;
    const liveIndex = getLiveIndex();
    const lastField = value.length;

    if (!liveIndex) {
      this.setState({
        isModalOpen: true,
        formType: "liveStream",
        sectionIndex: lastField,
        liveStreamIndex: 0,
        initialValues: LIVE_FEED,
        mode: "initLive",
      });
    } else {
      const liveSection = value[liveIndex];
      this.setState({
        isModalOpen: true,
        formType: "liveStream",
        sectionIndex: liveIndex,
        liveStreamIndex: liveSection.live_feeds.length,
        initialValues: LIVE_FEED,
      });
    }
  };

  openModal = (sectionIndex, field, formType = "section") => {
    const {
      form: { getFieldMeta },
    } = this.props;

    const { value: initialValues } = getFieldMeta(field);
    const addThumbnailResolution = (liveFeed) => ({
      ...liveFeed,
      thumbnail_resolution:
        liveFeed.thumbnail_width && liveFeed.thumbnail_height
          ? `${liveFeed.thumbnail_width}x${liveFeed.thumbnail_height}`
          : "",
    });

    return (focusTitle, liveStreamIndex, mode) => {
      this.setState({
        isModalOpen: true,
        sectionIndex: sectionIndex,
        liveStreamIndex,
        focusTitle,
        initialValues:
          formType !== "liveStream"
            ? initialValues
            : addThumbnailResolution(initialValues.live_feeds[liveStreamIndex]),
        formType,
        mode,
      });
    };
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
      focusTitle: false,
      formType: "section",
      mode: undefined,
    });
  };

  handleSubmit = (formValues) => {
    const {
      name,
      form: { setFieldValue, getFieldMeta },
    } = this.props;
    const { sectionIndex, formType, liveStreamIndex, mode } = this.state;
    const sectionField = `${name}[${sectionIndex}]`;
    let values = { ...formValues };
    if (formValues.thumbnail_resolution) {
      const [thumbnail_width, thumbnail_height] =
        formValues.thumbnail_resolution.split("x");
      values = {
        ...formValues,
        thumbnail_width,
        thumbnail_height,
      };
      delete values.thumbnail_resolution;
    }
    if (formType !== "liveStream") {
      // add home or section
      setFieldValue(sectionField, values);
    } else if (mode === "initLive") {
      // add first livestream
      const sections = cloneDeep(getFieldMeta(name).value);
      const liveSection = cloneDeep(NEW_LIVE_SECTION);
      liveSection.live_feeds[liveStreamIndex] = values;
      liveSection.order = sectionIndex;
      sections.push(liveSection);
      setFieldValue(name, sections);
    } else if (mode === "editLive") {
      // edit livestream
      const field = `${sectionField}.live_feeds[${liveStreamIndex}]`;
      setFieldValue(field, values);
    } else {
      // add another livestream
      const field = `${sectionField}.live_feeds`;
      const { value } = getFieldMeta(field);
      setFieldValue(field, value.concat(values));
    }
    this.closeModal();
  };

  renderHomeSection = (item, index, fields) => {
    const { name } = this.props;
    const fieldName = `${name}[${index}]`;

    return (
      <SectionHome
        fields={fields}
        key={fieldName}
        item={item}
        index={index}
        fieldName={fieldName}
        openModal={this.openModal(index, fieldName)}
      />
    );
  };

  renderLiveSection = (item, index, fields) => {
    const { name, getLiveIndex, ...rest } = this.props;
    const sectionFields = fields[index];
    const fieldName = `${name}[${index}]`;

    return (
      <SectionLive
        key={`${name}[${index}]`}
        index={index}
        fields={fields}
        fieldName={fieldName}
        name={name}
        openLiveStreamModal={this.openModal(index, fieldName, "liveStream")}
        openLiveSectionModal={this.openModal(index, fieldName, "liveSection")}
        item={item}
        {...sectionFields}
        {...rest}
      />
    );
  };

  renderSection = (item, index, fields) => {
    const { name, ...rest } = this.props;
    const fieldName = `${name}[${index}]`;

    return (
      <SectionFeed
        fields={fields}
        index={index}
        name={name}
        key={`${name}[${index}]`}
        item={item}
        openModal={this.openModal(index, fieldName)}
        dataTestId="feed-section-draggable-table-row"
        {...rest}
      />
    );
  };

  renderTableRow = (sectionItem, index, sections) => {
    if (index === HOME_ID) {
      if (!sectionItem.show) {
        return null;
      }
      return this.renderHomeSection(sectionItem, index, sections);
    }
    if (sectionItem.live) {
      return this.renderLiveSection(sectionItem, index, sections);
    }
    return this.renderSection(sectionItem, index, sections);
  };

  renderTableRowMessage = (message) => {
    return (
      <tr>
        <td colSpan="7" className={styles.rowEmpty}>
          {message}
        </td>
      </tr>
    );
  };

  render() {
    const {
      getLiveIndex,
      name,
      form: { getFieldMeta },
    } = this.props;
    const { focusTitle, isModalOpen, sectionIndex, initialValues, formType } =
      this.state;

    const { value } = getFieldMeta(name);

    const fieldsNumber = value.length;
    const homeSection = value[HOME_ID];
    const hasLiveSection = Boolean(getLiveIndex());

    const hasSection = Boolean(fieldsNumber - 1 - (hasLiveSection ? 1 : 0));
    const canAddHomeSection = !homeSection.show;

    return (
      <div>
        <Table layout="fixed" className={styles.editTable}>
          <TableHeader>
            <TableHeaderColumn className={styles.extendedIconColumn} />
            <TableHeaderColumn className={styles.iconColumn}>
              {SECTIONS.TRANSLATIONS.colIcon}
            </TableHeaderColumn>
            <TableHeaderColumn className={styles.titleColumn}>
              {SECTIONS.TRANSLATIONS.colTitle}
            </TableHeaderColumn>
            <TableHeaderColumn className={styles.contentColumn}>
              {SECTIONS.TRANSLATIONS.colURL}
            </TableHeaderColumn>
            <TableHeaderColumn className={styles.typeColumn}>
              {SECTIONS.TRANSLATIONS.colType}
            </TableHeaderColumn>
            <TableHeaderColumn className={styles.orderColumn}>
              {SECTIONS.TRANSLATIONS.colOrder}
            </TableHeaderColumn>
            <TableHeaderColumn className={styles.optionsColumn} />
          </TableHeader>

          <tbody onDragOver={(e) => e.preventDefault()}>
            {value.map(this.renderTableRow)}
            {!hasSection &&
              this.renderTableRowMessage(SECTIONS.TRANSLATIONS.noSection)}
            {!hasLiveSection &&
              this.renderTableRowMessage(SECTIONS.TRANSLATIONS.noLiveSection)}
          </tbody>
        </Table>
        <ButtonsWrapper position="center">
          <Button
            type="green"
            disabled={!canAddHomeSection}
            onClick={() => this.addHomeSection(value)}
            dataTestId="add-home-section-button"
          >
            {BUTTONS.SECTION.addHome}
          </Button>
          <Button
            type="green"
            onClick={() => this.addNewSection(value)}
            dataTestId="add-section-button"
          >
            {BUTTONS.SECTION.addSection}
          </Button>
          <Button
            type="green"
            onClick={() => this.addNewLiveStream(value)}
            dataTestId="add-live-stream-button"
          >
            {BUTTONS.SECTION.addLiveStream}
          </Button>
        </ButtonsWrapper>
        {isModalOpen && (
          // form in Modal is modified on-the-fly, so there is no need to update state on "OK" - just hide it
          <TranslationsModal
            focusTitle={focusTitle}
            initialValues={initialValues}
            onSubmit={this.handleSubmit}
            isOpen={isModalOpen}
            formType={formType}
            sectionIndex={sectionIndex}
            fields={value}
            onClose={this.closeModal}
            onCancel={this.closeModal}
          />
        )}

        {fieldsNumber === SNAP_EDIT_MINIMUM_SECTION_NUMBER &&
          (!homeSection.show || !homeSection.url) && (
            <InfoBox message={SECTIONS.TRANSLATIONS.notEnoughSections} />
          )}
      </div>
    );
  }
}
