// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { PageContent, PageHeader } from "components/layout";
import { trans } from "src/translations";

import StructuresData from "./Structures/StructuresData";

export default function StructuresList(props) {
  const { forAdmin } = props;

  return (
    <div>
      <PageHeader>{trans.STRUCTURES_LIST__HEADER()}</PageHeader>
      <PageContent>
        <StructuresData forAdmin={forAdmin} />
      </PageContent>
    </div>
  );
}

StructuresList.propTypes = {
  forAdmin: PropTypes.bool,
};

StructuresList.defaultProps = {
  forAdmin: false,
};
