// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useContext, useState } from "react";

import { FieldArray, useField } from "formik";
import PropTypes from "prop-types";

import { Checkbox } from "components/form/elements";
import { trans } from "src/translations";

import { PackageItemsContext } from "../../../StructureForm";
import { PackageInfiniteTable } from "../PackageInfiniteTable";
import styles from "../PackageItemsModals.scss";

export const AppsListFields = ({
  name,
  autogenerated,
  loadingAll,
  onLoadingAllChange,
  ...props
}) => {
  const preselectedItems = useContext(PackageItemsContext);
  const [field, , helpers] = useField(name);
  const [fetchAll, setFetchAll] = useState(false);

  const isChecked = (item) => field.value.some((id) => id === item.id);
  const whenNotLoadingAll = (callback) => (!loadingAll ? callback : () => {});

  const handleChange = (arrayHelpers) => (item, newValue) => {
    const index = field.value.findIndex((id) => id === item.id);
    const checked = index > -1;
    const shouldBeChecked =
      (typeof newValue !== "undefined" && newValue) || !checked;
    if (checked === shouldBeChecked) {
      return;
    }
    if (shouldBeChecked) {
      arrayHelpers.push(item.id);
    } else {
      arrayHelpers.remove(index);
      setFetchAll(false);
    }
  };

  const handleChangeAll = (arrayHelpers) => (items, newValue) => {
    if (newValue) {
      items.forEach((item) => handleChange(arrayHelpers)(item, true));
      if (!fetchAll) {
        setFetchAll(true);
        onLoadingAllChange(true);
      }
    } else {
      helpers.setValue([]);
      setFetchAll(false);
    }
  };

  const onNewDataLoaded = (arrayHelpers) => (data, allDataLoaded) => {
    if (fetchAll) {
      if (loadingAll) {
        data.forEach((item) => handleChange(arrayHelpers)(item, true));
      }
      /*
        If loadingAll is false, it means that there was a new search. In that
        case, it is best to do nothing. InfiniteData has already started to
        fetch all pages, so the value of fetchAll is correct.
      */
    }
    if (allDataLoaded) {
      setFetchAll(true);
      onLoadingAllChange(false);
    }
  };

  const renderSelectAll = (arrayHelpers) =>
    // Component must have a name, so an arrow function can not be used here.
    function SelectAll(items, itemCount) {
      if (autogenerated) {
        return null;
      }
      const checked =
        preselectedItems.length + field.value.length >= itemCount &&
        items.every((item) => isChecked(item));
      return (
        <Checkbox
          className={styles.selectAll}
          checked={checked}
          disabled={loadingAll || items.length <= 0}
          value="items"
          onChange={whenNotLoadingAll(() =>
            handleChangeAll(arrayHelpers)(items, !checked)
          )}
        />
      );
    };

  const renderStatus = ({ meta }) => {
    if (autogenerated) {
      return null;
    }
    return (
      <p className={styles.selectedStatus}>
        {trans.STRUCTURES_MODAL__PACKAGE_EDIT_STATUS({
          count: meta.count,
          selected: preselectedItems.length + field.value.length,
        })}
      </p>
    );
  };

  return (
    <FieldArray name={name}>
      {(arrayHelpers) => (
        <PackageInfiniteTable
          {...props}
          className={styles.editAppsList}
          fetchAllData={fetchAll}
          layout="fixed"
          urlTarget="blank"
          isChecked={isChecked}
          onChange={whenNotLoadingAll(handleChange(arrayHelpers))}
          onNewDataLoaded={onNewDataLoaded(arrayHelpers)}
          selectAllComponent={renderSelectAll(arrayHelpers)}
          statusComponent={renderStatus}
        />
      )}
    </FieldArray>
  );
};

AppsListFields.propTypes = {
  name: PropTypes.string.isRequired,
  autogenerated: PropTypes.bool.isRequired,
  loadingAll: PropTypes.bool.isRequired,
  onLoadingAllChange: PropTypes.func.isRequired,
};
