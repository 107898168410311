// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { flatten } from "src/utils/serialize";

export const ROLES = {
  provider: {
    creator: "Provider:creator",
    signatory: "Provider:signatory",
  },
  distributor: {
    readOnlyDistributionPlanner: "Distributor:read_only_distribution_planner",
    distributionPlanner: "Distributor:distribution_planner",
    restrictedDistributionPlanner:
      "Distributor:restricted_distribution_planner",
    deviceManager: "Distributor:device_manager",
  },
  structure: {
    structureManager: "Structures:structure_manager",
  },
  deviceInventory: {
    deviceManager: "DeviceInventory:device_manager",
    configControlManager: "DeviceInventory:config_control_manager",
  },
  softwareUpdates: {
    updateManager: "SoftwareUpdates:update_manager",
  },
  organization: {
    owner: "Organization:owner",
    member: "Organization:member",
    roleManager: "Organization:role_manager",
  },
  administrator: {
    browserConfigAdmin: "Administrator:browser_config_admin",
    deviceAdmin: "Administrator:device_admin",
    distributionAdmin: "Administrator:distribution_admin",
    organizationAdmin: "Administrator:organization_admin",
    promotionAdmin: "Administrator:promotion_admin",
    structuresAdmin: "Administrator:structures_admin",
    userAdmin: "Administrator:user_admin",
    vewdIDsManager: "Administrator:vewd_ids_manager",
  },
  moderator: {
    appIntegrator: "Moderator:app_integrator",
    contentManager: "Moderator:content_manager",
    moderator: "Moderator:moderator",
  },
  promotionManagement: {
    promotionManager: "PromotionManagement:promotion_manager",
  },
};

const impliedRoles = {
  [ROLES.organization.owner]: {
    implies: Object.values(flatten(ROLES)).filter(
      (role) => role !== ROLES.organization.owner
    ),
  },
  [ROLES.deviceInventory.configControlManager]: {
    implies: [ROLES.deviceInventory.deviceManager],
  },
};

const isRoleImpliedByUserRole = (role, editUserRoles) =>
  Object.entries(impliedRoles)
    .filter(([roleName]) => editUserRoles.includes(roleName))
    .some(([, roleDetails]) => roleDetails.implies.includes(role));

export const isAllowedToRemoveRole = (
  currentUserRoles,
  editUserRoles,
  role
) => {
  const editUserIsOwner = editUserRoles.includes(ROLES.organization.owner);
  const currentUserIsOwnerOrAdmin = currentUserRoles.some((role) =>
    [ROLES.organization.owner, ROLES.administrator.userAdmin].includes(role)
  );
  const currentUserCanEditUsers = currentUserRoles.some((role) =>
    [
      ROLES.organization.owner,
      ROLES.organization.roleManager,
      ROLES.administrator.userAdmin,
    ].includes(role)
  );
  if (!currentUserCanEditUsers) {
    return false;
  }

  if (editUserIsOwner && !currentUserIsOwnerOrAdmin) {
    return false;
  }

  return !isRoleImpliedByUserRole(role, editUserRoles);
};

export const isAllowedToAddRole = (currentUserRoles, editUserRoles, role) => {
  const currentUserIsOwnerOrAdmin = currentUserRoles.some((role) =>
    [ROLES.organization.owner, ROLES.administrator.userAdmin].includes(role)
  );
  const currentUserCanEditUsers = currentUserRoles.some((role) =>
    [
      ROLES.organization.owner,
      ROLES.organization.roleManager,
      ROLES.administrator.userAdmin,
    ].includes(role)
  );
  if (!currentUserCanEditUsers) {
    return false;
  }

  // only org owner or admin can assign org owner
  // @todo most probably this should be extended to admin role
  //  (but this is how it works currently in the backend)
  //  [ROLES.organization.owner, ROLES.administrator.userAdmin].includes(role)
  return !(ROLES.organization.owner === role && !currentUserIsOwnerOrAdmin);
};
