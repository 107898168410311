// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Formik } from "formik";
import PropTypes from "prop-types";

import { Modal } from "components/layout";
import { trans } from "src/translations";

import { FIELD_TYPE_AUTO, FIELD_TYPE_SELECT } from "../../constants";
import { INITIAL_FORM_STATE } from "./constants";
import { EditPackageItemsData } from "./EditPackageItemsData";

export const EditPackageItemsModal = ({
  forAdmin,
  initialValues,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const handleClose = ({ isSubmitting, resetForm }) => {
    if (!isSubmitting) {
      onClose();
      resetForm();
    }
  };

  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    onSubmit(values);
    setSubmitting(false);
    resetForm();
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues || INITIAL_FORM_STATE}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Modal
          isOpen={isOpen}
          size="big"
          title={trans.STRUCTURES_MODAL__PACKAGE_ADD_ITEMS_LABEL()}
          onClose={() => handleClose(formikProps)}
          dataTestId="edit-package-items-modal"
        >
          <EditPackageItemsData
            forAdmin={forAdmin}
            onClose={() => handleClose(formikProps)}
          />
        </Modal>
      )}
    </Formik>
  );
};

EditPackageItemsModal.propTypes = {
  forAdmin: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    appListType: PropTypes.oneOf([FIELD_TYPE_AUTO, FIELD_TYPE_SELECT]),
    selectedApps: PropTypes.array,
    filters: PropTypes.object,
    volatileFilters: PropTypes.object,
  }),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
