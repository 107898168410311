// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

export const urlToBlob = async (url: RequestInfo): Promise<Blob> => {
  const res = await fetch(url, {
    method: "GET",
    cache: "no-cache",
  });

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return await res.blob();
};

export const blobToBase64 = (blob: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onloadend = () => resolve(fileReader.result as string); // base64
    fileReader.onerror = (err) => reject(err);

    fileReader.readAsDataURL(blob);
  });

export const urlToBase64 = async (url: RequestInfo): Promise<string | null> => {
  if (!url) {
    return null;
  }
  const blob = await urlToBlob(url);
  return blobToBase64(blob);
};

export const base64ToText = (value = "") => decodeURIComponent(atob(value));

export const textToBase64 = (value = "") => btoa(encodeURIComponent(value));
