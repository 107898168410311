// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useFormikContext } from "formik";
import PropTypes from "prop-types";

import { reorderFields } from "utils/formik/utils";

import { FIELDS } from "../constants";
import { SectionRow } from "./SectionRow";

export const Sections = ({ onMove, onEdit, onRemove, onCreate }) => {
  const { values } = useFormikContext();

  return values.sections.map((section, index) => {
    const sectionFieldName = `${FIELDS.SECTION_FIELD_NAME}[${index}]`;
    return (
      <SectionRow
        sectionFieldName={sectionFieldName}
        key={section.id || section.key}
        index={index}
        onMove={(index, direction) =>
          reorderFields(section, index, direction, onMove)
        }
        onCreate={(type) => {
          onCreate(type, sectionFieldName);
        }}
        onRemove={onRemove}
        onEdit={onEdit}
        dataTestId={`section-row-${index}`}
      />
    );
  });
};

Sections.propTypes = {
  onMove: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
};
