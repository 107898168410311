// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";

import styles from "./PackageItems/PackageItems.scss";

export const FIELDS = {
  SECTION_FIELD_NAME: "sections",
  SECTION_NAME_FIELD_NAME: "name",
  TRANSLATIONS_FIELD_NAME: "translations",
  PROVIDER_FIELD_NAME: "selectedProvider",
  SECTION_LABEL_FIELD_NAME: "sectionLabelId",
  PACKAGE_LABEL_FIELD_NAME: "packageLabelId",
  PACKAGE_FIELD_NAME: "packages",
  PACKAGE_ITEM_FIELD_NAME: "packageItems",
  ORDERBY_FIELD_NAME: "packageItemsOrderBy",
  AUTOFILL_FIELD_NAME: "autofillPackageItems",
  AUTOFILL_ORDERBY_FIELD_NAME: "autofillPackageItems.orderBy",
  AUTOFILL_COUNT_FIELD_NAME: "autofillPackageItems.displayCount",
};

export const DIRECTIONS = {
  UP: -1,
  DOWN: 1,
};

export const STRUCTURE_TYPES = {
  SECTION: "section",
  PACKAGE: "package",
};

export const STRUCTURE_NAMES = {
  [STRUCTURE_TYPES.SECTION]: trans.STRUCTURES_CREATOR__SECTION(),
  [STRUCTURE_TYPES.PACKAGE]: trans.STRUCTURES_CREATOR__PACKAGE(),
};

export const NO_ITEMS_MESSAGES = {
  [STRUCTURE_TYPES.SECTION]: trans.STRUCTURES_CREATOR__NO_PACKAGE_ADDED(),
  [STRUCTURE_TYPES.PACKAGE]: trans.STRUCTURES_CREATOR__NO_PACKAGE_ITEM_ADDED(),
};

export const PROVIDER_ID = {
  CLOUD: "vewd_cloud",
  FVP: "freeview_play",
  YT: "youtube",
  OP_VOD: "operator_vod",
  NATIVE_APPS: "installed_native_apps",
  LIVE: "live_tv",
  EPG: "epg",
  SETTINGS: "device_settings",
  SEARCH: "search",
  FAVOURITES: "favorites",
  WEATHER: "weather",
  AVINPUTS: "avinputs",
};

export const PROVIDERS = {
  [PROVIDER_ID.CLOUD]: {
    id: PROVIDER_ID.CLOUD,
    name: trans.STRUCTURES_PROVIDER_NAME__CLOUD(),
    info: trans.STRUCTURES_PROVIDER_INFO__CLOUD(),
    extendedInfo: trans.STRUCTURES_PROVIDER_EXTENDED_INFO__CLOUD(),
  },
  [PROVIDER_ID.FVP]: {
    id: PROVIDER_ID.FVP,
    name: trans.STRUCTURES_PROVIDER_NAME__FVP(),
    info: trans.STRUCTURES_PROVIDER_INFO__FVP(),
    extendedInfo: trans.STRUCTURES_PROVIDER_EXTENDED_INFO__FVP(),
  },
  [PROVIDER_ID.YT]: {
    id: PROVIDER_ID.YT,
    name: trans.STRUCTURES_PROVIDER_NAME__YOUTUBE(),
    info: trans.STRUCTURES_PROVIDER_INFO__YOUTUBE(),
    extendedInfo: trans.STRUCTURES_PROVIDER_EXTENDED_INFO__YOUTUBE(),
  },
  [PROVIDER_ID.OP_VOD]: {
    id: PROVIDER_ID.OP_VOD,
    name: trans.STRUCTURES_PROVIDER_NAME__OPERATOR_VOD(),
    info: trans.STRUCTURES_PROVIDER_INFO__OPERATOR_VOD(),
    extendedInfo: trans.STRUCTURES_PROVIDER_EXTENDED_INFO__OPERATOR_VOD(),
  },
  [PROVIDER_ID.NATIVE_APPS]: {
    id: PROVIDER_ID.NATIVE_APPS,
    name: trans.STRUCTURES_PROVIDER_NAME__NATIVE_APPS(),
    info: trans.STRUCTURES_PROVIDER_INFO__NATIVE_APPS(),
    extendedInfo: trans.STRUCTURES_PROVIDER_EXTENDED_INFO__NATIVE_APPS(),
  },
  [PROVIDER_ID.LIVE]: {
    id: PROVIDER_ID.LIVE,
    name: trans.STRUCTURES_PROVIDER_NAME__LIVE_TV(),
    info: trans.STRUCTURES_PROVIDER_INFO__LIVE_TV(),
    extendedInfo: trans.STRUCTURES_PROVIDER_EXTENDED_INFO__LIVE_TV(),
  },
  [PROVIDER_ID.EPG]: {
    id: PROVIDER_ID.EPG,
    name: trans.STRUCTURES_PROVIDER_NAME__EPG(),
    info: trans.STRUCTURES_PROVIDER_INFO__EPG(),
    extendedInfo: trans.STRUCTURES_PROVIDER_EXTENDED_INFO__EPG(),
  },
  [PROVIDER_ID.SETTINGS]: {
    id: PROVIDER_ID.SETTINGS,
    name: trans.STRUCTURES_PROVIDER_NAME__DEVICE_SETTINGS(),
    info: trans.STRUCTURES_PROVIDER_INFO__DEVICE_SETTINGS(),
    extendedInfo: trans.STRUCTURES_PROVIDER_EXTENDED_INFO__DEVICE_SETTINGS(),
  },
  [PROVIDER_ID.SEARCH]: {
    id: PROVIDER_ID.SEARCH,
    name: trans.STRUCTURES_PROVIDER_NAME__SEARCH(),
    info: trans.STRUCTURES_PROVIDER_INFO__SEARCH(),
    extendedInfo: trans.STRUCTURES_PROVIDER_EXTENDED_INFO__SEARCH(),
  },
  [PROVIDER_ID.FAVOURITES]: {
    id: PROVIDER_ID.FAVOURITES,
    name: trans.STRUCTURES_PROVIDER_NAME__FAVOURITES(),
    info: trans.STRUCTURES_PROVIDER_INFO__FAVOURITES(),
    extendedInfo: trans.STRUCTURES_PROVIDER_EXTENDED_INFO__FAVOURITES(),
  },
  [PROVIDER_ID.WEATHER]: {
    id: PROVIDER_ID.WEATHER,
    name: trans.STRUCTURES_PROVIDER_NAME__WEATHER(),
    info: trans.STRUCTURES_PROVIDER_INFO__WEATHER(),
    extendedInfo: trans.STRUCTURES_PROVIDER_EXTENDED_INFO__WEATHER(),
  },
  [PROVIDER_ID.AVINPUTS]: {
    id: PROVIDER_ID.AVINPUTS,
    name: trans.STRUCTURES_PROVIDER_NAME__AVINPUTS(),
    info: trans.STRUCTURES_PROVIDER_INFO__AVINPUTS(),
    extendedInfo: trans.STRUCTURES_PROVIDER_EXTENDED_INFO__AVINPUTS(),
  },
};

export const PACKAGE_ITEMS_COLUMNS = [
  { id: "dragHeader", label: "", className: styles.dragHeader },
  {
    id: "iconHeader",
    label: trans.STRUCTURES_PACKAGE__LIST_ICON_COL(),
    className: styles.iconHeader,
  },
  {
    id: "nameHeader",
    label: trans.STRUCTURES_PACKAGE__LIST_NAME_COL(),
    className: styles.nameHeader,
  },
  {
    id: "categoryHeader",
    label: trans.STRUCTURES_PACKAGE__LIST_CATEGORY_COL(),
    className: styles.categoryHeader,
  },
  {
    id: "removeHeader",
    label: "",
    className: styles.removeHeader,
  },
];

export const PACKAGE_ITEMS_SORT = {
  ALPHABETICALLY: "NAME_ASC",
  NEWEST: "CREATED_DATE_DESC",
  CUSTOM: "CUSTOM",
};

export const SORT_VALUES = [
  {
    text: trans.STRUCTURES_PACKAGE_ITEM__SORT_A_Z(),
    value: PACKAGE_ITEMS_SORT.ALPHABETICALLY,
  },
  {
    text: trans.STRUCTURES_PACKAGE_ITEM__SORT_NEWEST(),
    value: PACKAGE_ITEMS_SORT.NEWEST,
  },
  {
    text: trans.STRUCTURES_PACKAGE_ITEM__SORT_CUSTOM(),
    value: PACKAGE_ITEMS_SORT.CUSTOM,
    isDefault: true,
  },
];

export const AUTOFILL_LENGTH_VALUES = [
  { text: "10", value: 10 },
  { text: "20", value: 20, isDefault: true },
  { text: "30", value: 30 },
  { text: "40", value: 40 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];

const AUTOFILL_SORT = {
  ALPHABETICALLY: "NAME",
  NEWEST: "NEWEST",
  POPULARITY_DESC: "POPULAR",
};

export const LABEL_MODAL_TEXTS = {
  EDIT: {
    [STRUCTURE_TYPES.SECTION]: {
      translationsHeader:
        trans.STRUCTURES_SECTION_LABEL_MODAL__COLUMN_TRANSLATIONS,
      copySearchPlaceholder:
        trans.STRUCTURES_SECTION_LABEL_MODAL__CREATE_COPY_SEARCH_PLACEHOLDER,
      copyTooltip:
        trans.STRUCTURES_SECTION_LABEL_MODAL__CREATE_RADIO_COPY_TOOLTIP,
    },
    [STRUCTURE_TYPES.PACKAGE]: {
      translationsHeader:
        trans.STRUCTURES_PACKAGE_LABEL_MODAL__COLUMN_TRANSLATIONS,
      copySearchPlaceholder:
        trans.STRUCTURES_SECTION_LABEL_MODAL__CREATE_COPY_SEARCH_PLACEHOLDER,
      copyTooltip:
        trans.STRUCTURES_SECTION_LABEL_MODAL__CREATE_RADIO_COPY_TOOLTIP,
    },
  },
  CREATE: {
    [STRUCTURE_TYPES.SECTION]: {
      translationsHeader:
        trans.STRUCTURES_SECTION_LABEL_MODAL__COLUMN_TRANSLATIONS,
      modalTitle: trans.STRUCTURES_SECTION_LABEL_MODAL__CREATE_TITLE_,
      copySearchPlaceholder:
        trans.STRUCTURES_SECTION_LABEL_MODAL__CREATE_COPY_SEARCH_PLACEHOLDER,
      copyTooltip:
        trans.STRUCTURES_SECTION_LABEL_MODAL__CREATE_RADIO_COPY_TOOLTIP,
    },
    [STRUCTURE_TYPES.PACKAGE]: {
      translationsHeader:
        trans.STRUCTURES_PACKAGE_LABEL_MODAL__COLUMN_TRANSLATIONS,
      modalTitle: trans.STRUCTURES_PACKAGE_LABEL_MODAL__CREATE_TITLE,
      copySearchPlaceholder:
        trans.STRUCTURES_PACKAGE_LABEL_MODAL__CREATE_COPY_SEARCH_PLACEHOLDER,
      copyTooltip:
        trans.STRUCTURES_PACKAGE_LABEL_MODAL__CREATE_RADIO_COPY_TOOLTIP,
    },
  },
};

export const AUTOFILL_SORT_VALUES = [
  {
    text: trans.STRUCTURES_PACKAGE_ITEM__SORT_A_Z(),
    value: AUTOFILL_SORT.ALPHABETICALLY,
  },
  {
    text: trans.STRUCTURES_PACKAGE_ITEM__SORT_NEWEST(),
    value: AUTOFILL_SORT.NEWEST,
  },
  {
    text: trans.STRUCTURES_PACKAGE_ITEM__SORT_POPULARITY(),
    value: AUTOFILL_SORT.POPULARITY_DESC,
    isDefault: true,
  },
];
