// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { WarningModal } from "components/layout";
import { trans } from "src/translations";
import { getGqlErrorMessage } from "utils/errors";
import { withMutation, mutationResultType } from "utils/graphql";

import DeleteAdminStructureMutation from "../Structures/gql/deleteAdminStructure";
import DeleteStructureMutation from "../Structures/gql/deleteStructure";
import styles from "./modals.scss";

// TODO CS-170: Add error handling

@withMutation({
  name: "deleteStructure",
  mutation: DeleteStructureMutation,
})
@withMutation({
  name: "deleteAdminStructure",
  mutation: DeleteAdminStructureMutation,
})
export default class RemoveStructureConfirmModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    refetchList: PropTypes.func,
    structureId: PropTypes.string,
    forAdmin: PropTypes.bool,

    // from @withMutation
    deleteStructureMutation: PropTypes.func,
    deleteStructureMutationStatus: mutationResultType,
    deleteAdminStructureMutation: PropTypes.func,
    deleteAdminStructureMutationStatus: mutationResultType,
  };

  onRemoveStructure = async () => {
    const {
      onClose,
      structureId,
      refetchList,
      deleteStructureMutation,
      deleteAdminStructureMutation,
      forAdmin,
    } = this.props;

    const request = forAdmin
      ? deleteAdminStructureMutation
      : deleteStructureMutation;

    await request({
      variables: {
        input: {
          structureId: structureId,
        },
      },
    });

    onClose();

    if (refetchList) {
      await refetchList();
    }
  };

  handleCloseModal = () => {
    const {
      onClose,
      forAdmin,
      deleteStructureMutationStatus,
      deleteAdminStructureMutationStatus,
    } = this.props;
    const { reset: resetModalStatus } = forAdmin
      ? deleteAdminStructureMutationStatus
      : deleteStructureMutationStatus;

    resetModalStatus();
    onClose();
  };

  render() {
    const {
      isOpen,
      onClose,
      forAdmin,
      deleteStructureMutationStatus,
      deleteAdminStructureMutationStatus,
    } = this.props;
    const { loading, error } = forAdmin
      ? deleteAdminStructureMutationStatus
      : deleteStructureMutationStatus;

    return (
      <WarningModal
        isOpen={isOpen}
        onClose={onClose}
        title={trans.STRUCTURES_MODAL__REMOVE_HEADER()}
        dataTestId="remove-structure-modal"
        actions={
          <ButtonsWrapper position="modal">
            <Button
              type="normal"
              onClick={this.handleCloseModal}
              disabled={loading}
              dataTestId="remove-structure-cancel-button"
            >
              {trans.CANCEL()}
            </Button>
            <Button
              type="green"
              onClick={this.onRemoveStructure}
              processing={loading}
              disabled={loading}
              dataTestId="remove-structure-ok-button"
            >
              {trans.DELETE()}
            </Button>
          </ButtonsWrapper>
        }
      >
        <p>{trans.STRUCTURES_LIST__REMOVE_WARNING()}</p>
        {error && (
          <Info type="error" className={styles.error}>
            {getGqlErrorMessage(error)}
          </Info>
        )}
      </WarningModal>
    );
  }
}
