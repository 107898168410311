// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

export const FORM_NAME = "createStructureModal";

export const CREATE_MODE = {
  CREATE_NEW: "create-new",
  COPY_EXISTING: "copy-existing",
};

export const INITIAL_FORM_STATE = {
  createMode: CREATE_MODE.CREATE_NEW,
  structureToCopy: "",
  name: "",
};
