// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { Info } from "components/feedback";
import { withCountries } from "utils/continents";

@withCountries
export class StructureData extends Component {
  static propTypes = {
    children: PropTypes.func,

    // from @withCountries
    countriesLoading: PropTypes.bool.isRequired,
    countriesError: PropTypes.object,
    allCountries: PropTypes.array.isRequired,
  };

  render() {
    const { children, countriesLoading, countriesError, allCountries } =
      this.props;

    if (countriesLoading) {
      return <Loader />;
    }

    if (countriesError) {
      return (
        <Info type="error">{countriesError.message || countriesError}</Info>
      );
    }

    return children({ allCountries });
  }
}
