// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PageContent, PageHeader } from "components/layout";
import { trans } from "src/translations";

import { PackageLabelsData } from "./Labels/PackageLabelsData";

export default function PackageLabels() {
  return (
    <div>
      <PageHeader>{trans.STRUCTURES_PACKAGE__HEADER()}</PageHeader>
      <PageContent>
        <PackageLabelsData />
      </PageContent>
    </div>
  );
}
