// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { TextField } from "components/form/fields";
import { Row, Column } from "components/layout";
import { ContentBox, Header } from "components/typography";
import { SECTIONS } from "pages/Snap/shared/constants";

import styles from "./Analytics.scss";

export const Analytics = ({ name, label, dataTestId }) => (
  <Row>
    <Column>
      <Header bold={true} color="secondary" uppercase={true}>
        {SECTIONS.ANALYTICS.header}
      </Header>
      <TextField
        name={name}
        label={label}
        dataTestId={dataTestId}
        required={false}
      />
    </Column>
    <Column>
      <ContentBox title={SECTIONS.ANALYTICS.title}>
        <p className={styles.contentBoxText}>
          {SECTIONS.ANALYTICS.content}
          <a
            className={styles.contentBoxLink}
            href="https://d3u3bgui77bitj.cloudfront.net/common-statics/cloud/snap/Google%20Analytics%20Configuration%20for%20Snap.pdf"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            the Google Analytics guide
          </a>
          {"."}
        </p>
      </ContentBox>
    </Column>
  </Row>
);

Analytics.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  dataTestId: PropTypes.string,
};
