// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { GrabsonIcon } from "components/icons";
import { Tooltip } from "components/popups";
import { trans } from "src/translations";

import { LABEL_ACTION_TOOLTIP } from "./constants";
import styles from "./LabelModal.scss";

const renderLabelTooltip = ({ selectedLabelAction }) => {
  const { info, shared, notShared, additionalInfo, example } =
    LABEL_ACTION_TOOLTIP[selectedLabelAction];

  const renderSharedItem = (text, i) => (
    <li key={`${text}${i}`}>
      <GrabsonIcon
        className={styles.tooltipSharedIcon}
        name="ok-filled"
        size="normal"
      />
      <span>{text}</span>
    </li>
  );

  const renderNotSharedItem = (text, i) => (
    <li key={`${text}${i}`}>
      <GrabsonIcon
        className={styles.tooltipNotSharedIcon}
        name="close-filled"
        size="normal"
      />
      <span>{text}</span>
    </li>
  );

  const tooltipContent = (
    <div className={styles.tooltipContent}>
      <div>{info}</div>
      {shared && (
        <div>
          <h4>{trans.STRUCTURES_LABEL_MODAL__SHARED_HEADER_TOOLTIP()}</h4>
          <ul>{shared.map((text, i) => renderSharedItem(text, i))}</ul>
        </div>
      )}
      {notShared && (
        <div>
          <h4>{trans.STRUCTURES_LABEL_MODAL__NOT_SHARED_HEADER_TOOLTIP()}</h4>
          <ul>{notShared.map((text) => renderNotSharedItem(text))}</ul>
        </div>
      )}
      {additionalInfo && <div>{additionalInfo}</div>}
      {example && (
        <div className={styles.tooltipExample}>
          <h4>{`${trans.EXAMPLE()}:`}</h4>
          {example}
        </div>
      )}
    </div>
  );

  return (
    <Tooltip
      className={styles.labelTooltip}
      content={tooltipContent}
      alignment="bottom"
    >
      <GrabsonIcon
        className={styles.tooltipIcon}
        name="help-filled"
        size="2xl"
      />
    </Tooltip>
  );
};

export const LabelModalContent = ({
  selectedLabelAction,
  selectLabelActionField,
  children,
  withReplaceOptions = false,
}) => {
  return (
    <>
      {withReplaceOptions && (
        <div className={styles.labelSelectWrapper}>
          {selectLabelActionField}
          {renderLabelTooltip({ selectedLabelAction })}
        </div>
      )}
      {children}
    </>
  );
};

LabelModalContent.propTypes = {
  withReplaceOptions: PropTypes.bool.isRequired,
  selectLabelActionField: PropTypes.node,
  selectedLabelAction: PropTypes.string,
  children: PropTypes.node,
};
