import get from "lodash-es/get";

import { trans } from "src/translations";
import { createValidator } from "utils/validation";

const isSectionLabelNotUsed = (path, sectionLabelIds) =>
  createValidator(
    (field) => {
      const fieldId = field?.id;
      return sectionLabelIds.includes(fieldId);
    },
    trans.STRUCTURES_LABEL_MODAL__DUPLICATE_SECTION_ERROR(),
    path
  );

const isPackageLabelNotUsed = (path, packageLabelIds) =>
  createValidator(
    (field) => {
      const fieldId = field?.id;
      return packageLabelIds.includes(fieldId);
    },
    trans.STRUCTURES_LABEL_MODAL__DUPLICATE_PACKAGE_ERROR(),
    path
  );

export const notSelectedLabelToCopy = (path) =>
  createValidator(
    (field) => Object.keys(field).length === 0,
    trans.STRUCTURES_LABEL_MODAL__NOT_SELECTED_SECTION_ERROR(),
    path
  );

export const getAlreadyUsedSectionLabels = (formValues) => {
  return get(formValues, "sections", []).map(
    (section) => section.sectionLabelId
  );
};

export const getAlreadyUsedPackageLabels = (formValues, fieldName) => {
  return get(formValues, `${fieldName}.packages`, []).map(
    (pack) => pack.packageLabelId
  );
};

export const getDuplicateLabelValidator = (
  isSection,
  path,
  alreadyUsedLabelIds
) => {
  if (isSection) {
    return isSectionLabelNotUsed(path, alreadyUsedLabelIds);
  }
  return isPackageLabelNotUsed(path, alreadyUsedLabelIds);
};
