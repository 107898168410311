// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, Component } from "react";

import PropTypes from "prop-types";

import { SearchableList } from "components/elements";
import { Label, ValidationBadge } from "components/form";
import { InfiniteData } from "containers/InfiniteData";
import { trans } from "src/translations";
import { getGqlErrorMessage } from "utils/errors";
import { withQuery } from "utils/graphql";
import { calulateOffsetFromPage } from "utils/pagination";

import { LABEL_TYPES } from "../../../../Labels/constants";
import { TransCreatePropTypes, LabelType } from "../../../../Labels/propTypes";
import { ITEMS_PER_COPY_PAGE } from "../../constants";
import { packageLabelsGql } from "../../gql/packageLabels";
import { sectionLabelsGql } from "../../gql/sectionLabels";
import { TranslationRow } from "./TranslationRow";

@withQuery({ name: "sectionLabels", query: sectionLabelsGql })
@withQuery({ name: "packageLabels", query: packageLabelsGql })
export default class LabelToCopy extends Component {
  static propTypes = {
    texts: TransCreatePropTypes,
    labelType: LabelType,
    languages: PropTypes.array,
    // from @withQuery
    sectionLabelsQuery: PropTypes.func,
    packageLabelsQuery: PropTypes.func,
    // from @formik
    field: PropTypes.object,
    form: PropTypes.object,
    meta: PropTypes.object,
  };

  fetchPackageLabels = async (queryParams) => {
    const { packageLabelsQuery } = this.props;

    const result = await packageLabelsQuery({
      variables: {
        pagination: {
          offset: calulateOffsetFromPage(queryParams.page, ITEMS_PER_COPY_PAGE),
          limit: ITEMS_PER_COPY_PAGE,
        },
        search: {
          query: queryParams.search || "",
        },
      },
    });

    const packageLabels = result?.packageLabels?.packageLabels?.items ?? [];

    return {
      meta: {
        count: result?.packageLabels?.packageLabels?.pagination?.count ?? 0,
      },
      results: packageLabels,
    };
  };

  fetchSectionLabels = async (queryParams) => {
    const { sectionLabelsQuery } = this.props;

    const result = await sectionLabelsQuery({
      variables: {
        pagination: {
          offset: calulateOffsetFromPage(queryParams.page, ITEMS_PER_COPY_PAGE),
          limit: ITEMS_PER_COPY_PAGE,
        },
        search: {
          query: queryParams.search || "",
        },
      },
    });

    const sectionLabels = result?.sectionLabels?.sectionLabels?.items ?? [];

    return {
      meta: {
        count: result?.sectionLabels?.sectionLabels?.pagination?.count ?? 0,
      },
      results: sectionLabels,
    };
  };

  adaptLabels = (labels) => {
    if (!labels) {
      return [];
    }
    return labels.map((label) => ({
      id: label.id,
      name: label?.translations[0]?.translation ?? "",
      useCount: Number(label.usedInStructuresCount),
    }));
  };

  renderRow = (label, i) => {
    const {
      field,
      languages,
      labelType,
      form: { getFieldHelpers },
    } = this.props;

    const { setValue, setTouched } = getFieldHelpers(field.name);

    return (
      <TranslationRow
        key={label.id}
        field={field}
        label={label}
        index={i}
        languages={languages}
        labelType={labelType}
        setValue={setValue}
        setTouched={setTouched}
      />
    );
  };

  isSection() {
    return this.props.labelType === LABEL_TYPES.SECTION;
  }

  render() {
    const { meta, texts } = this.props;
    return (
      <Fragment>
        <Label text={trans.STRUCTURES_LABEL_MODAL__CREATE_COPY_SEARCH()} />
        <InfiniteData
          fetchData={
            this.isSection() ? this.fetchSectionLabels : this.fetchPackageLabels
          }
          mapData={this.adaptLabels}
          itemsPerPage={ITEMS_PER_COPY_PAGE}
        >
          {({
            data,
            pending,
            error,
            allDataLoaded,
            handleScroll,
            handleSearchChange,
          }) => {
            return (
              <SearchableList
                dataTestId="create-label-existing-list"
                list={data}
                onScroll={handleScroll}
                onSearchChange={handleSearchChange}
                withLoader={true}
                loading={pending}
                allDataLoaded={allDataLoaded}
                error={error && getGqlErrorMessage(error)}
                placeholder={texts.copySearchPlaceholder()}
              >
                {this.renderRow}
              </SearchableList>
            );
          }}
        </InfiniteData>
        <ValidationBadge error={meta.error} touched={meta.touched} />
      </Fragment>
    );
  }
}
