// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { Info } from "components/feedback";
import { apiThatThrows } from "containers/Request";
import { createBody } from "utils/jsonApi";

import { getFullName } from "../../_utils";
import {
  ActivationWarningModal,
  WARNING_MODAL_MODES,
} from "../../AdminUsersTable/ActivationWarningModal";
import { AccountStatus } from "./AccountStatus";

@connect(null, (dispatch) => ({
  setUserStatus: (id, body) =>
    dispatch(
      apiThatThrows.patchUserProfileNoOrganization.action({
        params: { id },
        options: { body },
      })
    ),
}))
export class AccountStatusData extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    onRefreshData: PropTypes.func,

    // from @connect
    setUserStatus: PropTypes.func.isRequired,
  };

  state = {
    processedUser: null,
    warningModalMode: WARNING_MODAL_MODES.CLOSED,
    setStatusLoading: false,
    error: null,
  };

  prepareSetStatusBody = (status) => {
    const data = {
      type: "users",
      is_active: status,
    };
    return createBody(data);
  };

  performSetActiveRequest = async () => {
    const { setUserStatus, onRefreshData } = this.props;
    const {
      processedUser: { id },
      warningModalMode,
    } = this.state;
    this.setState({ setStatusLoading: true });

    const body = this.prepareSetStatusBody(
      warningModalMode === WARNING_MODAL_MODES.ACTIVATE
    );

    try {
      await setUserStatus(id, body);
      await onRefreshData();
    } catch (error) {
      this.setState({ error });
    } finally {
      this.handleCloseModal();
    }
  };

  handleSetActive = (status) => {
    const { user } = this.props;
    this.setState({
      processedUser: { id: user.id, name: getFullName(user) },
      warningModalMode: status
        ? WARNING_MODAL_MODES.ACTIVATE
        : WARNING_MODAL_MODES.DEACTIVATE,
    });
  };

  handleCloseModal = () =>
    this.setState({
      warningModalMode: WARNING_MODAL_MODES.CLOSED,
      processedUser: null,
      setStatusLoading: false,
    });

  render() {
    const {
      user: { is_active },
    } = this.props;
    const { setStatusLoading, warningModalMode, processedUser, error } =
      this.state;
    return (
      <Fragment>
        <AccountStatus
          isActive={is_active}
          onActivate={() => this.handleSetActive(true)}
          onDeactivate={() => this.handleSetActive(false)}
        />
        <ActivationWarningModal
          userName={processedUser ? processedUser.name : null}
          mode={warningModalMode}
          onCancel={this.handleCloseModal}
          onConfirm={this.performSetActiveRequest}
          loading={setStatusLoading}
        />
        {error && <Info type="error">{error}</Info>}
      </Fragment>
    );
  }
}
