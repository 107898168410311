// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useEffect } from "react";

import { useField } from "formik";
import PropTypes from "prop-types";

import { SearchBarWithFilters } from "components/elements";
import { filterSectionPropTypes } from "components/elements/SearchBarWithFilters/propTypes";
import { trans } from "src/translations";

export const AppsListSearchField = ({
  name,
  availableFilters,
  getSuggestions,
  onSearch,
}) => {
  const [field, , helpers] = useField(name);

  useEffect(() => {
    onSearch(field.value);
  }, [onSearch, field]);

  const handleSearch = (value) => {
    helpers.setValue(value);
    helpers.setTouched(true);
    onSearch(value);
  };

  return (
    <SearchBarWithFilters
      name={field.name}
      filtersDataChoices={availableFilters}
      look="for-white-bg"
      placeholder={trans.STRUCTURES_PACKAGE__LIST_SEARCH_PLACEHOLDER()}
      searchParams={field.value}
      onSearch={handleSearch}
      suggestionApiEndpoint={getSuggestions}
      dataTestId="applications-search-bar"
    />
  );
};

AppsListSearchField.propTypes = {
  name: PropTypes.string.isRequired,
  availableFilters: PropTypes.shape({
    sections: PropTypes.arrayOf(filterSectionPropTypes).isRequired,
  }).isRequired,
  getSuggestions: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};
