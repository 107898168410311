import { trans } from "src/translations";

import { createStructureInput } from "./createStructureInput";

export const submitForm = async ({
  values,
  isEdit,
  allCountriesCount,
  forAdmin,
  navigate,
  page,
  submitMutation,
}) => {
  const pageString = `?page=${page}`;
  const structuresListPath = forAdmin
    ? "/structures/admin/list"
    : "/structures/list";

  await submitMutation({
    variables: {
      input: createStructureInput(isEdit, values, allCountriesCount),
    },
  });

  const successMessage = isEdit
    ? trans.STRUCTURES_LIST__EDIT_SUCCESS()
    : trans.STRUCTURES_LIST__CREATE_SUCCESS();

  navigate(
    {
      pathname: structuresListPath,
      search: pageString,
    },
    { state: { message: successMessage } }
  );
};
