// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useCallback } from "react";

import { useField } from "formik";
import PropTypes from "prop-types";

import { TextField, ScoreField } from "components/form/fields";
import { Section } from "components/layout";
import { TargetsField, TARGET_FIELD_MODE } from "pages/_shared";
import { TARGETS_FIELD_NAME } from "pages/_shared/TargetsField/constants";
import { HtmlTranslation, trans } from "src/translations";

import { calculateScore } from "../utils/calculateScore";
import styles from "./TabBasicDetails.scss";

export const NAME_FIELD_NAME = "name";
const FIELD_NAME = "score";
const AUTOGENERATED_FIELD_NAME = "isScoreAutogenerated";

export const TabBasicDetails = ({
  activeOrganizationId,
  allCountriesCount,
  forAdmin,
}) => {
  const [{ value: targets }] = useField(TARGETS_FIELD_NAME);
  const getScore = useCallback(
    () => calculateScore({ forAdmin, targets, allCountriesCount }),
    [allCountriesCount, forAdmin, targets]
  );

  return (
    <>
      <Section header={trans.STRUCTURES_EDIT__SECTION_NAME()}>
        <TextField
          name={NAME_FIELD_NAME}
          label={trans.STRUCTURES_EDIT__FIELD_NAME()}
          look="simple"
          dataTestId="structure-form-name-input"
        />
      </Section>
      <Section header={trans.STRUCTURES_EDIT__SECTION_TARGETS()}>
        <TargetsField
          required={true}
          devicesDistributorId={activeOrganizationId}
          forAdmin={forAdmin}
          mode={
            forAdmin
              ? TARGET_FIELD_MODE.ALL_DEVICES
              : TARGET_FIELD_MODE.GRANULAR
          }
        />
      </Section>
      <Section header={trans.STRUCTURES_EDIT__SCORE_SECTION()}>
        <HtmlTranslation
          className={styles.scoreInfo}
          component="p"
          transFunc={trans.STRUCTURES_EDIT__SCORE_DESCRIPTION}
        />
        <ScoreField
          name={FIELD_NAME}
          autogenFieldName={AUTOGENERATED_FIELD_NAME}
          getScore={getScore}
        />
      </Section>
    </>
  );
};

TabBasicDetails.propTypes = {
  activeOrganizationId: PropTypes.string.isRequired,
  allCountriesCount: PropTypes.number.isRequired,
  forAdmin: PropTypes.bool.isRequired,
};
