// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { DYNAMIC_FEATURES } from "config";

import { ROLES } from "containers/Permissions";
import {
  ALL_USERS,
  ALL_ORGANIZATION_MEMBERS,
  ALL_PROVIDERS,
  ALL_MODERATORS,
  ALL_DISTRIBUTORS,
  ALL_DISTRIBUTION_PLANNERS,
} from "containers/Permissions/groups";
import {
  APP_ROUTE_MY,
  APP_ROUTE_MODERATION,
  APP_ROUTE_DISTRIBUTION,
  APP_ROUTE_AVAILABLE,
} from "pages/Applications/constants";
import { trans } from "src/translations";

/**
Example entry:
{
  // main route
  path: "/applications",
  // icon to display in side menu
  icon: "app",
  // text to display in side menu
  label: trans.SIDE_MENU__APPLICATIONS_ITEM(),
  // list or roles that can see this item
  allowed: [ALL_USERS],
  // id used for automated tests
  dataTestId: "applications-main-item",
  // array of subitems
  items: [
    {
      // path to subpage. Must start with parent's path
      path: `/applications/my`,
      // text to display in side menu
      label: trans.SIDE_MENU__MY_APPS_ITEM(),
      // list or roles that can see this item
      allowed: ALL_USERS,
      // id used for automated tests
      dataTestId: "my-apps-sub-item",
      // On each page, we should have some side menu item marked as active.
      // This is an array of urls, each expressed as string/regex.
      isActiveForPaths: [`/applications/my`],
    },
  ],
},
*/

export const menuConfig = () => [
  {
    path: "/dashboard",
    icon: "grid",
    label: trans.SIDE_MENU__DASHBOARD_ITEM(),
    allowed: ALL_USERS,
    dataTestId: "dashboard-main-item",
  },
  {
    path: "/applications",
    icon: "app",
    label: trans.SIDE_MENU__APPLICATIONS_ITEM(),
    allowed: [
      ...ALL_PROVIDERS,
      ...ALL_MODERATORS,
      ROLES.distributor.distributionPlanner,
      ROLES.administrator.distributionAdmin,
    ],
    dataTestId: "applications-main-item",
    items: [
      {
        path: `/applications/${APP_ROUTE_MY}`,
        label: trans.SIDE_MENU__MY_APPS_ITEM(),
        allowed: ALL_PROVIDERS,
        dataTestId: "my-apps-sub-item",
        isActiveForPaths: [`/applications/${APP_ROUTE_MY}`],
      },
      {
        path: `/applications/${APP_ROUTE_MODERATION}`,
        label: trans.SIDE_MENU__MODERATION_APPS_ITEM(),
        allowed: ALL_MODERATORS,
        dataTestId: "moderation-apps-sub-item",
        isActiveForPaths: [`/applications/${APP_ROUTE_MODERATION}`],
      },
      {
        path: `/applications/${APP_ROUTE_DISTRIBUTION}`,
        label: trans.SIDE_MENU__APPS_TO_DISTRIBUTE_ITEM(),
        allowed: [ROLES.distributor.distributionPlanner],
        dataTestId: "distribution-apps-sub-item",
        isActiveForPaths: [`/applications/${APP_ROUTE_DISTRIBUTION}`],
      },
      {
        path: `/applications/${APP_ROUTE_AVAILABLE}`,
        label: trans.SIDE_MENU__AVAILABLE_APPS_ITEM(),
        allowed: [
          ROLES.moderator.moderator,
          ROLES.moderator.contentManager,
          ROLES.administrator.distributionAdmin,
        ],
        dataTestId: "available-apps-sub-item",
        isActiveForPaths: [`/applications/${APP_ROUTE_AVAILABLE}`],
      },
      {
        path: "/applications/create",
        label: trans.SIDE_MENU__SUBMIT_APP_ITEM(),
        allowed: ALL_PROVIDERS,
        dataTestId: "submit-app-sub-item",
        isActiveForPaths: ["/applications/create"],
      },
    ],
  },
  {
    path: "/snap",
    icon: "snap",
    label: trans.SIDE_MENU__APP_CREATOR_ITEM(),
    allowed: ALL_PROVIDERS,
    dataTestId: "snap-main-item",
    items: [
      {
        path: "/snap/my",
        label: trans.SIDE_MENU__MY_APPS_ITEM(),
        allowed: ALL_PROVIDERS,
        dataTestId: "my-apps-sub-item",
        isActiveForPaths: ["/snap/my", /snap\/.*?\/details/, /snap\/.*?\/edit/],
      },
      {
        path: "/snap/create",
        label: trans.SIDE_MENU__CREATE_APP_ITEM(),
        allowed: ALL_PROVIDERS,
        dataTestId: "create-app-sub-item",
        isActiveForPaths: ["/snap/create"],
      },
    ],
  },
  {
    path: "/manifests",
    icon: "details",
    label: trans.SIDE_MENU__APP_MANIFESTS_ITEM(),
    allowed: ALL_MODERATORS,
    dataTestId: "manifests-main-item",
    items: [
      {
        path: "/manifests/list",
        label: trans.SIDE_MENU__APP_MANIFESTS_LIST_ITEM(),
        allowed: ALL_MODERATORS,
        dataTestId: "manifests-list-sub-item",
        isActiveForPaths: [
          "/manifests/list",
          /manifests\/edit\/.*?\/application-id/,
          /manifests\/edit\/.*?\/manifest/,
        ],
      },
      {
        path: "/manifests/create",
        label: trans.SIDE_MENU__APP_MANIFESTS_CREATE_ITEM(),
        allowed: [ROLES.moderator.appIntegrator],
        dataTestId: "manifests-create-sub-item",
        isActiveForPaths: ["/manifests/create"],
      },
    ],
  },
  {
    path: "/structures",
    icon: "structures",
    label: trans.SIDE_MENU__STRUCTURES_ITEM(),
    allowed: [
      ROLES.structure.structureManager,
      ROLES.administrator.structuresAdmin,
    ],
    dataTestId: "structures-main-item",
    items: [
      {
        path: "/structures/list",
        label: trans.SIDE_MENU__STRUCTURE_LIST_ITEM(),
        allowed: [ROLES.structure.structureManager],
        dataTestId: "structures-list-sub-item",
        isActiveForPaths: [
          "/structures/list",
          "/structures/create",
          "/structures/edit",
        ],
      },
      {
        path: "/structures/admin/list",
        label: trans.SIDE_MENU__ADMIN_STRUCTURE_LIST_ITEM(),
        allowed: [ROLES.administrator.structuresAdmin],
        dataTestId: "admin-structures-list-sub-item",
        isActiveForPaths: [
          "/structures/admin/list",
          "/structures/admin/create",
          "/structures/admin/edit",
        ],
      },
      {
        path: "/structures/sections",
        label: trans.SIDE_MENU__STRUCTURE_SECTION_ITEM(),
        allowed: [
          ROLES.structure.structureManager,
          ROLES.administrator.structuresAdmin,
        ],
        dataTestId: "sections-list-sub-item",
        isActiveForPaths: ["/structures/sections"],
      },
      {
        path: "/structures/packages",
        label: trans.SIDE_MENU__STRUCTURE_PACKAGE_ITEM(),
        allowed: [
          ROLES.structure.structureManager,
          ROLES.administrator.structuresAdmin,
        ],
        dataTestId: "packages-list-sub-item",
        isActiveForPaths: ["/structures/packages"],
      },
    ],
  },
  {
    path: "/devices",
    icon: "device-inventory",
    label: trans.SIDE_MENU__DEVICE_ITEM(),
    allowed: ALL_ORGANIZATION_MEMBERS,
    dataTestId: "devices-main-item",
    items: [
      {
        path: "/devices/inventory",
        label: trans.SIDE_MENU__DEVICE_INVENTORY_ITEM(),
        allowed: [ROLES.deviceInventory.deviceManager],
        dataTestId: "device-inventory-sub-item",
        isActiveForPaths: ["/devices/inventory"],
      },
      {
        path: "/devices/queue",
        label: trans.SIDE_MENU__DEVICE_PROCESSING_QUEUE_ITEM(),
        allowed: [ROLES.deviceInventory.deviceManager],
        dataTestId: "device-queue-sub-item",
        isActiveForPaths: ["/devices/queue"],
      },
      DYNAMIC_FEATURES.DEVICE_COMMANDS === "true"
        ? {
            path: "/devices/commands",
            label: trans.SIDE_MENU__DEVICE_COMMANDS_ITEM(),
            allowed: [ROLES.deviceInventory.configControlManager],
            dataTestId: "device-commands-sub-item",
            isActiveForPaths: ["/devices/commands"],
          }
        : null,
      {
        path: "/devices/create",
        label: trans.SIDE_MENU__ADD_DEVICE_MODEL_ITEM(),
        allowed: [ROLES.administrator.deviceAdmin],
        dataTestId: "add-device-sub-item",
        isActiveForPaths: ["/devices/create"],
      },
      {
        path: "/devices/filters",
        label: trans.SIDE_MENU__DEVICE_FILTERS_AND_QUERIES_ITEM(),
        allowed: [ROLES.deviceInventory.deviceManager],
        dataTestId: "device-filters-sub-item",
        isActiveForPaths: ["/devices/filters"],
      },
      {
        path: "/devices/models",
        label: trans.SIDE_MENU__DEVICE_MODELS_ITEM(),
        allowed: [ROLES.administrator.deviceAdmin, ROLES.moderator.moderator],
        dataTestId: "device-models-sub-item",
        isActiveForPaths: ["/devices/models"],
      },
      {
        path: "/devices/my",
        label: trans.SIDE_MENU__MY_DEVICE_MODELS_ITEM(),
        allowed: ALL_DISTRIBUTORS,
        dataTestId: "my-device-models-sub-item",
        isActiveForPaths: ["/devices/my"],
      },
      {
        path: "/devices/pairing",
        label: trans.SIDE_MENU__DEVICE_PAIRING_ITEM(),
        allowed: ALL_ORGANIZATION_MEMBERS,
        dataTestId: "device-pairing-sub-item",
        isActiveForPaths: ["/devices/pairing"],
      },
      {
        href: "https://api-services-production.s3-us-west-2.amazonaws.com/docs/Vewd+DI+User+Guide.pdf",
        props: {
          target: "_blank",
          rel: "noopener noreferrer",
        },
        label: trans.HELP(),
        allowed: [ROLES.deviceInventory.deviceManager],
        dataTestId: "device-help-sub-item",
      },
    ].filter((el) => el !== null),
  },
  {
    path: "/updates",
    icon: "update",
    label: trans.SIDE_MENU__UPDATE_ITEM(),
    allowed: [ROLES.softwareUpdates.updateManager],
    dataTestId: "updates-main-item",
    items: [
      {
        path: "/updates/jobs",
        label: trans.SIDE_MENU__UPDATE_JOB_LIST_ITEM(),
        allowed: [ROLES.softwareUpdates.updateManager],
        dataTestId: "update-job-list-sub-item",
        isActiveForPaths: ["/updates/jobs"],
      },
      {
        path: "/updates/create",
        label: trans.SIDE_MENU__CREATE_UPDATE_JOB(),
        allowed: [ROLES.softwareUpdates.updateManager],
        dataTestId: "create-update-job-sub-item",
        isActiveForPaths: ["/updates/create"],
      },
      {
        path: "/updates/packages",
        label: trans.SIDE_MENU__SOFTWARE_PACKAGES_ITEM(),
        allowed: [ROLES.softwareUpdates.updateManager],
        dataTestId: "packages-list-sub-item",
        isActiveForPaths: ["/updates/packages"],
      },
      {
        path: "/updates/queue",
        label: trans.SIDE_MENU__SOFTWARE_PACKAGES_PROCESSING_QUEUE_ITEM(),
        allowed: [ROLES.softwareUpdates.updateManager],
        dataTestId: "updates-queue-sub-item",
        isActiveForPaths: ["/updates/queue"],
      },
      {
        href: "https://api-services-production.s3-us-west-2.amazonaws.com/docs/Vewd+SU+User+Guide.pdf",
        props: {
          target: "_blank",
          rel: "noopener noreferrer",
        },
        label: trans.HELP(),
        allowed: [ROLES.softwareUpdates.updateManager],
        dataTestId: "updates-help-sub-item",
      },
    ],
  },
  {
    path: "/distribution",
    icon: "live",
    label: trans.SIDE_MENU__DISTRIBUTION_ITEM(),
    allowed: [
      ...ALL_DISTRIBUTION_PLANNERS,
      ROLES.administrator.distributionAdmin,
    ],
    dataTestId: "distribution-main-item",
    items: [
      {
        path: "/distribution/default-plan",
        label: trans.SIDE_MENU__DEFAULT_DISTRIBUTION_ITEM(),
        allowed: [ROLES.distributor.distributionPlanner],
        dataTestId: "default-plan-sub-item",
        isActiveForPaths: ["/distribution/default-plan"],
      },
      {
        path: "/distribution/custom-plans",
        label: trans.SIDE_MENU__CUSTOM_DISTRIBUTION_ITEM(),
        allowed: ALL_DISTRIBUTION_PLANNERS,
        dataTestId: "custom-plans-sub-item",
        isActiveForPaths: ["/distribution/custom-plans"],
      },
      {
        path: "/distribution/admin-plans",
        label: trans.SIDE_MENU__DISTRIBUTION_ADMIN_ITEM(),
        allowed: [ROLES.administrator.distributionAdmin],
        dataTestId: "admin-plans-sub-item",
        isActiveForPaths: ["/distribution/admin-plans"],
      },
    ],
  },
  {
    path: "/software-assets",
    icon: "list",
    label: trans.SIDE_MENU__ASSETS_ITEM(),
    allowed: [ROLES.administrator.distributionAdmin],
    dataTestId: "software-assets-main-item",
  },
  {
    path: "/promotions",
    icon: "promotions",
    label: trans.SIDE_MENU__PROMOTION_ITEM(),
    allowed: [
      ROLES.administrator.promotionAdmin,
      ROLES.promotionManagement.promotionManager,
    ],
    dataTestId: "promotions-main-item",
    items: [
      {
        path: "/promotions/list",
        label: trans.SIDE_MENU__PROMOTIONS_ITEM(),
        allowed: [ROLES.promotionManagement.promotionManager],
        dataTestId: "promotions-sub-item",
        isActiveForPaths: [
          "/promotions/list",
          "/promotions/edit",
          "/promotions/create",
        ],
      },
      {
        path: "/promotions/campaigns",
        label: trans.SIDE_MENU__CAMPAIGNS_ITEM(),
        allowed: [ROLES.promotionManagement.promotionManager],
        dataTestId: "campaigns-sub-item",
        isActiveForPaths: ["/promotions/campaigns"],
      },
      {
        path: "/promotions/schedule",
        label: trans.SIDE_MENU__SCHEDULE_ITEM(),
        allowed: [ROLES.promotionManagement.promotionManager],
        dataTestId: "schedule",
        isActiveForPaths: ["/promotions/schedule"],
      },
      {
        path: "/promotions/admin/list",
        label: trans.SIDE_MENU__PROMOTIONS_ADMIN_ITEM(),
        allowed: [ROLES.administrator.promotionAdmin],
        dataTestId: "admin-promotions-sub-item",
        isActiveForPaths: [
          "/promotions/admin/list",
          "/promotions/admin/edit",
          "/promotions/admin/create",
        ],
      },
      {
        path: "/promotions/admin/campaigns",
        label: trans.SIDE_MENU__CAMPAIGNS_ADMIN_ITEM(),
        allowed: [ROLES.administrator.promotionAdmin],
        dataTestId: "admin-campaigns-sub-item",
        isActiveForPaths: ["/promotions/admin/campaigns"],
      },

      {
        path: "/promotions/admin/schedule",
        label: trans.SIDE_MENU__SCHEDULE_ADMIN_ITEM(),
        allowed: [ROLES.administrator.promotionAdmin],
        dataTestId: "admin=schedule",
        isActiveForPaths: ["/promotions/admin/schedule"],
      },
      {
        path: "/promotions/admin/types",
        label: trans.SIDE_MENU__PROMOTION_TYPES_ITEM(),
        allowed: [ROLES.administrator.promotionAdmin],
        dataTestId: "admin-promotion-types-sub-item",
        isActiveForPaths: ["/promotions/admin/types"],
      },
    ],
  },
  {
    path: "/users",
    icon: "users",
    label: trans.SIDE_MENU__USERS_ITEM(),
    allowed: ALL_ORGANIZATION_MEMBERS,
    dataTestId: "users-main-item",
    items: [
      {
        path: "/users/admin",
        label: trans.SIDE_MENU__USERS_ADMIN_ITEM(),
        allowed: [ROLES.administrator.userAdmin],
        dataTestId: "users-admin-sub-item",
        isActiveForPaths: ["users/admin"],
      },
      {
        path: "/users/list",
        label: trans.SIDE_MENU__USER_LIST_ITEM(),
        allowed: ALL_ORGANIZATION_MEMBERS,
        dataTestId: "users-sub-item",
        isActiveForPaths: [
          /users\/(?!admin).*?\/details/,
          /users\/(?!admin).*?\/edit/,
          "users/list",
        ],
      },
      {
        path: "/users/invite",
        label: trans.SIDE_MENU__INVITE_USER_ITEM(),
        allowed: [ROLES.organization.roleManager],
        dataTestId: "invite-user-sub-item",
        isActiveForPaths: ["/users/invite"],
      },
    ],
  },
  {
    path: "/organization",
    icon: "organizations",
    label: trans.SIDE_MENU__ORGANIZATION_ITEM(),
    allowed: ALL_USERS,
    dataTestId: "organizations-main-item",
    items: [
      {
        path: "/organization/list",
        label: trans.SIDE_MENU__ORGANIZATIONS_ITEM(),
        allowed: [ROLES.administrator.organizationAdmin],
        dataTestId: "organizations-sub-item",
        isActiveForPaths: [
          // Currently organization id can be either number (matched by '\d')
          // or slug-like expression (matched by '[\w-_]{3,}').
          // We should not match "/organization/my/details" etc.
          /organization\/([\w-_]{3,}|\d+)\/details/,
          /organization\/([\w-_]{3,}|\d+)\/edit/,
          "/organization/list",
        ],
      },
      {
        path: "/organization/my",
        label: trans.SIDE_MENU__MY_ORGANIZATION_ITEM(),
        allowed: [
          ROLES.organization.member,
          ROLES.organization.owner,
          ROLES.administrator.organizationAdmin,
        ],
        dataTestId: "my-organization-sub-item",
        isActiveForPaths: ["/organization/my/details", "/organization/my/edit"],
      },
      {
        path: "/organization/create",
        label: trans.SIDE_MENU__CREATE_ORGANIZATION_ITEM(),
        allowed: ALL_USERS,
        dataTestId: "create-organization-sub-item",
        isActiveForPaths: ["/organization/create"],
      },
    ],
  },
];
