// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { differenceBy } from "lodash-es";
import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { Info } from "components/feedback";
import { apiThatThrows } from "containers/Request";

import { PREVIEW_COLUMNS, RESULTS_PER_PAGE } from "./constants";
import {
  fetchCategories,
  fetchAutofillApps,
  fetchSelectedApps,
} from "./dataHelpers";
import { PackageInfiniteTable } from "./PackageInfiniteTable";

export const PackageItemsData = ({
  forAdmin,
  packageData,
  searchComponent,
  statusComponent,
}) => {
  const dispatch = useDispatch();

  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const loadedSelectedItems = useRef([]);
  const loadedAutofillItems = useRef([]);
  const autofillAPIPage = useRef(1);

  const autofill = packageData.autofill;
  const selectedItemsIds = packageData.items;

  const getApplicationCategories = useCallback(
    () =>
      dispatch(
        apiThatThrows.getApplicationCategoriesPaginated.action({
          cache: true,
          queryParams: { limit: "nolimit" },
        })
      ),
    [dispatch]
  );

  const getApplicationsPaginated = (queryParams) => {
    if (forAdmin) {
      return dispatch(
        apiThatThrows.getDistributionApplicationsPaginated.action({
          queryParams,
        })
      );
    }
    return dispatch(
      apiThatThrows.getMyDistributionApplicationsPaginated.action({
        queryParams,
      })
    );
  };

  const prepareResult = (data, count) => ({
    results: data,
    meta: {
      count: count,
    },
  });

  /**
   * Fetch selected items paginated data
   * In case of search, load all items first,
   * then perform search and return paginated response
   * @param page
   * @param search
   * @returns {Promise<{meta, results}>}
   */
  const getSelectedItems = async (page, search) => {
    const currentItems = loadedSelectedItems.current,
      startItemIdx = loadedSelectedItems.current.length;
    let result = [];
    const areAllItemsLoaded = !(startItemIdx < page * RESULTS_PER_PAGE);

    if (startItemIdx < selectedItemsIds.length) {
      // SEARCH - load all missing data
      if (search) {
        result = await fetchSelectedApps(
          getApplicationsPaginated,
          selectedItemsIds.slice(startItemIdx, selectedItemsIds.length),
          categories
        );
      }
      // PAGE - load next page of data
      if (!areAllItemsLoaded) {
        result = await fetchSelectedApps(
          getApplicationsPaginated,
          selectedItemsIds.slice(
            startItemIdx,
            Math.min(startItemIdx + RESULTS_PER_PAGE, selectedItemsIds.length)
          ),
          categories
        );
      }
    }
    const newLoadedItems = currentItems.concat(result);
    const searchedItems = newLoadedItems.filter(
      (item) => item.name.search(new RegExp(search, "i")) !== -1
    );

    loadedSelectedItems.current = newLoadedItems;

    return prepareResult(
      searchedItems.slice(
        RESULTS_PER_PAGE * (page - 1),
        RESULTS_PER_PAGE * page
      ),
      search ? searchedItems.length : selectedItemsIds.length
    );
  };

  /**
   * Get paginated autofill items up to the autofill limit
   * Example:
   * if there are 14 predefined items
   * and items per page amount is 10
   * and autofill limit is 20, then:
   * - offset is 4
   * - 1st page returns 6 items, [0-5]
   * - 2nd page returns 10 items, [6-15]
   * - 3rd page returns 4 items, [16-19]
   * @param page
   * @param offset
   * @param search
   * @returns {Promise<{meta, results}>}
   */
  const getAutofillItems = async (page, offset, search) => {
    const start = Math.max(RESULTS_PER_PAGE * (page - 1) - offset, 0);
    const end = Math.min(
      RESULTS_PER_PAGE * page - offset,
      autofill.displayCount
    );
    let loadedItems = loadedAutofillItems.current;
    let apiPage = autofillAPIPage.current;
    let results = false;
    let itemsCount = autofill.displayCount;

    const canDataBeFetched = () => {
      const isEnoughData =
        loadedItems.length >= (search ? autofill.displayCount : end);
      const isApiEmpty = results && results.length === 0;

      return !(isEnoughData || isApiEmpty);
    };

    while (canDataBeFetched()) {
      results = await fetchAutofillApps(
        getApplicationsPaginated,
        apiPage,
        autofill,
        categories
      );

      const newResults = differenceBy(
        results,
        loadedSelectedItems.current,
        (item) => item.id
      );

      loadedItems = loadedItems
        .concat(newResults)
        .slice(0, autofill.displayCount);

      if (!results.length) {
        itemsCount = loadedItems.length; // this will stop pagination
      }
      apiPage++;
    }

    const searchedItems = loadedItems.filter(
      (item) => item.name.search(new RegExp(search, "i")) !== -1
    );

    loadedAutofillItems.current = loadedItems;
    autofillAPIPage.current = apiPage;

    return prepareResult(
      searchedItems.slice(start, end),
      search ? searchedItems.length : itemsCount
    );
  };

  /**
   * Fetch paginated data
   * @param page
   * @param search
   * @returns {Promise<{meta, results}>}
   */
  const fetchData = async ({ page, search }) => {
    const selectedItems = await getSelectedItems(page, search);

    let data = selectedItems.results;
    // we need to add autofill limit, so InfinteData won't stop pagination too soon
    let count =
      selectedItems.meta.count + (autofill ? autofill.displayCount : 0);
    const isAutofillEnabled =
      autofill && autofill.filters && data.length < RESULTS_PER_PAGE;

    if (isAutofillEnabled) {
      const autofillOffset = selectedItems.meta.count % RESULTS_PER_PAGE;
      const autofillPage = Math.ceil(
        (RESULTS_PER_PAGE * page - selectedItems.meta.count) / RESULTS_PER_PAGE
      );

      const autofillItems = await getAutofillItems(
        autofillPage,
        autofillOffset,
        search
      );

      data = data.concat(autofillItems.results);
      // as a result we might get less items than RESULTS_PER_PAGE, but for InfiniteData it does not matter,
      // as it will only ask for as many pages, as it's counted by total amount
      count = selectedItems.meta.count + autofillItems.meta.count;
    }

    return prepareResult(data, count);
  };

  useEffect(() => {
    async function setupCategories() {
      try {
        setCategories(await fetchCategories(getApplicationCategories));
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    }
    setupCategories();
  }, [getApplicationCategories]);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <Info type="error">{error.message}</Info>;
  }

  return (
    <PackageInfiniteTable
      columns={PREVIEW_COLUMNS}
      layout="fixed"
      urlTarget="blank"
      fetchData={fetchData}
      searchComponent={searchComponent}
      statusComponent={statusComponent}
    />
  );
};

PackageItemsData.propTypes = {
  forAdmin: PropTypes.bool.isRequired,
  packageData: PropTypes.object.isRequired,
  searchComponent: PropTypes.func.isRequired,
  statusComponent: PropTypes.func.isRequired,
};
