// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

export const ITEMS_PER_PAGE = 20;

export const LABEL_TYPES = {
  SECTION: "section",
  PACKAGE: "package",
};
