// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component, Fragment } from "react";
import { connect } from "react-redux";

import { Formik, Form } from "formik";
import { isEqual } from "lodash";
import PropTypes from "prop-types";

import { Info } from "components/feedback";
import { FormPagination } from "components/form";
import { Overlay } from "components/layout";
import { getActiveTabIndex } from "components/navigation";
import { api } from "containers/Request";
import { CACHE_REMOVE } from "containers/Request/actionTypes";
import { getKeyInStore } from "containers/Request/getKeyInStore";
import { APP_ROUTE_MY } from "pages/Applications/constants";
import {
  FIELDS,
  TABS,
  MESSAGES,
  INITIAL_VALUES,
} from "pages/Snap/shared/constants";
import { Analytics } from "pages/Snap/SnapEdit/components/Analytics/Analytics";
import { Monetization } from "pages/Snap/SnapEdit/components/Monetization/Monetization";
import { SnapTabs } from "pages/Snap/SnapEdit/components/SnapTabs/SnapTabs";
import { ViewSubmittedDetails } from "pages/Snap/SnapEdit/components/ViewSubmittedDetails";
import { tabsConfig, SnapDetailsComponent } from "pages/Snap/SnapEdit/tabs";
import { snapEditValidation } from "pages/Snap/SnapEdit/validation";
import { trans } from "src/translations";
import { withRouter } from "utils/decorators";

import { DiscardModal } from "./components/DiscardModal/DiscardModal";
import { SnapStatusTree } from "./components/StatusTree/SnapStatusTree";
import { withSnapEditData } from "./withSnapEditData";

@withRouter
@withSnapEditData
@connect(null, (dispatch) => ({
  removeRevisionFromCache: (id) => {
    const endpoint = api.getSnapRevisionsList.path.replace(":id", id);
    dispatch({
      type: CACHE_REMOVE,
      keyInStore: getKeyInStore("GET", endpoint),
    });
  },
}))
export class SnapEditForm extends Component {
  static propTypes = {
    appId: PropTypes.string.isRequired,
    getLatest: PropTypes.func.isRequired,
    discardRevisions: PropTypes.func.isRequired,
    revisions: PropTypes.array,
    storeAppId: PropTypes.string,
    languages: PropTypes.array.isRequired,
    revision: PropTypes.object,
    saveRevision: PropTypes.func.isRequired,

    // from @withSnapEditData
    createCloudApp: PropTypes.func.isRequired,
    updateCloudApp: PropTypes.func.isRequired,
    createCloudDraft: PropTypes.func.isRequired,
    setSubmittedStatus: PropTypes.func.isRequired,

    // from @withRouter
    navigate: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    // from @connect
    removeRevisionFromCache: PropTypes.func.isRequired,
  };

  static defaultProps = {
    revision: null,
    revisions: [],
  };

  state = {
    isSaving: false,
    isDiscarding: false,
    isDiscardModalOpen: false,
    savedDraft: false,
  };

  componentDidMount() {
    const { revision, appId } = this.props;
    this.setState({
      savedDraft:
        revision[appId]["vewd_cloud_version_status"] === "NOT_SUBMITTED",
    });
  }

  static tabs = [
    { url: "content-and-graphics", tabTitle: TABS.CONTENT.title },
    { url: "monetization-and-stats", tabTitle: TABS.MONETIZATION.title },
  ];

  getRevisionParams = async (values, appId) => {
    const { saveRevision, revision: initialValues } = this.props;
    let app_display_url, vewd_cloud_status_url, revision_id, error;

    if (isEqual(initialValues, values[appId])) {
      return ({ app_display_url, vewd_cloud_status_url, revision_id } =
        values[appId]);
    }

    try {
      ({
        result: { app_display_url, vewd_cloud_status_url, revision_id },
        error,
      } = await saveRevision(values));
    } catch (err) {
      error = err;
    }

    return {
      app_display_url,
      vewd_cloud_status_url,
      revision_id,
      error,
    };
  };

  handleSubmit = async (args, { appId, storeAppId }) => {
    const {
      navigate,
      createCloudApp,
      updateCloudApp,
      createCloudDraft,
      setSubmittedStatus,
      removeRevisionFromCache,
    } = this.props;
    const [values, helpers] = args;

    let cloudApp = null;
    try {
      const { app_display_url, vewd_cloud_status_url, revision_id, error } =
        await this.getRevisionParams(values, appId);

      if (error) {
        helpers.setErrors({ _error: MESSAGES.draftCannotSave });
        return;
      }

      if (!storeAppId) {
        cloudApp = await createCloudApp(vewd_cloud_status_url, helpers);
      } else {
        cloudApp = await updateCloudApp(vewd_cloud_status_url, helpers);
      }
      await createCloudDraft(values[appId], cloudApp, app_display_url);
      await setSubmittedStatus(revision_id, appId, cloudApp.id, helpers);
      removeRevisionFromCache(appId);
      navigate(`/applications/${APP_ROUTE_MY}/${cloudApp.id}/edit`);
    } catch (error) {
      helpers.setErrors({
        _error: error?.message ?? MESSAGES.genericError,
      });
    }
  };

  discardDraft = async ({ resetForm, setErrors }) => {
    this.setState({ isDiscarding: true });
    const { discardRevisions } = this.props;
    const { response, error } = await discardRevisions();
    if (error) {
      this.setState({
        isDiscarding: false,
        isDiscardModalOpen: false,
      });
    } else {
      await this.reInitializeForm(resetForm, setErrors, response);
      this.setState({
        isDiscarding: false,
        isDiscardModalOpen: false,
        savedDraft: false,
      });
    }
  };

  redirectToInvalidTab = (errors) => {
    const { appId, navigate, params } = this.props;

    Object.keys(tabsConfig).some((tab) => {
      const { fields } = tabsConfig[tab];
      if (!errors[appId]) {
        return false;
      }
      const invalid = Object.keys(errors[appId]).some((field) =>
        fields.includes(field)
      );

      if (invalid) {
        navigate(`/snap/${params.id}/edit/${tab}`);
        return true;
      }
      return false;
    });
  };

  handleDraft = async ({ resetForm, setErrors, values, errors }) => {
    const { saveRevision, appId } = this.props;
    let error;
    try {
      this.setState({ isSaving: true });
      ({ error } = await saveRevision(values));
    } catch (err) {
      error = { _error: err };
    } finally {
      this.setState({ isSaving: false });
      if (error) {
        const errMsg =
          error?._error || !errors?.[appId]
            ? MESSAGES.draftError
            : MESSAGES.draftCannotSave;
        setErrors({
          _error: errMsg,
        });
      } else {
        await this.reInitializeForm(resetForm, setErrors);
      }
    }
  };

  getLink(tabId) {
    const { params } = this.props;
    return `/snap/${params.id}/edit/${tabId}`;
  }

  reInitializeForm = async (resetForm, setErrors, response) => {
    const { getLatest, appId, revision: initialValues } = this.props;
    let latestRevision;
    if (response?.length === 0) {
      resetForm({
        values: { [appId]: { ...INITIAL_VALUES } },
      });
      return;
    }
    try {
      latestRevision = await getLatest();
      resetForm({
        values: { [appId]: { ...initialValues[appId], ...latestRevision } },
      });
    } catch (error) {
      setErrors({
        _error: error?.message || MESSAGES.submitErrorNotValid,
      });
      return;
    }
    if (latestRevision["vewd_cloud_version_status"] === "NOT_SUBMITTED") {
      this.setState({ savedDraft: true });
    }
  };

  openDiscardModal = () => {
    this.setState({ isDiscardModalOpen: true });
  };

  closeDiscardModal = () => {
    this.setState({ isDiscardModalOpen: false });
  };

  getActiveTabIndex() {
    const { params } = this.props;
    return getActiveTabIndex(params, "tabId", SnapEditForm.tabs);
  }

  checkErrorsBeforeSubmit(formikProps) {
    formikProps.validateForm().then((errors) => {
      if (Object.keys(errors).length > 0) {
        formikProps.setStatus(true);
        this.redirectToInvalidTab(errors);
      }
      formikProps.handleSubmit();
    });
  }

  renderErrors(formikProps) {
    const showError = formikProps.errors?._error;

    const showSubmitError =
      Boolean(formikProps.status) &&
      Object.keys(formikProps.errors).length &&
      !formikProps.dirty;

    if (showError || showSubmitError) {
      return (
        <Info type="error">{showError || MESSAGES.submitErrorNotValid}</Info>
      );
    }

    return null;
  }

  render() {
    const { appId, storeAppId, revisions, revision, languages } = this.props;
    const { isDiscardModalOpen, isDiscarding, savedDraft, isSaving } =
      this.state;
    const activeTabIndex = this.getActiveTabIndex();
    return (
      <Fragment>
        <ViewSubmittedDetails
          storeAppId={storeAppId}
          revisionsList={revisions}
        />
        <Formik
          initialValues={{
            [appId]: {
              ga_tracker_id: "",
              vt_type: undefined,
              ...revision[appId],
            },
          }}
          onSubmit={(...args) => this.handleSubmit(args, { appId, storeAppId })}
          validate={(values) => snapEditValidation(values, appId)}
          validateOnBlur={true}
          validateOnChange={true}
        >
          {(formikProps) => {
            return (
              <Form aria-label="form">
                <Overlay
                  message={trans.SUBMITTING()}
                  active={formikProps.isSubmitting}
                >
                  {this.renderErrors(formikProps)}
                  <SnapStatusTree
                    key={formikProps.values[appId]["revision_id"]}
                    discardDraft={this.openDiscardModal}
                  />
                  <SnapTabs
                    appId={this.props.appId}
                    activeTabIndex={activeTabIndex}
                    errors={formikProps.errors[appId]}
                  />

                  {activeTabIndex === 0 && (
                    <>
                      <SnapDetailsComponent
                        appId={appId}
                        languages={languages}
                      />
                      {this.renderErrors(formikProps)}
                    </>
                  )}
                  {activeTabIndex === 1 && (
                    <>
                      <Analytics
                        name={`${appId}.${FIELDS.ANALYTICS.name}`}
                        dataTestId="google-analytics-id-field"
                        label={FIELDS.ANALYTICS.label}
                      />
                      <Monetization formId={appId} />
                    </>
                  )}

                  <FormPagination
                    activeTab={activeTabIndex}
                    dirty={formikProps.dirty}
                    discarding={isDiscarding}
                    saving={isSaving}
                    submitting={formikProps.isSubmitting}
                    onDiscard={savedDraft ? this.openDiscardModal : undefined}
                    onPrev={() =>
                      this.getLink(SnapEditForm.tabs[activeTabIndex - 1].url)
                    }
                    onNext={() =>
                      this.getLink(SnapEditForm.tabs[activeTabIndex + 1].url)
                    }
                    onSave={() => this.handleDraft(formikProps)}
                    onSubmit={() => this.checkErrorsBeforeSubmit(formikProps)}
                    tabCount={SnapEditForm.tabs.length}
                    tabTitle={SnapEditForm.tabs[activeTabIndex].tabTitle}
                  />

                  <DiscardModal
                    discardRevision={() => this.discardDraft(formikProps)}
                    isDiscarding={isDiscarding}
                    onClose={this.closeDiscardModal}
                    isDiscardModalOpen={isDiscardModalOpen}
                  />
                </Overlay>
              </Form>
            );
          }}
        </Formik>
      </Fragment>
    );
  }
}
