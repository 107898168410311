// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { FieldArray, useField } from "formik";
import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { TableRow, TableRowColumn } from "components/layout";
import { trans } from "src/translations";
import { reorderFields } from "utils/formik/utils";

import { isSectionValid } from "../../utils/validate";
import { StructureItem } from "../_shared/StructureItem";
import { PROVIDER_ID, STRUCTURE_TYPES, FIELDS } from "../constants";
import { Packages, PackagesLockedScreen } from "../Packages";
import { ProviderRow } from "./ProviderRow";
import styles from "./SectionRow.scss";

export const SectionRow = (props) => {
  const { onCreate, onEdit, sectionFieldName } = props;
  const [{ value: section }] = useField(sectionFieldName);
  const selectedProvider = section[FIELDS.PROVIDER_FIELD_NAME];

  const renderPackages = (move, remove) => {
    if (selectedProvider !== PROVIDER_ID.CLOUD) {
      return (
        <PackagesLockedScreen
          onEdit={onEdit}
          sectionFieldName={sectionFieldName}
        />
      );
    }

    return (
      <Packages
        sectionFieldName={sectionFieldName}
        onMove={(index, direction) => {
          reorderFields(section.packages, index, direction, move);
        }}
        onEdit={onEdit}
        onRemove={remove}
      />
    );
  };

  return (
    <StructureItem
      {...props}
      fieldName={sectionFieldName}
      type={STRUCTURE_TYPES.SECTION}
      validate={isSectionValid}
    >
      <FieldArray
        name={`${sectionFieldName}.${FIELDS.PACKAGE_FIELD_NAME}`}
        render={({ move, remove }) => (
          <>
            <ProviderRow
              selectedProvider={selectedProvider}
              onEdit={onEdit}
              sectionsFieldName={sectionFieldName}
              section={section}
            />
            {renderPackages(move, remove)}
            <TableRow className={styles.noBorder} highlighting={false}>
              <TableRowColumn className={styles.padded}>
                <Button
                  type="normal"
                  disabled={selectedProvider !== PROVIDER_ID.CLOUD}
                  className={styles.addNewPackageButton}
                  onClick={() => {
                    onCreate(STRUCTURE_TYPES.PACKAGE);
                  }}
                >
                  {trans.STRUCTURES_CREATOR__ADD_NEW_PACKAGE()}
                </Button>
              </TableRowColumn>
            </TableRow>
          </>
        )}
      />
    </StructureItem>
  );
};

SectionRow.propTypes = {
  isValid: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  sectionFieldName: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
};
