// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Label } from "components/form";
import { trans } from "src/translations";

import { PROVIDERS } from "../constants";
import styles from "./ProviderInfoBox.scss";

export const ProviderInfoBox = ({ selectedProvider }) => {
  const providerName = selectedProvider && PROVIDERS[selectedProvider].name;
  return (
    <div className={styles.wrapper}>
      <Label
        text={`${trans.STRUCTURES_PACKAGE__SECTION()}:`}
        className={styles.wrapper}
        required
      >
        <input className={styles.input} value={providerName} disabled />
      </Label>
    </div>
  );
};

ProviderInfoBox.propTypes = {
  selectedProvider: PropTypes.string,
};
