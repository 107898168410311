// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useEffect, useState } from "react";

import { useMutation, useLazyQuery } from "@apollo/client";
import { useFormikContext } from "formik";

import { DEFAULT_APOLLO_OPTIONS } from "utils/graphql/constants";

import { createPackageLabelGql } from "../../Labels/gql/createPackageLabel";
import { createSectionLabelGql } from "../../Labels/gql/createSectionLabel";
import { editPackageLabelGql } from "../../Labels/gql/editPackageLabel";
import { editSectionLabelGql } from "../../Labels/gql/editSectionLabel";
import sectionProvidersQuery from "../gql/sectionProviders";
import { STRUCTURE_TYPES, FIELDS } from "./constants";
import { TabStructureCreator } from "./TabStructureCreator";

export const TabStructureCreatorData = () => {
  const formikProps = useFormikContext();
  const [createSectionLabelMutation] = useMutation(createSectionLabelGql);
  const [editSectionLabelMutation] = useMutation(editSectionLabelGql);
  const [editPackageLabelMutation] = useMutation(editPackageLabelGql);
  const [createPackageLabelMutation] = useMutation(createPackageLabelGql);
  const [sectionProvidersQueryFunction, sectionProvidersQueryStatus] =
    useLazyQuery(sectionProvidersQuery, {
      ...DEFAULT_APOLLO_OPTIONS,
    });

  const [fieldState, setFieldState] = useState({
    fieldName: undefined,
    labelType: undefined,
    currentLabelItem: undefined,
  });
  const { fieldName, labelType, currentLabelItem } = fieldState;

  const isSectionLabel = () => {
    return labelType === STRUCTURE_TYPES.SECTION;
  };

  const handleEditLabel = async (translations) => {
    if (isSectionLabel()) {
      const result = await editSectionLabelMutation({
        variables: {
          input: { sectionLabelId: currentLabelItem.id, translations },
        },
      });
      return result.data.editSectionLabel.sectionLabel.translations.find(
        ({ isDefault }) => isDefault
      ).translation;
    }
    const result = await editPackageLabelMutation({
      variables: {
        input: { packageLabelId: currentLabelItem.id, translations },
      },
    });
    return result.data.editPackageLabel.packageLabel.translations[0]
      .translation;
  };

  const handleCreateLabel = async (values) => {
    if (isSectionLabel()) {
      const result = await createSectionLabelMutation({
        variables: { input: { translations: values } },
      });
      const sectionLabel = result.data.createSectionLabel.sectionLabel;
      return {
        name: sectionLabel.translations[0].translation,
        id: sectionLabel.id,
      };
    }
    const result = await createPackageLabelMutation({
      variables: { input: { translations: values } },
    });
    const packageLabel = result.data.createPackageLabel.packageLabel;
    return {
      name: packageLabel.translations[0].translation,
      id: packageLabel.id,
    };
  };

  const updateLabel = ({ name, id, translations }) => {
    if (name) {
      formikProps.setFieldValue(
        `${fieldName}.${FIELDS.SECTION_NAME_FIELD_NAME}`,
        name
      );
      formikProps.setFieldValue(
        `${fieldName}.${FIELDS.TRANSLATIONS_FIELD_NAME}`,
        translations
      );
    }
    if (id && isSectionLabel()) {
      formikProps.setFieldValue(
        `${fieldName}.${FIELDS.SECTION_LABEL_FIELD_NAME}`,
        id
      );
    }
    if (id && !isSectionLabel()) {
      formikProps.setFieldValue(
        `${fieldName}.${FIELDS.PACKAGE_LABEL_FIELD_NAME}`,
        id
      );
    }
  };

  useEffect(() => {
    sectionProvidersQueryFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const availableProviders =
    sectionProvidersQueryStatus.data?.sectionProviders?.availableProviders ??
    [];

  return (
    <TabStructureCreator
      handleEditLabel={handleEditLabel}
      handleCreateLabel={handleCreateLabel}
      updateLabel={updateLabel}
      availableProviders={availableProviders}
      setFieldState={setFieldState}
      isSectionLabel={isSectionLabel}
      {...fieldState}
    />
  );
};
