// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useState } from "react";

import { FieldArray, Field, Form, Formik } from "formik";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { Radio } from "components/form/elements";
import { SelectField, TextField } from "components/form/fields";
import { GrabsonIcon } from "components/icons";
import {
  Table,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableRow,
  TableRowColumn,
  TableRowColumnExtended,
  TableNoResults,
  Row,
  Column,
} from "components/layout";
import { Tooltip } from "components/popups";
import { trans } from "src/translations";
import { sortLanguages } from "utils/translations";

import {
  TransCreatePropTypes,
  TransEditPropTypes,
  LabelType,
} from "../../Labels/propTypes";
import {
  PROVIDER_ID,
  PROVIDERS,
} from "../../StructureForm/TabStructureCreator/constants";
import { ProviderTooltip } from "../../StructureForm/TabStructureCreator/Sections/ProviderTooltip";
import {
  CREATE_NEW_MODE,
  CREATE_COPY_MODE,
  CREATE_MODE,
  EDIT_MODES,
  NEW_TRANSLATION_INITIAL_VALUES,
  SECTION,
  SELECT_LABEL_FIELDS_OPTIONS,
} from "./constants";
import { LabelToCopyField } from "./fields";
import styles from "./LabelModal.scss";
import { LabelModalContent } from "./LabelModalContent";

const renderSelectField = ({ options, value, handleChange }) => (
  <SelectField
    name="edit-label-name-select"
    className={styles.selectLabel}
    label={trans.STRUCTURES_SECTION_LABEL_MODAL__LABEL_DROPDOWN()}
    labelPosition="top"
    values={options}
    value={value}
    onChange={handleChange}
    required={false}
  />
);

const LabelModalModeType = PropTypes.oneOf([
  CREATE_MODE,
  CREATE_NEW_MODE,
  CREATE_COPY_MODE,
  ...Object.values(EDIT_MODES),
]);

const RenderTranslations = ({
  languages,
  loading,
  texts,
  name,
  ...formikHelpers
}) => {
  const {
    form: { getFieldMeta, setFieldValue },
    push,
    remove,
  } = formikHelpers;

  const { value: fields } = getFieldMeta(name);
  const extractLanguageCode = (translation) => translation.languageCode;

  return (
    <div>
      <div>
        <Table>
          <TableHeader>
            <TableHeaderColumn>
              {trans.DEFAULT()}
              <Tooltip
                content={trans.STRUCTURES_LABEL_MODAL__COLUMN_DEFAULT_TOOLTIP()}
              >
                &nbsp;(?)
              </Tooltip>
            </TableHeaderColumn>
            <TableHeaderColumn>{texts.translationsHeader()}</TableHeaderColumn>
            <TableHeaderColumn colspan={2} />
          </TableHeader>
          {loading ? (
            <TableNoResults loading={loading} />
          ) : (
            <TableBody>
              {(fields ?? []).map((translation, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableRow key={index} highlighting={false}>
                  <TableRowColumn>
                    <Field name={`${name}[${index}].isDefault`}>
                      {({ field }) => (
                        <Radio
                          {...field}
                          value={field.value ? `${index}` : null}
                          defaultValue={`${index}`}
                          onChange={(e) => {
                            const newDefault = fields.map((field, idx) => ({
                              ...field,
                              isDefault: idx === parseInt(e.target.value, 10),
                            }));
                            setFieldValue(name, newDefault);
                          }}
                          dataTestId="label-modal-default-translation-radio"
                        />
                      )}
                    </Field>
                  </TableRowColumn>
                  <TableRowColumn>
                    <SelectField
                      name={`${name}[${index}].languageCode`}
                      className={styles.selectWrapper}
                      dataTestId="label-modal-select-language"
                      required={true}
                      initOption={{ value: "", text: trans.CHOOSE_LANGUAGE() }}
                      excluded={fields.map(extractLanguageCode)}
                      values={sortLanguages(languages).map((language) => ({
                        value: language.code,
                        text: language.eng_name,
                      }))}
                      look="simple"
                    />
                  </TableRowColumn>
                  <TableRowColumn>
                    <TextField
                      name={`${name}[${index}].translation`}
                      dataTestId="label-modal-translation-name"
                      className={styles.inputWrapper}
                      placeholder={trans.STRUCTURES_LABEL_MODAL__TRANSLATION_NAME_PLACEHOLDER()}
                      look="simple"
                    />
                  </TableRowColumn>
                  <TableRowColumnExtended
                    className={styles.removeIcon}
                    fontIcon="close-filled"
                    onClick={() => {
                      if (fields.length > 1) {
                        remove(index);
                      }
                    }}
                    fontIconClassName={styles.removeTranslationBtn}
                  />
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </div>
      <ButtonsWrapper position="left">
        <Button
          type="normal"
          onClick={() => push(NEW_TRANSLATION_INITIAL_VALUES)}
        >
          {trans.STRUCTURES_LABEL_MODAL__ADD_TRANSLATION_BTN()}
        </Button>
      </ButtonsWrapper>
    </div>
  );
};

RenderTranslations.propTypes = {
  languages: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  texts: PropTypes.oneOfType([TransEditPropTypes, TransCreatePropTypes])
    .isRequired,
};

const editModeModalContent = ({
  languages,
  texts,
  labelType,
  mode,
  loading,
}) => {
  switch (mode) {
    case EDIT_MODES.EDIT:
      return (
        <FieldArray
          name="translations"
          render={(arrayHelpers) => (
            <RenderTranslations
              languages={languages}
              loading={loading}
              texts={texts}
              {...arrayHelpers}
            />
          )}
        />
      );
    case EDIT_MODES.REPLACE_NEW:
      return (
        <FieldArray
          name="translationsNew"
          render={(arrayHelpers) => (
            <RenderTranslations
              languages={languages}
              loading={loading}
              texts={texts}
              {...arrayHelpers}
            />
          )}
        />
      );
    case EDIT_MODES.REPLACE_EXISTING:
      return (
        <LabelToCopyField
          texts={texts}
          labelType={labelType}
          languages={languages}
        />
      );
    default:
      return null;
  }
};

export const LabelModalForm = ({
  selectedProvider,
  availableProviders,
  labelType,
  languages,
  texts,
  loading,
  onChangeMode,
  fieldName,
  mode,
  validate,
  onClose,
  onSubmit,
  initialValues,
}) => {
  const [state, setState] = useState({
    selectedLabelAction:
      mode === CREATE_MODE ? CREATE_NEW_MODE : EDIT_MODES.EDIT,
    selectedProvider,
    excludedLanguages: [],
    excludedLanguagesReplace: [],
  });

  const mergeState = (partialState) => {
    setState((prevState) => ({
      ...prevState,
      ...partialState,
    }));
  };

  const onSelectedProviderChange = (event) => {
    mergeState({ selectedProvider: event.target.value });
  };

  const isCreateMode = () =>
    [CREATE_MODE, CREATE_NEW_MODE, CREATE_COPY_MODE].includes(mode);

  const handleChangeMode = (event) => {
    mergeState({ selectedLabelAction: event.target.value });
    onChangeMode(event);
  };

  const hasProviderSection = labelType === SECTION && selectedProvider;

  const getProviderValues = (providers) => [
    { label: PROVIDERS[PROVIDER_ID.CLOUD].name, value: PROVIDER_ID.CLOUD },
    ...providers.map(({ id }) => ({ label: PROVIDERS[id].name, value: id })),
  ];
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={(values, helpers) => onSubmit(values, helpers)}
      validate={validate}
    >
      {(formikProps) => (
        <>
          <Form aria-label="form">
            <Row className={styles.modalWrapper}>
              {hasProviderSection && (
                <Column className={styles.providerColumn}>
                  <SelectField
                    name="providerField"
                    className={styles.select}
                    values={getProviderValues(availableProviders)}
                    label={trans.STRUCTURES_PACKAGE__SELECT_PROVIDER()}
                    labelPosition="top"
                    dataTestId="select-provider"
                    onChange={(e) => {
                      onSelectedProviderChange(e);
                      formikProps.handleChange(e);
                    }}
                  />
                  <div>
                    <GrabsonIcon
                      className={styles.icon}
                      name="ok-filled"
                      size="lg"
                    />
                    <h4 className={styles.descriptionHeader}>
                      {trans.DESCRIPTION()}
                    </h4>
                  </div>
                  <p className={styles.providerDescription}>
                    {PROVIDERS[state.selectedProvider].info}
                  </p>
                  <ProviderTooltip
                    selectedProvider={state.selectedProvider}
                    className={styles.providerDescriptionTooltip}
                    alignment="bottom"
                    fullInfo={false}
                  >
                    {<GrabsonIcon name="details" font="micro" />}
                    {trans.READ_MORE()}
                  </ProviderTooltip>
                </Column>
              )}
              <Column className={styles.labelsColumn}>
                {isCreateMode() ? (
                  <LabelModalContent
                    withReplaceOptions={true}
                    selectLabelActionField={renderSelectField({
                      options:
                        SELECT_LABEL_FIELDS_OPTIONS.createLabelSelectTabsField,
                      value: mode,
                      handleChange: handleChangeMode,
                    })}
                    selectedLabelAction={state.selectedLabelAction}
                  >
                    {mode === CREATE_COPY_MODE ? (
                      <LabelToCopyField
                        texts={texts}
                        labelType={labelType}
                        languages={languages}
                      />
                    ) : (
                      <FieldArray
                        name="translations"
                        render={(arrayHelpers) => (
                          <RenderTranslations
                            languages={languages}
                            loading={loading}
                            texts={texts}
                            {...arrayHelpers}
                          />
                        )}
                      />
                    )}
                  </LabelModalContent>
                ) : (
                  <LabelModalContent
                    withReplaceOptions={fieldName !== undefined}
                    selectLabelActionField={renderSelectField({
                      options:
                        SELECT_LABEL_FIELDS_OPTIONS.editLabelSelectTabsField,
                      value: mode,
                      handleChange: handleChangeMode,
                    })}
                    selectedLabelAction={state.selectedLabelAction}
                  >
                    {editModeModalContent({
                      mode,
                      loading,
                      languages,
                      texts,
                      labelType,
                    })}
                  </LabelModalContent>
                )}
              </Column>
            </Row>
            {formikProps.errors?.requestError && (
              <Info type="error">{formikProps.errors.requestError}</Info>
            )}
          </Form>
          <ButtonsWrapper>
            <Button
              type="normal"
              onClick={() => onClose(formikProps)}
              disabled={formikProps.isSubmitting}
              dataTestId={`${
                isCreateMode() ? "create" : "edit"
              }-label-cancel-button`}
            >
              {trans.CANCEL()}
            </Button>
            <Button
              type="green"
              onClick={formikProps.handleSubmit}
              disabled={formikProps.isSubmitting || !formikProps.dirty}
              processing={formikProps.isSubmitting}
              dataTestId={`${
                isCreateMode() ? "create" : "edit"
              }-label-done-button`}
            >
              {trans.DONE()}
            </Button>
          </ButtonsWrapper>
        </>
      )}
    </Formik>
  );
};

LabelModalForm.propTypes = {
  fieldName: PropTypes.string,
  mode: LabelModalModeType.isRequired,
  labelType: LabelType,
  texts: PropTypes.oneOfType([TransEditPropTypes, TransCreatePropTypes])
    .isRequired,
  languages: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  onChangeMode: PropTypes.func,
  availableProviders: PropTypes.array,
  selectedProvider: PropTypes.string,
  initialValues: PropTypes.object.isRequired,
  validate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
