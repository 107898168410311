// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useState } from "react";

import { Form, useFormikContext } from "formik";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { filterSectionPropTypes } from "components/elements/SearchBarWithFilters/propTypes";
import { RadioGroupField } from "components/form/fields";
import { tableColPropType } from "components/layout";
import { HtmlTranslation, trans } from "src/translations";

import { FIELD_TYPE_AUTO } from "../../constants";
import { FIELDS } from "./constants";
import { AppsListFields, AppsListSearchField } from "./fields";
import styles from "./PackageItemsModals.scss";

export const EditPackageItemsForm = ({
  availableFilters,
  columns,
  fetchData,
  getSuggestions,
  onClose,
}) => {
  const { dirty, isSubmitting, submitForm, values } = useFormikContext();
  const [loadingAllApps, setLoadingAllApps] = useState(false);

  const {
    [FIELDS.appListType.name]: appsListType,
    [FIELDS.filters.name]: filters,
    [FIELDS.volatileFilters.name]: volatileFilters,
  } = values;

  const isAutogenerated = appsListType === FIELD_TYPE_AUTO;
  const labeledColumns = isAutogenerated
    ? columns.filter((col) => col.id !== "select")
    : columns;
  const initialSearchQuery = isAutogenerated ? filters : volatileFilters;

  const renderSearchField = (onSearch) => {
    const name = isAutogenerated
      ? FIELDS.filters.name
      : FIELDS.volatileFilters.name;
    return (
      <AppsListSearchField
        name={name}
        availableFilters={availableFilters}
        getSuggestions={getSuggestions}
        onSearch={onSearch}
      />
    );
  };

  return (
    <>
      <Form className={styles.form}>
        <RadioGroupField {...FIELDS.appListType} disabled={loadingAllApps} />
        <HtmlTranslation
          className={styles.autofillMessage}
          component="p"
          transFunc={
            appsListType === FIELD_TYPE_AUTO
              ? trans.STRUCTURES_MODAL__PACKAGE_AUTOFILL_INFO
              : trans.STRUCTURES_MODAL__PACKAGE_APPS_INFO
          }
        />
        <AppsListFields
          {...FIELDS.selectedApps}
          autogenerated={isAutogenerated}
          columns={labeledColumns}
          initialSearch={initialSearchQuery}
          loadingAll={loadingAllApps}
          fetchData={fetchData}
          onLoadingAllChange={setLoadingAllApps}
          searchComponent={renderSearchField}
        />
      </Form>
      <ButtonsWrapper>
        <Button
          disabled={isSubmitting}
          type="normal"
          onClick={onClose}
          dataTestId="edit-package-cancel-button"
        >
          {trans.CANCEL()}
        </Button>
        <Button
          disabled={!dirty || loadingAllApps || isSubmitting}
          processing={isSubmitting}
          type="green"
          onClick={submitForm}
          dataTestId="edit-package-add-button"
        >
          {trans.DONE()}
        </Button>
      </ButtonsWrapper>
    </>
  );
};

EditPackageItemsForm.propTypes = {
  availableFilters: PropTypes.shape({
    sections: PropTypes.arrayOf(filterSectionPropTypes).isRequired,
  }).isRequired,
  columns: PropTypes.arrayOf(tableColPropType).isRequired,
  fetchData: PropTypes.func.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
