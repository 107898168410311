// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useEffect, useState, useRef, useContext } from "react";
import { useDispatch } from "react-redux";

import { useField } from "formik";
import { get, orderBy, sortBy } from "lodash-es";
import PropTypes from "prop-types";

import { apiThatThrows } from "containers/Request";

import {
  fetchSelectedApps,
  fetchCategories,
} from "../../../Modals/PackageItems/dataHelpers";
import { ForAdminContext } from "../../utils/context";
import {
  AUTOFILL_LENGTH_VALUES,
  AUTOFILL_SORT_VALUES,
  PACKAGE_ITEMS_SORT,
  FIELDS,
} from "../constants";
import { PackageItems } from "./PackageItems";

const sortAlphabetically = (applications) => sortBy(applications, ["name"]);

const sortNewest = (applications) =>
  orderBy(applications, ["first_approved_date"], ["desc"]);

const useAppsGetters = () => {
  const forAdmin = useContext(ForAdminContext);
  const dispatch = useDispatch();

  return {
    getApplicationCategories: () =>
      dispatch(
        apiThatThrows.getApplicationCategoriesPaginated.action({
          cache: true,
          queryParams: { limit: "nolimit" },
        })
      ),

    getApplicationsPaginated: (queryParams) => {
      if (forAdmin) {
        return dispatch(
          apiThatThrows.getDistributionApplicationsPaginated.action({
            queryParams,
          })
        );
      }
      return dispatch(
        apiThatThrows.getMyDistributionApplicationsPaginated.action({
          queryParams,
        })
      );
    },
  };
};

export const PackageItemsData = ({
  packageFieldName,
  ...packageItemsArrayHelpers
}) => {
  const firstUpdate = useRef(true);

  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getApplicationCategories, getApplicationsPaginated } =
    useAppsGetters();

  const [{ value }] = useField(packageFieldName);
  const { packageItemsOrderBy, packageItems, autofillPackageItems } = value;
  const { form, move, remove } = packageItemsArrayHelpers;

  const setPackageItems = (packageItems) =>
    form.setFieldValue(
      `${packageFieldName}.${FIELDS.PACKAGE_ITEM_FIELD_NAME}`,
      packageItems
    );

  const setPackageItemsSort = (sortOption) =>
    form.setFieldValue(
      `${packageFieldName}.${FIELDS.ORDERBY_FIELD_NAME}`,
      sortOption
    );

  const setAutofill = (autofillOptions) =>
    form.setFieldValue(
      `${packageFieldName}.${FIELDS.AUTOFILL_FIELD_NAME}`,
      autofillOptions
    );

  const removeAutofill = () =>
    form.setFieldValue(
      `${packageFieldName}.${FIELDS.AUTOFILL_FIELD_NAME}`,
      null
    );

  const addAutofill = (filters) => {
    const count = AUTOFILL_LENGTH_VALUES.find((item) => item.isDefault).value;
    const order = AUTOFILL_SORT_VALUES.find((item) => item.isDefault).value;
    const newFilters = autofillPackageItems || {
      displayCount: count,
      orderBy: order,
      filters: {},
    };
    newFilters.filters = filters;
    setAutofill(newFilters);
  };

  const sortItems = (detailedItems, orderBy) => {
    let sortedItems = detailedItems;
    if (orderBy === PACKAGE_ITEMS_SORT.ALPHABETICALLY) {
      sortedItems = sortAlphabetically(detailedItems);
    } else if (orderBy === PACKAGE_ITEMS_SORT.NEWEST) {
      sortedItems = sortNewest(detailedItems);
    }
    return sortedItems.map(({ id }) => ({
      id,
    }));
  };

  const updateAppDetails = async (packageItems) => {
    try {
      const categories = await fetchCategories(getApplicationCategories);

      const applications = await fetchSelectedApps(
        getApplicationsPaginated,
        packageItems,
        categories
      );

      setLoading(false);
      setApplications(applications);
      return applications;
    } catch (error) {
      setLoading(false);
      setError(error);
    }
    return null;
  };

  const addItems = async (selectedItems) => {
    const items = [...packageItems];
    setLoading(true);

    selectedItems.forEach((id) => {
      if (!items.find((item) => item.id === id)) {
        items.push({ id: id });
      }
    });
    const detailedItems = await updateAppDetails(items);
    const sortedPackageItems = sortItems(detailedItems, packageItemsOrderBy);
    setPackageItems(sortedPackageItems, packageFieldName);
  };

  const getAllAppsDetails = () => {
    return packageItems.map((item) =>
      applications.find((app) => app.id === item.id)
    );
  };

  const handleChangeSort = () => {
    const sortedPackageItems = sortItems(
      getAllAppsDetails(),
      packageItemsOrderBy
    );
    setPackageItems(sortedPackageItems);
  };

  const handleMove = ({ source, destination }) => {
    if (!destination) {
      return;
    }
    move(source.index, destination.index);
    setPackageItemsSort(PACKAGE_ITEMS_SORT.CUSTOM);
  };

  const isValid = () =>
    !get(form.errors, `${packageFieldName}.${FIELDS.PACKAGE_ITEM_FIELD_NAME}`);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    handleChangeSort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageItemsOrderBy]);

  useEffect(() => {
    updateAppDetails(packageItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PackageItems
      packageFieldName={packageFieldName}
      removePackageItem={remove}
      removeAutofill={removeAutofill}
      addAutofill={addAutofill}
      addItems={addItems}
      handleMove={handleMove}
      applications={applications}
      isValid={isValid}
      loading={loading}
      error={error}
    />
  );
};

PackageItemsData.propTypes = {
  packageFieldName: PropTypes.string.isRequired,
};
