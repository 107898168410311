// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component, Fragment } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { SearchableListPanel, Loader } from "components/elements";
import { Radio } from "components/form/elements";
import { GrabsonIcon } from "components/icons";
import { Column, Row } from "components/layout";
import { trans } from "src/translations";
import { getGqlErrorMessage } from "utils/errors";
import { withQuery } from "utils/graphql";

import { packageLabelGql } from "../../../../Labels/gql/packageLabel";
import { sectionLabelGql } from "../../../../Labels/gql/sectionLabel";
import { SECTION } from "../../constants";
import styles from "../../LabelModal.scss";

@withQuery({ name: "sectionLabel", query: sectionLabelGql })
@withQuery({ name: "packageLabel", query: packageLabelGql })
export class TranslationRow extends Component {
  static propTypes = {
    label: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    languages: PropTypes.array.isRequired,
    labelType: PropTypes.string.isRequired,
    // from @formik
    field: PropTypes.object.isRequired,
    setValue: PropTypes.func.isRequired,
    setTouched: PropTypes.func.isRequired,
    // from @withQuery
    sectionLabelQuery: PropTypes.func,
    packageLabelQuery: PropTypes.func,
  };

  state = {
    isExpanded: false,
    loading: false,
    translations: null,
    error: null,
  };

  onTranslationsToggle = async (id) => {
    const { translations, isExpanded } = this.state;
    const { sectionLabelQuery, packageLabelQuery, languages, labelType } =
      this.props;
    const isSection = labelType === SECTION;

    if (!translations && !isExpanded) {
      this.setState({
        loading: true,
        isExpanded: true,
      });

      try {
        const query = isSection ? sectionLabelQuery : packageLabelQuery;
        const labelType = isSection ? "sectionLabel" : "packageLabel";

        const labelResponse = await query({
          variables: { [`${labelType}Id`]: id },
        });

        const translations = get(
          labelResponse,
          `${labelType}.${labelType}.translations`
        );
        const formattedTranslations = translations.map(
          ({ translation, language }) => ({
            id,
            translation,
            language: languages.find((lang) => lang.code === language.code)
              .eng_name,
          })
        );

        this.setState({
          translations: formattedTranslations,
          loading: false,
        });
      } catch (e) {
        this.setState({
          error: getGqlErrorMessage(e),
          translations: null,
          loading: false,
          isExpanded: true,
        });
      }
    } else {
      this.setState((prevState) => ({
        isExpanded: !prevState.isExpanded,
        error: null,
      }));
    }
  };

  renderTranslations() {
    const { translations, loading, error } = this.state;

    if (error) {
      return <p className={styles.error}>{error}</p>;
    } else if (loading && !translations) {
      return (
        <Loader
          className={styles.loader}
          size="small"
          message={`${trans.LOADING()}...`}
        />
      );
    }

    return (
      <Column>
        {translations.map(({ translation, language, id }) => (
          <Row key={`${id}${language}`} className={styles.translationRow}>
            <span>{`${language}:`}</span>
            <span>{translation}</span>
          </Row>
        ))}
      </Column>
    );
  }

  render() {
    const { field, setValue, setTouched, label, index } = this.props;
    const { isExpanded } = this.state;

    const checkedLabelId = field?.value?.id ?? false;
    const name = `copy-label-${index}`;

    return (
      <Fragment>
        <SearchableListPanel className={styles.labelRow}>
          <Radio
            name={name}
            dataTestId={name}
            value={checkedLabelId}
            defaultValue={label.id}
            onChange={() => {
              setTouched();
              setValue({ id: label.id, name: label.name });
            }}
            className={styles.labelRowCheckbox}
          />
          <Column
            className={styles.sectionLabel}
            onClick={() => {
              this.onTranslationsToggle(label.id);
            }}
          >
            <GrabsonIcon
              name={isExpanded ? "arrow-up" : "arrow-down"}
              size="sm"
              font="micro"
              className={styles.toggleIcon}
            />
            <span className={styles.labelName}>{label.name}</span>
          </Column>
          <div className={styles.usedCount}>
            {trans.STRUCTURES__USE_AMOUNT({ count: label.useCount })}
          </div>
        </SearchableListPanel>
        {isExpanded && this.renderTranslations()}
      </Fragment>
    );
  }
}
