// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { TableRow, TableRowColumn } from "components/layout";
import { ActionMenu, MenuItem } from "components/popups";
import { trans } from "src/translations";

import styles from "./Labels.scss";
import { LabelPropTypes } from "./propTypes";

export default function LabelRow(props) {
  const { label, onEdit, onRemove } = props;

  return (
    <TableRow key={label.id} dataTestId="label-row" highlighting={false}>
      <TableRowColumn columnClass={styles.itemCol}>
        <div className={styles.item}>
          <p className={styles.title}>{label.name}</p>
          <ButtonsWrapper>
            <Button
              className={styles.button}
              type="normal"
              onClick={() => onEdit(label)}
              dataTestId="edit-label-button"
            >
              {trans.STRUCTURES_LABELS__CUSTOMIZE_BUTTON()}
            </Button>
            <ActionMenu>
              <MenuItem
                onClick={() => onEdit(label)}
                dataTestId="edit-label-menu-button"
              >
                {trans.STRUCTURES_LABELS__CUSTOMIZE_BUTTON()}
              </MenuItem>
              <MenuItem
                onClick={() => onRemove(label)}
                dataTestId="remove-label-menu-button"
              >
                {trans.DELETE()}
              </MenuItem>
            </ActionMenu>
          </ButtonsWrapper>
        </div>
      </TableRowColumn>
      <TableRowColumn className={styles.countCol}>
        {trans.STRUCTURES__USE_AMOUNT({ count: label.useCount })}
      </TableRowColumn>
    </TableRow>
  );
}

LabelRow.propTypes = {
  label: LabelPropTypes.isRequired,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
};
