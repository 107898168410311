// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { apiThatThrows } from "containers/Request";

import { TransCreatePropTypes, LabelType } from "../../Labels/propTypes";
import { CREATE_INITIAL_VALUES } from "./constants";
import { LabelModalForm } from "./LabelModalForm";

@connect(null, (dispatch) => ({
  getLanguages: () =>
    dispatch(
      apiThatThrows.getLanguages.action({
        cache: true,
      })
    ),
}))
export class CreateLabelModalData extends Component {
  static propTypes = {
    labelType: LabelType,
    fieldName: PropTypes.string,
    texts: TransCreatePropTypes.isRequired,
    mode: PropTypes.string,
    onSubmit: PropTypes.func,
    onChangeMode: PropTypes.func.isRequired,
    availableProviders: PropTypes.array,
    validate: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    // from @connect
    getLanguages: PropTypes.func.isRequired,
  };

  state = {
    languages: [],
    loading: false,
  };

  componentDidMount() {
    this.fetchLanguages();
  }

  async fetchLanguages() {
    const { getLanguages } = this.props;

    this.setState({
      loading: true,
    });

    const languagesResponse = await getLanguages();

    this.setState({
      languages: languagesResponse.results,
      loading: false,
    });
  }

  render() {
    const {
      fieldName,
      texts,
      labelType,
      mode,
      onChangeMode,
      availableProviders,
      onClose,
      onSubmit,
      validate,
    } = this.props;

    const { loading, languages } = this.state;
    return (
      <LabelModalForm
        fieldName={fieldName}
        mode={mode}
        texts={texts}
        labelType={labelType}
        languages={languages}
        loading={loading}
        onChangeMode={onChangeMode}
        availableProviders={availableProviders}
        selectedProvider={CREATE_INITIAL_VALUES.providerField}
        initialValues={CREATE_INITIAL_VALUES}
        validate={validate}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    );
  }
}
