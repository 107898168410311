// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { gql } from "@apollo/client";

export default gql`
  query structures(
    $pagination: PaginationInput!
    $sort: StructuresSortInput
    $search: FilterSearchInput
  ) {
    structures(pagination: $pagination, sort: $sort, search: $search) {
      structures {
        items {
          id
          name
          lastModifiedDate
          sections {
            items {
              id
              sectionLabel {
                id
                translations(onlyDefault: true) {
                  translation
                }
              }
              selectedProvider {
                id
              }
              packages {
                items {
                  id
                  packageLabel {
                    id
                    translations(onlyDefault: true) {
                      translation
                    }
                  }
                  autofillPackageItems {
                    displayCount
                    orderBy
                    filters {
                      key
                      value
                    }
                  }
                  packageItems(pagination: { noLimit: true }) {
                    items {
                      id
                    }
                    pagination {
                      count
                    }
                  }
                }
              }
            }
          }
          score {
            value
          }
        }
        pagination {
          count
        }
      }
    }
  }
`;
