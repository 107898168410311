// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

export const ProviderPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

export const AutofillPackageItemsPropTypes = PropTypes.shape({
  displayCount: PropTypes.number.isRequired,
  orderBy: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
});

export const ApplicationPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
});

export const PackageItemPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
});

export const PackagePropTypes = PropTypes.shape({
  id: PropTypes.string,
  key: PropTypes.string,
  name: PropTypes.string.isRequired,
  packageItems: PropTypes.arrayOf(PackageItemPropTypes).isRequired,
  autofillPackageItems: AutofillPackageItemsPropTypes,
});

export const SectionPropTypes = PropTypes.shape({
  id: PropTypes.string,
  key: PropTypes.string,
  name: PropTypes.string.isRequired,
  packages: PropTypes.arrayOf(PackagePropTypes).isRequired,
});

export const StructurePropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(SectionPropTypes).isRequired,
});
