// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

export const PackagePropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  itemsCount: PropTypes.number.isRequired,
});

export const SectionPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  packages: PropTypes.arrayOf(PackagePropTypes).isRequired,
});

export const ScorePropTypes = PropTypes.shape({
  value: PropTypes.number,
});

export const StructurePropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  lastModifiedDate: PropTypes.instanceOf(Date).isRequired,
  sections: PropTypes.arrayOf(SectionPropTypes).isRequired,
  score: ScorePropTypes.isRequired,
});
