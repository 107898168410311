// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useFormikContext } from "formik";
import get from "lodash-es/get";
import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { GrabsonIcon } from "components/icons";
import {
  TableRow,
  TableRowColumn,
  TableRowColumnExtended,
} from "components/layout";
import { ActionMenu, MenuItem } from "components/popups";
import { trans } from "src/translations";
import { classes } from "utils/classes";

import { DIRECTIONS, STRUCTURE_NAMES } from "../constants";
import { Accordion } from "./Accordion";
import styles from "./StructureItem.scss";

export const StructureItem = ({
  fieldName,
  type,
  validate,
  onEdit,
  onRemove,
  onMove,
  dataTestId,
  children,
  rowClassName,
  isNested,
  index,
}) => {
  const { errors, values, setFieldValue } = useFormikContext();
  const item = get(values, fieldName);

  const isValid = () => validate(fieldName, errors);

  const setExpanded = (isExpanded) =>
    setFieldValue(`${fieldName}.isExpanded`, isExpanded);

  return (
    <>
      <TableRow
        highlighting={false}
        dataTestId={dataTestId}
        className={classes(rowClassName, styles.row, {
          [styles.sectionRow]: !isNested,
        })}
      >
        <TableRowColumnExtended
          fontIcon={item.isExpanded ? "arrow-up" : "arrow-down"}
          fontIconSize="sm"
          fontIconType="micro"
          fontIconClassName={styles.arrow}
          columnClass={styles.withoutPadding}
          className={classes(styles.name, styles.rowGreen, {
            [styles.paddedName]: isNested,
            [styles.rowRed]: !isValid(),
          })}
          onClick={() => setExpanded(!item.isExpanded)}
          text={get(item, "name", "")}
        />
        <TableRowColumn
          columnClass={styles.withoutPadding}
          className={classes({
            [styles.paddedCategory]: isNested,
          })}
        >
          <span className={styles.type}>{STRUCTURE_NAMES[type]}</span>
        </TableRowColumn>
        <TableRowColumn
          columnClass={styles.withoutPadding}
          className={classes(styles.edit, {
            [styles.paddedEdit]: isNested,
          })}
        >
          <Button
            className={classes({
              [styles.buttonWithBorder]: isNested,
            })}
            type="normal"
            onClick={() => onEdit(type, item, fieldName)}
          >
            {trans.STRUCTURES_LABELS__CUSTOMIZE_BUTTON()}
          </Button>
        </TableRowColumn>
        <TableRowColumn
          columnClass={styles.withoutPadding}
          className={classes(styles.edit, {
            [styles.paddedEdit]: isNested,
          })}
        >
          <GrabsonIcon
            className={styles.moveIcon}
            dataTestId="move-up"
            font="micro"
            name="arrow-up"
            onClick={() => onMove(index, DIRECTIONS.UP)}
            size="sm"
          />
          <GrabsonIcon
            className={styles.moveIcon}
            dataTestId="move-down"
            font="micro"
            name="arrow-down"
            onClick={() => onMove(index, DIRECTIONS.DOWN)}
            size="sm"
          />
        </TableRowColumn>
        <TableRowColumn
          columnClass={styles.withoutPadding}
          className={classes(styles.edit, {
            [styles.paddedEdit]: isNested,
          })}
        >
          <ActionMenu>
            <MenuItem onClick={() => onEdit(type, item, fieldName)}>
              {trans.STRUCTURES_LABELS__CUSTOMIZE_BUTTON()}
            </MenuItem>
            <MenuItem onClick={() => onRemove(index)}>
              {trans.DELETE()}
            </MenuItem>
          </ActionMenu>
        </TableRowColumn>
      </TableRow>
      <Accordion
        isExpanded={item.isExpanded}
        dataTestId={`${dataTestId}-unwrapped`}
      >
        {children}
      </Accordion>
    </>
  );
};

StructureItem.propTypes = {
  fieldName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired,
  index: PropTypes.number,
  validate: PropTypes.func,
  dataTestId: PropTypes.string,
  rowClassName: PropTypes.string,
  isNested: PropTypes.bool,
};
