// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { withActiveOrganization } from "containers/Auth/decorators";
import { restrictedArea, ROLES } from "containers/Permissions";
import { trans } from "src/translations";
import { withRouter, withSearch } from "utils/decorators";
import { withMutation, withQuery } from "utils/graphql";
import { parseLocationToLimitOffset } from "utils/pagination";

import { ITEMS_PER_PAGE } from "./constants";
import { deletePackageLabelGql } from "./gql/deletePackageLabel";
import { editPackageLabelGql } from "./gql/editPackageLabel";
import { packageLabelsGql } from "./gql/packageLabels";
import Labels from "./Labels";

@restrictedArea(() => ({
  allowed: [
    ROLES.structure.structureManager,
    ROLES.administrator.structuresAdmin,
  ],
}))
@withRouter
@withActiveOrganization
@withSearch
@withMutation({ name: "deletePackageLabel", mutation: deletePackageLabelGql })
@withMutation({ name: "editPackageLabel", mutation: editPackageLabelGql })
@withQuery({ name: "packageLabels", query: packageLabelsGql })
export class PackageLabelsData extends Component {
  static propTypes = {
    // from @withActiveOrganization
    activeOrganization: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),

    // from @withRouter
    location: PropTypes.object,

    // from @withMutation
    deletePackageLabelMutation: PropTypes.func,
    deletePackageLabelMutationStatus: PropTypes.object,
    editPackageLabelMutation: PropTypes.func,
    editPackageLabelMutationStatus: PropTypes.object,

    // from @withQuery
    packageLabelsQuery: PropTypes.func,
    packageLabelsQueryStatus: PropTypes.object,

    // from @withSearch
    changeSearch: PropTypes.func,
    searchValue: PropTypes.string,
  };

  texts = {
    edit: {
      translationsHeader:
        trans.STRUCTURES_PACKAGE_LABEL_MODAL__COLUMN_TRANSLATIONS,
    },
    list: {
      contentHeader: trans.STRUCTURES_PACKAGE__COLUMN_ITEM,
      infoHeader: trans.STRUCTURES_PACKAGE__COLUMN_INFO,
      searchPlaceholder: trans.STRUCTURES_PACKAGE__SEARCH_PLACEHOLDER,
    },
    remove: {
      removeFailMessage: trans.STRUCTURES_PACKAGE__REMOVE_FAIL_MESSAGE,
      removeConfirmMessage: trans.STRUCTURES_PACKAGE__REMOVE_WARNING,
    },
  };

  componentDidMount() {
    this.fetchPackageLabels();
  }

  componentDidUpdate(prevProps) {
    const locationHasChanged = this.props.location !== prevProps.location;
    const organizationHasChanged =
      this.props.activeOrganization.id !== prevProps.activeOrganization.id;

    if (locationHasChanged || organizationHasChanged) {
      this.fetchPackageLabels();
    }
  }

  fetchPackageLabels = () => {
    const { packageLabelsQuery, searchValue, location } = this.props;

    const pagination = parseLocationToLimitOffset(location, ITEMS_PER_PAGE);

    packageLabelsQuery({
      variables: {
        pagination,
        search: { query: searchValue },
      },
    });
  };

  handleRemovePackageLabel = async (packageLabelId) => {
    const { deletePackageLabelMutation } = this.props;
    await deletePackageLabelMutation({
      variables: { input: { packageLabelId: packageLabelId } },
    });
  };

  handleUpdatePackageLabel = async (packageLabelId, translations) => {
    const { editPackageLabelMutation } = this.props;
    await editPackageLabelMutation({
      variables: { input: { packageLabelId, translations } },
    });
  };

  adaptPackageLabels = (data) => {
    if (!data) {
      return [];
    }

    return data.packageLabels.packageLabels.items.map(
      ({ id, usedInStructuresCount, translations }) => {
        return {
          id: id,
          name: get(translations, "[0].translation", ""),
          useCount: Number(usedInStructuresCount),
        };
      }
    );
  };

  render() {
    const {
      changeSearch,
      searchValue,
      deletePackageLabelMutationStatus,
      editPackageLabelMutationStatus,
      packageLabelsQueryStatus,
    } = this.props;

    const totalCount = get(
      packageLabelsQueryStatus.data,
      "packageLabels.packageLabels.pagination.count",
      0
    );

    return (
      <Labels
        labels={this.adaptPackageLabels(packageLabelsQueryStatus.data)}
        labelsStatus={packageLabelsQueryStatus}
        removeLabel={this.handleRemovePackageLabel}
        removeLabelStatus={deletePackageLabelMutationStatus}
        updateLabel={this.handleUpdatePackageLabel}
        updateLabelStatus={editPackageLabelMutationStatus}
        changeSearch={changeSearch}
        searchValue={searchValue}
        texts={this.texts}
        labelType={"package"}
        totalCount={totalCount}
      />
    );
  }
}
