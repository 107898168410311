// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import get from "lodash-es/get";
import PropTypes from "prop-types";

import { GrabsonIcon } from "components/icons";
import {
  TableRow,
  TableRowColumn,
  TableRowColumnExtended,
} from "components/layout";
import { classes } from "utils/classes";
import { innerRefType } from "utils/types";

import { ApplicationPropTypes } from "../../propTypes";
import styles from "./PackageItemRow.scss";

export const PackageItemRow = ({
  packageItem,
  index,
  onRemove,
  isDragging,
  innerRef,
  ...draggingProps
}) => (
  <TableRow
    highlighting={false}
    dragProps={draggingProps}
    innerRef={innerRef}
    className={classes(styles.row, { [styles.dragging]: isDragging })}
    dataTestId={`package-item-row-${index}`}
  >
    <TableRowColumn columnClass={styles.dragColumn}>
      <GrabsonIcon name="dragdrop2" className={styles.dragIcon} />
    </TableRowColumn>
    <TableRowColumn columnClass={styles.iconColumn}>
      <img src={get(packageItem, "icon")} className={styles.icon} />
    </TableRowColumn>
    <TableRowColumnExtended
      to={`/applications/distribution/${packageItem.id}/details`}
      urlTarget="blank"
      text={packageItem.name}
      columnClass={styles.nameColumn}
    />
    <TableRowColumn columnClass={styles.categoryColumn}>
      {get(packageItem, "category")}
    </TableRowColumn>
    <TableRowColumn columnClass={styles.deleteColumn}>
      <GrabsonIcon
        onClick={() => onRemove(index)}
        name="close-filled"
        size="lg"
        className={styles.removeIcon}
      />
    </TableRowColumn>
  </TableRow>
);

PackageItemRow.propTypes = {
  packageItem: ApplicationPropTypes,
  onRemove: PropTypes.func.isRequired,
  isDragging: PropTypes.bool,
  innerRef: innerRefType,
  index: PropTypes.number,
};
