// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component, Fragment } from "react";
import * as React from "react";

import { Field } from "formik";
import PropTypes from "prop-types";

import { SearchableList, SearchableListPanel } from "components/elements";
import { Label, ValidationBadge } from "components/form";
import { Radio } from "components/form/elements";
import { InfiniteData } from "containers/InfiniteData";
import { adaptQueryParamsToGqlSearchArg } from "pages/_utils/adaptQueryParamsToGqlSearchArg";
import { trans } from "src/translations";
import { classes } from "utils/classes";
import { getGqlErrorMessage } from "utils/errors";
import { withQuery } from "utils/graphql";
import { calulateOffsetFromPage } from "utils/pagination";

import styles from "../CreateStructureModal.scss";
import AdminStructuresQuery from "../gql/adminStructures";
import StructuresQuery from "../gql/structures";

const ITEMS_PER_PAGE = 10;

// TODO CS-395: Add 'X elements' at the bottom of the <SearchableList>

const adaptStructures = (data) =>
  data.map((entity) => ({
    id: entity.id,
    name: entity.name,
  }));

@withQuery({ name: "structure", query: StructuresQuery })
@withQuery({ name: "adminStructures", query: AdminStructuresQuery })
class StructureToCopy extends Component {
  static propTypes = {
    forAdmin: PropTypes.bool.isRequired,
    // from @withQuery
    structureQuery: PropTypes.func,
    adminStructuresQuery: PropTypes.func,
    /** formik context props */
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
  };

  fetchStructures = async (queryParams) => {
    const { structureQuery, adminStructuresQuery, forAdmin } = this.props;

    const listQuery = forAdmin ? adminStructuresQuery : structureQuery;

    const result = await listQuery({
      variables: {
        pagination: {
          offset: calulateOffsetFromPage(queryParams.page, ITEMS_PER_PAGE),
          limit: ITEMS_PER_PAGE,
        },
        search: adaptQueryParamsToGqlSearchArg({
          search: queryParams.search || "",
          contains: "",
          not_contains: "",
        }),
      },
    });

    const structuresType = forAdmin ? "adminStructures" : "structures";

    return {
      meta: {
        count: result[structuresType]?.structures?.pagination?.count ?? 0,
      },
      results: result[structuresType]?.structures?.items ?? [],
    };
  };

  renderRow = (structure, i) => {
    const {
      field,
      form: { setFieldValue },
    } = this.props;
    const checkedStructureId = field.value;
    const name = `copy-structure-${i}`;

    return (
      <SearchableListPanel key={structure.id} className={styles.structureRow}>
        <Radio
          name={name}
          dataTestId={name}
          label={structure.name}
          value={checkedStructureId || false}
          defaultValue={structure.id}
          onChange={() => {
            setFieldValue(field.name, structure.id);
          }}
          className={styles.structureRowCheckbox}
        />
      </SearchableListPanel>
    );
  };

  render() {
    const {
      form: { touched, errors },
      field: { name },
    } = this.props;
    return (
      <React.Fragment>
        <Label
          text={trans.STRUCTURES_MODAL__CREATE_STRUCTURE_COPY_FROM()}
          required={true}
        />
        <InfiniteData
          fetchData={this.fetchStructures}
          mapData={adaptStructures}
          itemsPerPage={ITEMS_PER_PAGE}
        >
          {({
            data,
            pending,
            error,
            allDataLoaded,
            handleScroll,
            handleSearchChange,
            count,
          }) => {
            return (
              <Fragment>
                <SearchableList
                  dataTestId="create-structure-existing-list"
                  list={data}
                  onScroll={handleScroll}
                  onSearchChange={handleSearchChange}
                  withLoader={true}
                  loading={pending}
                  allDataLoaded={allDataLoaded}
                  error={error && getGqlErrorMessage(error)}
                  placeholder={trans.STRUCTURES_MODAL__CREATE_STRUCTURE_COPY_FROM_PLACEHOLDER()}
                >
                  {this.renderRow}
                </SearchableList>
                <p className={classes(styles.structuresCount)}>
                  {trans.STRUCTURES_MODAL__CREATE_STRUCTURE_COPY_FROM_ELEMENTS({
                    count,
                  })}
                </p>
              </Fragment>
            );
          }}
        </InfiniteData>
        <ValidationBadge error={errors[name]} touched={touched[name]} />
      </React.Fragment>
    );
  }
}

export default function StructureToCopyField(props) {
  const { forAdmin, name } = props;
  return <Field name={name} component={StructureToCopy} forAdmin={forAdmin} />;
}

StructureToCopyField.propTypes = {
  forAdmin: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
};
