// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component, Fragment } from "react";

import isEmpty from "lodash-es/isEmpty";
import PropTypes from "prop-types";

import { TableRowColumnExtended, TableSubRow } from "components/layout";
import { trans } from "src/translations";

import { PROVIDERS } from "../StructureForm/TabStructureCreator/constants";
import { SectionPropTypes } from "./propTypes";
import StructurePackageRow from "./StructurePackageRow";
import styles from "./Structures.scss";

export default class StructureSectionRow extends Component {
  static propTypes = {
    section: SectionPropTypes,
    openPackageModal: PropTypes.func,
  };

  state = {
    isExpanded: false,
  };

  onToggle = () => {
    this.setState((prevState) => ({
      isExpanded: !prevState.isExpanded,
    }));
  };

  render() {
    const { section, openPackageModal } = this.props;
    const { isExpanded } = this.state;

    return (
      <Fragment>
        <TableSubRow dataTestId="structure-section-row">
          <TableRowColumnExtended
            fontIcon={isExpanded ? "arrow-up" : "arrow-down"}
            fontIconSize="sm"
            fontIconType="micro"
            onClick={this.onToggle}
            text={section.name}
            colspan={4}
            columnClass={styles.section}
            fontIconClassName={styles.toggleIcon}
          />
        </TableSubRow>
        {isExpanded &&
          (!isEmpty(section.packages) ? (
            section.packages.map((pack) => (
              <StructurePackageRow
                key={pack.id}
                pack={pack}
                openPackageModal={openPackageModal}
              />
            ))
          ) : (
            <StructurePackageRow
              pack={{
                name: trans.STRUCTURES_LIST__CUSTOM_PROVIDER({
                  provider: PROVIDERS[section.selectedProvider].name,
                }),
              }}
            />
          ))}
      </Fragment>
    );
  }
}
