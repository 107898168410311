// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent, Fragment } from "react";

import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { Loader } from "components/elements";
import { Info } from "components/feedback";
import { Column, Section, Subsection, Row } from "components/layout";
import { Link } from "components/typography";
import { NO_VALUE_PLACEHOLDER } from "src/constants";
import { trans } from "src/translations";
import { classes } from "utils/classes";
import { formatDateTime } from "utils/date";

import { UpdateJobStatus } from "../_shared/UpdateJobStatus";
import { UpdateJobStatusModal } from "../UpdateJobStatusModal/UpdateJobStatusModal";
import { UpdateJobLogs } from "./components/UpdateJobLogs";
import { CLONEABLE_STATUSES } from "./constants";
import { UpdateJobPropType } from "./propTypes";
import { UpdateJobDetailsActionButtons } from "./UpdateJobDetailsActionButtons";
import styles from "./UpdateJobDetailsMainTab.scss";
import { UpdateJobDetailsStepTable } from "./UpdateJobDetailsStepTable";
import { adaptUpdateJobDates } from "./utils/adaptUpdateJobDates";

export class UpdateJobDetailsMainTab extends PureComponent {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    data: UpdateJobPropType,
    devicesRefreshDate: PropTypes.instanceOf(Date),
    fetchDevices: PropTypes.func.isRequired,
    fetchDevicesStatus: PropTypes.shape({
      error: PropTypes.string,
      loading: PropTypes.bool.isRequired,
    }).isRequired,
    modalIsOpen: PropTypes.bool.isRequired,
    modalAction: PropTypes.string,
    openModal: PropTypes.func.isRequired,
    onModalClose: PropTypes.func.isRequired,
    onModalSuccess: PropTypes.func.isRequired,
  };

  state = {
    successMessage: null,
  };

  setSuccessMessage = (successMessage) => this.setState({ successMessage });

  renderHeader = () => {
    const { id, threshold, status, statusActions } = this.props.data;
    const { openModal } = this.props;

    return (
      <Fragment>
        <div className={styles.headerContainer}>
          <div className={styles.header}>
            {trans.UPDATES__UPDATE_DETAILS__DETAILS_DESCRIPTION()}
          </div>
          <div>
            <UpdateJobDetailsActionButtons
              actions={statusActions}
              openModal={openModal}
              clone={CLONEABLE_STATUSES.includes(status)}
              updateJobId={id}
            />
          </div>
        </div>
        <div className={styles.subheader}>
          <span>{trans.UPDATES__UPDATE_DETAILS__THRESHOLD_LABEL()}</span>
          <span
            className={styles.subheaderValue}
            data-test-id="update-job-threshold"
          >
            {threshold}%
          </span>
        </div>
        <div className={styles.subheader}>
          <span>{trans.UPDATES__UPDATE_DETAILS__STATUS_LABEL()}</span>
          <UpdateJobStatus
            status={status}
            className={classes(styles.subheaderValue, styles.status)}
          />
        </div>
      </Fragment>
    );
  };

  renderUpdateDetails = () => {
    const {
      type,
      description,
      creationDate,
      availabilityStartDate,
      availabilityEndDate,
    } = this.props.data;

    const { creation, start, end } = adaptUpdateJobDates(
      creationDate,
      availabilityStartDate,
      availabilityEndDate
    );

    return (
      <Section header={trans.UPDATES__UPDATE_DETAILS__UPDATE_DETAILS_HEADER()}>
        <Subsection
          header={trans.UPDATES__UPDATE_DETAILS__UPDATE_DETAILS_CREATED()}
          dataTestId="update-job-created-date"
        >
          {creation}
        </Subsection>
        <Subsection
          header={trans.UPDATES__UPDATE_DETAILS__UPDATE_DETAILS_AVAILABILITY()}
          dataTestId="update-job-availability"
        >
          {start}
          {" - "}
          {end}
        </Subsection>
        <Subsection
          header={trans.UPDATES__UPDATE_DETAILS__UPDATE_DETAILS_DESCRIPTION()}
          dataTestId="update-job-description"
        >
          {description}
        </Subsection>
        <Subsection
          header={trans.UPDATES__UPDATE_DETAILS__UPDATE_DETAILS_JOB_TYPE()}
          dataTestId="update-job-type"
        >
          {type ? type[0] + type.slice(1).toLowerCase() : NO_VALUE_PLACEHOLDER}
        </Subsection>
      </Section>
    );
  };

  renderSoftwarePackage = () => {
    const { customDownloadUrl } = this.props.data;
    const {
      id: softwarePackageVersionId,
      fileName,
      softwarePackage: { id: softwarePackageId },
    } = this.props.data.softwarePackageVersion;

    return (
      <Section
        header={trans.UPDATES__UPDATE_DETAILS__SOFTWARE_PACKAGE_HEADER()}
      >
        <Subsection
          header={trans.UPDATES__UPDATE_DETAILS__SOFTWARE_PACKAGE_FILENAME()}
          dataTestId="subsection-software-package"
        >
          <Link
            to={`/updates/packages/${softwarePackageId}/version/${softwarePackageVersionId}`}
            data-test-id="update-job-software-package"
          >
            {fileName}
          </Link>
        </Subsection>
        <Subsection
          header={trans.UPDATES__UPDATE_DETAILS__SOFTWARE_PACKAGE_CUSTOM_DOWNLOAD()}
          dataTestId="subsection-software-package-download-url"
        >
          {customDownloadUrl || trans.OFF().toUpperCase()}
        </Subsection>
      </Section>
    );
  };

  renderTarget = () => {
    const {
      devicesRefreshDate,
      fetchDevices,
      fetchDevicesStatus,
      data: { eligibleDevicesCount },
    } = this.props;
    const { id, name, deviceCount } = this.props.data.target;
    const { loading, error } = fetchDevicesStatus;

    return (
      <Section header={trans.UPDATES__UPDATE_DETAILS__TARGET_HEADER()}>
        <Subsection
          header={trans.UPDATES__UPDATE_DETAILS__TARGET_FILTER_QUERY()}
          dataTestId="subsection-filter-query"
        >
          <Link
            to={`/devices/filters/${id}/details`}
            data-test-id="update-job-target"
          >
            {name}
          </Link>
        </Subsection>
        <Subsection dataTestId="subsection-filter-device-number">
          <p className={styles.targetCheckedOn}>
            {trans.UPDATES__UPDATE_DETAILS__TARGET_DEVICE_NUMBER_CHECKED_ON()}{" "}
            {formatDateTime(devicesRefreshDate)}
          </p>
          <p className={styles.deviceCounter}>
            {trans.UPDATES__UPDATE_DETAILS__TARGET_DEVICES()} {deviceCount}
          </p>
          <p className={styles.deviceCounter}>
            {trans.UPDATES__UPDATE_DETAILS__ELIGIBLE_DEVICES()}{" "}
            {eligibleDevicesCount}
          </p>
          <Button
            onClick={fetchDevices}
            dataTestId="device-number-refresh"
            disabled={loading}
            processing={loading}
          >
            {trans.REFRESH()}
          </Button>
          {error && (
            <Info
              type="error"
              className={styles.deviceCountersError}
              dataTestId="device-number-error"
            >
              {error}
            </Info>
          )}
        </Subsection>
      </Section>
    );
  };

  renderModal = () => {
    const { id } = this.props.data;
    const { modalAction, modalIsOpen, onModalClose, onModalSuccess } =
      this.props;

    return (
      <UpdateJobStatusModal
        updateJobId={id}
        action={modalAction}
        isOpen={modalIsOpen}
        onClose={onModalClose}
        onSuccess={onModalSuccess}
      />
    );
  };

  render() {
    const { loading, error, data } = this.props;
    const { successMessage } = this.state;

    if (error) {
      return <Info type="error">{error}</Info>;
    }

    if (loading || !data) {
      return <Loader />;
    }

    return (
      <Fragment>
        {successMessage != null && successMessage.length > 0 && (
          <Info type="normal">{successMessage}</Info>
        )}

        <Section>
          {this.renderHeader()}
          <UpdateJobDetailsStepTable steps={data.steps} />
        </Section>

        <Row>
          <Column className={styles.column}>
            {this.renderUpdateDetails()}
            {this.renderSoftwarePackage()}
          </Column>
          <Column className={styles.column}>
            {this.renderTarget()}
            <UpdateJobLogs
              data={data}
              setSuccessMessage={this.setSuccessMessage}
            />
          </Column>
        </Row>
        {this.renderModal()}
      </Fragment>
    );
  }
}
