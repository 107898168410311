// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent, Fragment } from "react";

import PropTypes from "prop-types";

import { SearchBar } from "components/elements";
import { Section, WarningMessage } from "components/layout";
import { trans } from "src/translations";
import { getGqlErrorMessage } from "utils/errors";
import { mutationResultType, queryResultType } from "utils/graphql";

import { LabelModal, RemoveLabelModal } from "../Modals";
import styles from "./Labels.scss";
import LabelsTable from "./LabelsTable";
import {
  TransEditPropTypes,
  TransListPropTypes,
  TransRemovePropTypes,
  LabelType,
} from "./propTypes";

export default class Labels extends PureComponent {
  static propTypes = {
    labels: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        useCount: PropTypes.number.isRequired,
      })
    ),
    labelsStatus: queryResultType,
    removeLabel: PropTypes.func.isRequired,
    removeLabelStatus: mutationResultType,
    updateLabel: PropTypes.func.isRequired,
    updateLabelStatus: mutationResultType,
    texts: PropTypes.shape({
      list: TransListPropTypes,
      edit: TransEditPropTypes,
      remove: TransRemovePropTypes,
    }).isRequired,
    labelType: LabelType,
    totalCount: PropTypes.number.isRequired,

    changeSearch: PropTypes.func,
    searchValue: PropTypes.string,
  };

  state = {
    currentItem: null,
    isEditOpen: false,
    isRemoveOpen: false,
  };

  closeModal = () => {
    const { removeLabelStatus, updateLabelStatus } = this.props;
    this.setState({
      isEditOpen: false,
      isRemoveOpen: false,
    });

    removeLabelStatus.reset();
    updateLabelStatus.reset();
  };

  openEditModal = (item) => {
    this.setState({
      currentItem: item,
      isEditOpen: true,
    });
  };

  openRemoveModal = (item) => {
    this.setState({
      currentItem: item,
      isRemoveOpen: true,
    });
  };

  handleEditLabel = async (values) => {
    const { updateLabel, labelsStatus } = this.props;
    const { currentItem } = this.state;

    await updateLabel(currentItem.id, values);
    this.closeModal();
    await labelsStatus.refetch();
  };

  handleRemoveLabel = async () => {
    const { removeLabel, labelsStatus } = this.props;
    const { currentItem } = this.state;

    await removeLabel(currentItem.id);
    this.closeModal();
    await labelsStatus.refetch();
  };

  render() {
    const {
      labels,
      changeSearch,
      searchValue,
      labelsStatus,
      removeLabelStatus,
      updateLabelStatus,
      texts,
      labelType,
      totalCount,
    } = this.props;

    const { currentItem, isEditOpen, isRemoveOpen } = this.state;

    return (
      <Fragment>
        <Section>
          <WarningMessage
            title={trans.STRUCTURES_LABELS__CHANGES_HEADER()}
            text={trans.STRUCTURES_LABELS__CHANGES_WARNING()}
          />
          <SearchBar
            onSearch={changeSearch}
            value={searchValue}
            placeholder={texts.list.searchPlaceholder()}
            className={styles.searchBar}
            dataTestId="labels-search-bar"
          />
          <LabelsTable
            count={totalCount}
            texts={texts.list}
            onEdit={this.openEditModal}
            onRemove={this.openRemoveModal}
            labels={labels}
            loading={labelsStatus.loading}
            error={labelsStatus.error && getGqlErrorMessage(labelsStatus.error)}
          />
        </Section>
        <LabelModal
          item={currentItem}
          isOpen={isEditOpen}
          labelType={labelType}
          texts={texts.edit}
          onClose={this.closeModal}
          onSubmit={this.handleEditLabel}
          updateStatus={updateLabelStatus}
        />
        <RemoveLabelModal
          item={currentItem}
          isOpen={isRemoveOpen}
          texts={texts.remove}
          onClose={this.closeModal}
          onRemove={this.handleRemoveLabel}
          removeStatus={removeLabelStatus}
        />
      </Fragment>
    );
  }
}
