// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";

import { PROVIDER_ID } from "../../StructureForm/TabStructureCreator/constants";

export const CLOUD_PROVIDER_ID = PROVIDER_ID.CLOUD;
export const FORM_NAME = "labelForm";
export const CREATE_NEW_MODE = "new";
export const CREATE_COPY_MODE = "copy";
export const CREATE_MODE = "create";
export const EDIT_MODE = "edit";
export const EDIT_MODES = {
  EDIT: "edit",
  REPLACE_NEW: "replace-new",
  REPLACE_EXISTING: "replace-existing",
};
export const SECTION = "section";

export const LABEL_ACTION_TOOLTIP = {
  [EDIT_MODES.EDIT]: {
    info: trans.STRUCTURES_LABEL_MODAL__EDIT_SELECT_EDIT_TOOLTIP(),
  },
  [EDIT_MODES.REPLACE_NEW]: {
    info: trans.STRUCTURES_LABEL_MODAL__EDIT_SELECT_REPLACE_NEW_TOOLTIP(),
  },
  [EDIT_MODES.REPLACE_EXISTING]: {
    info: trans.STRUCTURES_LABEL_MODAL__EDIT_SELECT_REPLACE_EXISTING_TOOLTIP(),
    shared: [
      trans.STRUCTURES_LABEL_MODAL__COPY_SHARED_ITEM_1_TOOLTIP(),
      trans.STRUCTURES_LABEL_MODAL__COPY_SHARED_ITEM_2_TOOLTIP(),
    ],
    notShared: [trans.STRUCTURES_LABEL_MODAL__COPY_NOT_SHARED_ITEM_1_TOOLTIP()],
    additionalInfo:
      trans.STRUCTURES_LABEL_MODAL__COPY_ADDITIONAL_INFO_TOOLTIP(),
    example: trans.STRUCTURES_LABEL_MODAL__COPY_EXAMPLE_INFO_TOOLTIP(),
  },
  [CREATE_NEW_MODE]: {
    info: trans.STRUCTURES_LABEL_MODAL__CREATE_NEW_SELECT_TOOLTIP(),
  },
  [CREATE_COPY_MODE]: {
    info: trans.STRUCTURES_LABEL_MODAL__CREATE_COPY_EXISTING_TOOLTIP(),
    shared: [
      trans.STRUCTURES_LABEL_MODAL__COPY_SHARED_ITEM_1_TOOLTIP(),
      trans.STRUCTURES_LABEL_MODAL__COPY_SHARED_ITEM_2_TOOLTIP(),
    ],
    notShared: [trans.STRUCTURES_LABEL_MODAL__COPY_NOT_SHARED_ITEM_1_TOOLTIP()],
    additionalInfo:
      trans.STRUCTURES_LABEL_MODAL__COPY_ADDITIONAL_INFO_TOOLTIP(),
    example: trans.STRUCTURES_LABEL_MODAL__COPY_EXAMPLE_INFO_TOOLTIP(),
  },
};

export const SELECT_LABEL_FIELDS_OPTIONS = {
  createLabelSelectTabsField: [
    {
      text: trans.STRUCTURES_LABEL_MODAL__CREATE_RADIO_NEW(),
      value: CREATE_NEW_MODE,
    },
    {
      text: trans.STRUCTURES_LABEL_MODAL__CREATE_RADIO_COPY(),
      value: CREATE_COPY_MODE,
    },
  ],
  editLabelSelectTabsField: [
    {
      text: trans.STRUCTURES_LABEL_MODAL__EDIT_SELECT_EDIT(),
      value: EDIT_MODES.EDIT,
    },
    {
      text: trans.STRUCTURES_LABEL_MODAL__EDIT_SELECT_REPLACE_NEW(),
      value: EDIT_MODES.REPLACE_NEW,
    },
    {
      text: trans.STRUCTURES_LABEL_MODAL__EDIT_SELECT_REPLACE_EXISTING(),
      value: EDIT_MODES.REPLACE_EXISTING,
    },
  ],
};

export const NEW_TRANSLATION_INITIAL_VALUES = {
  isDefault: false,
  languageCode: undefined,
  translation: "",
};

export const INITIAL_VALUES = {
  translations: [
    {
      isDefault: true,
      languageCode: undefined,
      translation: undefined,
    },
  ],
};

export const CREATE_INITIAL_VALUES = {
  translations: [
    {
      isDefault: true,
      languageCode: undefined,
      translation: "",
    },
  ],
  labelToCopy: {},
  providerField: PROVIDER_ID.CLOUD,
};

export const ITEMS_PER_COPY_PAGE = 10;

export const EXLCUDE_LANG_KEY = {
  DEFAULT: "excludedLanguages",
  REPLACE: "excludedLanguagesReplace",
};
