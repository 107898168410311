// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { gql } from "@apollo/client";

export const createPackageLabelGql = gql`
  mutation createPackageLabel($input: CreatePackageLabelInput!) {
    createPackageLabel(input: $input) {
      packageLabel {
        id
        translations(onlyDefault: true) {
          translation
        }
      }
    }
  }
`;
