// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { withActiveOrganization } from "containers/Auth/decorators";
import { restrictedArea, ROLES } from "containers/Permissions";
import { withQueryParams, withRouter, withSorting } from "utils/decorators";
import { getGqlErrorMessage } from "utils/errors";
import { withQuery, queryResultType } from "utils/graphql";
import { parseLocationToLimitOffset } from "utils/pagination";

import { DefaultSortContext } from "../../_shared/Context/defaultSortContext";
import AdminStructuresQuery from "./gql/adminStructures";
import { getStructureSearchSuggestionsQuery } from "./gql/getStructureSearchSuggestionsQuery";
import StructuresQuery from "./gql/structures";
import Structures from "./Structures";
import StructuresTable from "./StructuresTable";
import {
  adaptQueryParamsToGqlSearchArg,
  convertStructureSearchSuggestionsForSearchBar as convertAvailableFilters,
} from "./utils";

export const ITEMS_PER_PAGE = 20;

const getNameFromTranslation = (label) =>
  label?.translations[0]?.translation ?? "";

/** Transforms [{ key: "CATEGORY", value: "lifestyle" }] into { category: "lifestyle" } */
const adaptFilters = (filters) => {
  const result = {};
  filters.forEach(({ key, value }) => {
    const keyAdapted = key.toLowerCase();

    if (Array.isArray(result[keyAdapted])) {
      result[keyAdapted].push(value);
    } else if (typeof result[keyAdapted] === "string") {
      result[keyAdapted] = [result[keyAdapted], value];
    } else {
      result[keyAdapted] = value;
    }
  });
  return result;
};

const adaptAutofill = (autofill) => {
  if (!autofill) {
    return null;
  }
  return {
    displayCount: autofill.displayCount,
    orderBy: autofill.orderBy,
    filters: adaptFilters(autofill.filters),
  };
};

const adaptItem = (itemDetails, pack) => {
  return {
    id: itemDetails.id,
    /* This is not yet implemented in api-gateway
    name: itemDetails.name,
    category: itemDetails.category.name,
    dateAdded: itemDetails.addedToPackageDate,*/
    parent: pack,
  };
};

const adaptPackage = (packageDetails, section) => {
  const items = get(packageDetails, "packageItems.items", []);
  const pack = {
    id: packageDetails.id,
    name: getNameFromTranslation(packageDetails.packageLabel),
    itemsCount: items.length, // @todo - packageDetails.packageItems.pagination.count - currently this has wrong value, so items.length is used instead
    autofill: adaptAutofill(packageDetails.autofillPackageItems),
    parent: section,
  };
  return {
    ...pack,
    items: items.map((item) => adaptItem(item, pack)),
  };
};

const adaptSection = (sectionDetails, structure) => {
  const packages = get(sectionDetails, "packages.items", []);
  const section = {
    id: sectionDetails.id,
    name: getNameFromTranslation(sectionDetails.sectionLabel),
    parent: structure,
    selectedProvider: sectionDetails.selectedProvider.id,
  };
  return {
    ...section,
    packages: packages.map((item) => adaptPackage(item, section)),
  };
};

const adaptStructure = (structureDetails) => {
  const sections = get(structureDetails, "sections.items", []);

  const structure = {
    id: structureDetails.id,
    name: structureDetails.name,
    lastModifiedDate: new Date(structureDetails.lastModifiedDate),
    score: {
      value: get(structureDetails, "score.value"),
    },
  };
  return {
    ...structure,
    sections: sections.map((item) => adaptSection(item, structure)),
  };
};

const allowedSortByValues = StructuresTable.tabsConfig[0].columns
  .map(({ sortBy }) => sortBy)
  .filter((sortByValue) => Boolean(sortByValue));
const defaultSortBy = allowedSortByValues[0];

@restrictedArea((props) => {
  const allowed = [];

  if (props.forAdmin) {
    allowed.push(ROLES.administrator.structuresAdmin);
  } else {
    allowed.push(ROLES.structure.structureManager);
  }

  return {
    allowed,
  };
})
@withRouter
@withActiveOrganization
@withQueryParams
@withQuery({ name: "structures", query: StructuresQuery })
@withQuery({
  name: "structureSearchSuggestions",
  query: getStructureSearchSuggestionsQuery,
})
@withQuery({ name: "adminStructures", query: AdminStructuresQuery })
@withSorting({
  defaultSortBy,
  allowedSortByValues,
})
export default class StructuresData extends Component {
  static propTypes = {
    forAdmin: PropTypes.bool.isRequired,

    // from @withActiveOrganization
    activeOrganization: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),

    // from @withRouter
    location: PropTypes.object,

    // from @withQueryParams
    queryParams: PropTypes.object,

    // from @withQuery
    structuresQuery: PropTypes.func,
    structuresQueryStatus: queryResultType,
    adminStructuresQuery: PropTypes.func,
    adminStructuresQueryStatus: queryResultType,
    structureSearchSuggestionsQuery: PropTypes.func,
    structureSearchSuggestionsQueryStatus: queryResultType,

    // from @withSorting
    sortBy: PropTypes.string.isRequired,
    sortOrder: PropTypes.string.isRequired,
  };

  state = {
    sortingDefault: {
      sortBy: this.props.sortBy,
      sortOrder: this.props.sortOrder,
    },
  };

  componentDidMount() {
    this.fetchStructures();
    this.fetchStructureSearchSuggestions();
  }

  componentDidUpdate(prevProps) {
    const locationHasChanged = this.props.location !== prevProps.location;
    const organizationHasChanged =
      this.props.activeOrganization.id !== prevProps.activeOrganization.id;

    if (locationHasChanged || organizationHasChanged) {
      this.fetchStructures();
    }
  }

  fetchStructureSearchSuggestions = () => {
    const { structureSearchSuggestionsQuery } = this.props;
    structureSearchSuggestionsQuery();
  };

  getConvertedSuggestionsData = (converter) => {
    const { structureSearchSuggestionsQueryStatus: status } = this.props;
    return {
      ...status,
      data: converter(status.data),
      error: status.error && getGqlErrorMessage(status.error),
    };
  };

  getAvailableFiltersData = () =>
    this.getConvertedSuggestionsData(convertAvailableFilters);

  getStructureQuery() {
    const { structuresQuery, adminStructuresQuery, forAdmin } = this.props;

    if (forAdmin) {
      return adminStructuresQuery;
    }
    return structuresQuery;
  }

  getStructureQueryStatus() {
    const { structuresQueryStatus, adminStructuresQueryStatus, forAdmin } =
      this.props;

    if (forAdmin) {
      return adminStructuresQueryStatus;
    }
    return structuresQueryStatus;
  }

  fetchStructures() {
    const { location, sortBy, sortOrder, queryParams } = this.props;
    const pagination = parseLocationToLimitOffset(location, ITEMS_PER_PAGE);

    this.getStructureQuery()({
      variables: {
        pagination,
        sort: {
          name: sortBy.toUpperCase(),
          order: sortOrder,
        },
        search: adaptQueryParamsToGqlSearchArg(queryParams),
      },
    });
  }

  getError() {
    const error = this.getStructureQueryStatus().error;

    return error && getGqlErrorMessage(error);
  }

  render() {
    const { forAdmin, queryParams } = this.props;

    const structuresType = forAdmin ? "adminStructures" : "structures";
    const availableFilters = this.getAvailableFiltersData();

    const structures = get(
      this.getStructureQueryStatus().data,
      `${structuresType}.structures.items`,
      []
    );
    const totalCount = get(
      this.getStructureQueryStatus().data,
      `${structuresType}.structures.pagination.count`,
      0
    );

    return (
      <DefaultSortContext.Provider value={this.state.sortingDefault}>
        <Structures
          data={structures.map(adaptStructure)}
          count={totalCount}
          loading={this.getStructureQueryStatus().loading}
          error={this.getError()}
          refetchList={this.getStructureQueryStatus().refetch}
          availableFilters={availableFilters.data}
          forAdmin={forAdmin}
          queryParams={queryParams}
        />
      </DefaultSortContext.Provider>
    );
  }
}
