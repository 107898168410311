// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { TableBody } from "components/layout";
import { TabbedTable } from "containers/TabbedTable";
import { trans } from "src/translations";

import { ITEMS_PER_PAGE } from "./constants";
import LabelRow from "./LabelRow";
import styles from "./Labels.scss";
import { LabelPropTypes, TransListPropTypes } from "./propTypes";

export default function LabelsTable(props) {
  const { labels, loading, texts, onEdit, onRemove, error, count } = props;

  const tabsConfig = [
    {
      dataTestId: "labels-table",
      columns: [
        {
          label: texts.contentHeader(),
        },
        {
          label: texts.infoHeader(),
          className: styles.countCol,
        },
      ],
    },
  ];
  return (
    <TabbedTable
      renderTableBody={() => (
        <TableBody>
          {labels.map((label) => (
            <LabelRow
              key={label.id}
              label={label}
              onEdit={onEdit}
              onRemove={onRemove}
            />
          ))}
        </TableBody>
      )}
      tabsConfig={tabsConfig}
      data={{
        results: labels,
        count,
        loading,
        error,
      }}
      noResultsMessage={<p>{trans.STRUCTURES_LABELS__EMPTY_LIST()}</p>}
      rowsPerPage={ITEMS_PER_PAGE}
    />
  );
}

LabelsTable.propTypes = {
  labels: PropTypes.arrayOf(LabelPropTypes),
  loading: PropTypes.bool,
  texts: TransListPropTypes.isRequired,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  error: PropTypes.string,
  count: PropTypes.number,
};

LabelsTable.defaultProps = {
  loading: false,
};
