// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { withActiveOrganization } from "containers/Auth/decorators";
import { restrictedArea, ROLES } from "containers/Permissions";
import { trans } from "src/translations";
import { withRouter, withSearch } from "utils/decorators";
import { withQuery, withMutation } from "utils/graphql";
import { parseLocationToLimitOffset } from "utils/pagination";

import { ITEMS_PER_PAGE } from "./constants";
import { deleteSectionLabelGql } from "./gql/deleteSectionLabel";
import { editSectionLabelGql } from "./gql/editSectionLabel";
import { sectionLabelsGql } from "./gql/sectionLabels";
import Labels from "./Labels";

@restrictedArea(() => ({
  allowed: [
    ROLES.structure.structureManager,
    ROLES.administrator.structuresAdmin,
  ],
}))
@withRouter
@withActiveOrganization
@withSearch
@withMutation({ name: "deleteSectionLabel", mutation: deleteSectionLabelGql })
@withMutation({ name: "editSectionLabel", mutation: editSectionLabelGql })
@withQuery({ name: "sectionLabels", query: sectionLabelsGql })
export class SectionLabelsData extends Component {
  static propTypes = {
    // from @withActiveOrganization
    activeOrganization: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),

    // from @withRouter
    location: PropTypes.object,

    // from @withMutation
    deleteSectionLabelMutation: PropTypes.func,
    deleteSectionLabelMutationStatus: PropTypes.object,
    editSectionLabelMutation: PropTypes.func,
    editSectionLabelMutationStatus: PropTypes.object,

    // from @withQuery
    sectionLabelsQuery: PropTypes.func,
    sectionLabelsQueryStatus: PropTypes.object,

    // from @withSearch
    changeSearch: PropTypes.func,
    searchValue: PropTypes.string,
  };

  texts = {
    edit: {
      translationsHeader:
        trans.STRUCTURES_SECTION_LABEL_MODAL__COLUMN_TRANSLATIONS,
    },
    list: {
      contentHeader: trans.STRUCTURES_SECTION__COLUMN_ITEM,
      infoHeader: trans.STRUCTURES_SECTION__COLUMN_INFO,
      searchPlaceholder: trans.STRUCTURES_SECTION__SEARCH_PLACEHOLDER,
    },
    remove: {
      removeFailMessage: trans.STRUCTURES_SECTION__REMOVE_FAIL_MESSAGE,
      removeConfirmMessage: trans.STRUCTURES_SECTION__REMOVE_WARNING,
    },
  };

  componentDidMount() {
    this.fetchSectionLabels();
  }

  componentDidUpdate(prevProps) {
    const locationHasChanged = this.props.location !== prevProps.location;
    const organizationHasChanged =
      this.props.activeOrganization.id !== prevProps.activeOrganization.id;

    if (locationHasChanged || organizationHasChanged) {
      this.fetchSectionLabels();
    }
  }

  fetchSectionLabels = () => {
    const { sectionLabelsQuery, searchValue, location } = this.props;

    const pagination = parseLocationToLimitOffset(location, ITEMS_PER_PAGE);

    sectionLabelsQuery({
      variables: {
        pagination,
        search: { query: searchValue },
      },
    });
  };

  handleRemoveSectionLabel = async (sectionLabelId) => {
    const { deleteSectionLabelMutation } = this.props;
    await deleteSectionLabelMutation({
      variables: { input: { sectionLabelId: sectionLabelId } },
    });
  };

  handleUpdateSectionLabel = async (
    sectionLabelId,
    translations,
    thumbnailUrl = undefined
  ) => {
    const { editSectionLabelMutation } = this.props;
    await editSectionLabelMutation({
      variables: { input: { sectionLabelId, translations, thumbnailUrl } },
    });
  };

  adaptSectionLabels = (data) => {
    if (!data) {
      return [];
    }

    return data.sectionLabels.sectionLabels.items.map(
      ({ id, usedInStructuresCount, translations }) => {
        return {
          id: id,
          name: get(translations, "[0].translation", ""),
          useCount: Number(usedInStructuresCount),
        };
      }
    );
  };

  render() {
    const {
      changeSearch,
      searchValue,
      deleteSectionLabelMutationStatus,
      editSectionLabelMutationStatus,
      sectionLabelsQueryStatus,
    } = this.props;

    const totalCount = get(
      sectionLabelsQueryStatus.data,
      "sectionLabels.sectionLabels.pagination.count",
      0
    );

    return (
      <Labels
        labels={this.adaptSectionLabels(sectionLabelsQueryStatus.data)}
        labelsStatus={sectionLabelsQueryStatus}
        removeLabel={this.handleRemoveSectionLabel}
        removeLabelStatus={deleteSectionLabelMutationStatus}
        updateLabel={this.handleUpdateSectionLabel}
        updateLabelStatus={editSectionLabelMutationStatus}
        changeSearch={changeSearch}
        searchValue={searchValue}
        texts={this.texts}
        labelType={"section"}
        totalCount={totalCount}
      />
    );
  }
}
