// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { ValidationBadge } from "components/form";
import { Checkbox } from "components/form/elements";
import { Section, Subsection } from "components/layout";
import { trans } from "src/translations";
import { getGqlErrorMessage } from "utils/errors/gqlErrors";
import { withMutation, mutationResultType } from "utils/graphql";

import { sendUpdateJobLogsToUserMutation } from "../gql/sendUpdateJobLogsToUserMutation";
import { UpdateJobPropType } from "../propTypes";
import { LogsDownloadModal } from "./LogsDownloadModal";
import styles from "./UpdateJobLogs.scss";

@withMutation({
  name: "sendUpdateJobLogsToUser",
  mutation: sendUpdateJobLogsToUserMutation,
})
export class UpdateJobLogs extends PureComponent {
  static propTypes = {
    data: UpdateJobPropType,
    setSuccessMessage: PropTypes.func.isRequired,

    // from @withMutation
    sendUpdateJobLogsToUserMutation: PropTypes.func.isRequired,
    sendUpdateJobLogsToUserMutationStatus: mutationResultType,
  };

  state = {
    checkedSteps: {},
    isModalOpen: false,
    showValidationError: false,
  };

  isStepChecked = (step) => this.state.checkedSteps[step.stepId] === true;

  toggleStep = (step) => {
    this.setState((state) => ({
      ...state,
      checkedSteps: {
        ...state.checkedSteps,
        [step.stepId]: !state.checkedSteps[step.stepId],
      },
    }));
  };

  getSelectedStepIndices = () => {
    const { data } = this.props;

    return data.steps.reduce((acc, step, idx) => {
      if (this.isStepChecked(step)) {
        acc.push(idx);
      }
      return acc;
    }, []);
  };

  handleModalClose = () => this.setState({ isModalOpen: false });

  handleDownloadBtnClick = () => {
    const { setSuccessMessage } = this.props;

    if (this.getValidationError() != null) {
      this.setState({ showValidationError: true });
    } else {
      this.setState({ showValidationError: false, isModalOpen: true });
    }

    setSuccessMessage(undefined);
  };

  handleDownload = async () => {
    const { data, setSuccessMessage, sendUpdateJobLogsToUserMutation } =
      this.props;
    const selectedSteps = this.getSelectedStepIndices();

    await sendUpdateJobLogsToUserMutation({
      variables: {
        input: {
          updateJobId: data.id,
          logsStartDate: data.logsStartDate,
          updateJobStepIndices: selectedSteps,
        },
      },
    });

    // skipped if mutation has thrown
    this.handleModalClose();
    setSuccessMessage(trans.UPDATES__UPDATE_DETAILS__LOGS_SUCCESS_MESSAGE());
  };

  getValidationError() {
    const { data } = this.props;
    const firstStep = data.steps[0];

    const anyDevicesWereUpdated =
      firstStep.successes > 0 || firstStep.failures > 0;
    if (!anyDevicesWereUpdated) {
      return trans.UPDATES__UPDATE_DETAILS__LOGS_ERROR_NO_AVAILABLE();
    }

    const selectedSteps = this.getSelectedStepIndices();
    if (selectedSteps.length === 0) {
      return trans.UPDATES__UPDATE_DETAILS__LOGS_ERROR_NO_STEPS_SELECTED();
    }

    return undefined;
  }

  render() {
    const { data, sendUpdateJobLogsToUserMutationStatus: requestStatus } =
      this.props;
    const { showValidationError } = this.state;

    const error = this.getValidationError();

    return (
      <Section header={trans.UPDATES__UPDATE_DETAILS__UPDATE_LOGS_HEADER()}>
        <Subsection
          header={trans.UPDATES__UPDATE_DETAILS__UPDATE_LOGS_SELECT_STEPS()}
        >
          <div className={styles.checkboxContainer}>
            {data.steps.map((step, idx) => {
              const hasLogs =
                Boolean(step.inProgress) ||
                Boolean(step.successes) ||
                Boolean(step.failures);

              return (
                <Checkbox
                  key={step.stepId}
                  name={`step-${idx}`}
                  value={step.stepId}
                  checked={this.isStepChecked(step)}
                  onChange={() => this.toggleStep(step)}
                  disabled={!step.hasStarted && !hasLogs}
                  className={styles.checkbox}
                  dataTestId="update-logs-step"
                >
                  {trans.UPDATES__UPDATE_DETAILS__UPDATE_LOGS_STEP({
                    step: String(idx + 1),
                  })}
                </Checkbox>
              );
            })}
          </div>

          <Button
            onClick={this.handleDownloadBtnClick}
            dataTestId="update-logs-download-button"
          >
            {trans.DOWNLOAD()}
          </Button>
          <ValidationBadge touched={showValidationError} error={error} />
        </Subsection>

        <LogsDownloadModal
          isOpen={this.state.isModalOpen}
          onClose={this.handleModalClose}
          onConfirm={this.handleDownload}
          isSubmitting={requestStatus.loading}
          error={requestStatus.error && getGqlErrorMessage(requestStatus.error)}
        />
      </Section>
    );
  }
}
