// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { SelectField } from "components/form/fields";
import { GrabsonIcon } from "components/icons";
import { TableRow, TableRowColumn } from "components/layout";
import vewdSymbol from "images/vewd-symbol.svg";
import { trans } from "src/translations";

import {
  AUTOFILL_LENGTH_VALUES,
  AUTOFILL_SORT_VALUES,
  FIELDS,
} from "../constants";
import styles from "./PackageAutofillRow.scss";

export const PackageAutofillRow = ({ onEdit, onRemove, packageFieldName }) => {
  const autofillSortName = `${packageFieldName}.${FIELDS.AUTOFILL_ORDERBY_FIELD_NAME}`;
  const autofillDisplayName = `${packageFieldName}.${FIELDS.AUTOFILL_COUNT_FIELD_NAME}`;

  return (
    <TableRow className={styles.row} highlighting={false}>
      <TableRowColumn colspan={2} className={styles.iconColumn}>
        <img src={vewdSymbol} className={styles.icon} />
      </TableRowColumn>
      <TableRowColumn colspan={2} className={styles.fieldsColumn}>
        <span className={styles.name} onClick={onEdit}>
          {trans.STRUCTURES_PACKAGE_ITEM__AUTOFILL()}
        </span>
        <SelectField
          label={`${trans.STRUCTURES_PACKAGE_ITEM__SORT()}:`}
          required
          size="compact"
          values={AUTOFILL_SORT_VALUES}
          labelPosition="left"
          name={autofillSortName}
        />
        <SelectField
          label={`${trans.STRUCTURES_PACKAGE_ITEM__DISPLAY()}:`}
          required
          size="compact"
          values={AUTOFILL_LENGTH_VALUES}
          labelPosition="left"
          name={autofillDisplayName}
        />
      </TableRowColumn>
      <TableRowColumn className={styles.deleteColumn}>
        <GrabsonIcon
          onClick={onRemove}
          name="close-filled"
          size="lg"
          className={styles.removeIcon}
        />
      </TableRowColumn>
    </TableRow>
  );
};

PackageAutofillRow.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  packageFieldName: PropTypes.string,
};
